import type { SecretFile } from '@/stores/secret/files';
import http from '@/utils/http';
import { aesDecryptFile } from '@/utils/encryption/symmetric';
import { useSecretStore } from '@/stores/secret';
import { storeToRefs } from 'pinia';

export default async function decryptDownloadFile(file: SecretFile): Promise<void> {
  const secretStore = useSecretStore();
  const { secretSymmetricKey } = storeToRefs(secretStore);

  if (!secretSymmetricKey.value) {
    throw new Error('Cannot get secret keys.');
  }

  if (!file.nameDecrypted) {
    throw new Error('Cannot get decrypted file name.');
  }

  if (!file.signed_url) {
    throw new Error('Cannot get signed file url.');
  }

  const response = await http.get(file.signed_url, {
    responseType: 'blob',
  });

  const filePlaintext = await aesDecryptFile(
    secretSymmetricKey.value,
    await response.data.arrayBuffer()
  );

  const blob = new Blob([filePlaintext], { type: 'application/octet-stream' });

  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = file.nameDecrypted ?? 'test.png';

  document.body.appendChild(a);

  a.click();

  URL.revokeObjectURL(url);
  document.body.removeChild(a);
}
