import { useAuthUserStore } from '@/stores/auth-user';
import { useTenantStore } from '@/stores/tenant';
import { importPrivateKey, rsaDecrypt } from '@/utils/encryption/asymmetric';
import { aesDecrypt, importAesKey } from '@/utils/encryption/symmetric';
import { storeToRefs } from 'pinia';
import rsaDecryptText from '../secret/rsa-decrypt-text';

export async function decryptUrlSalt(): Promise<string | null> {
  const tenantStore = useTenantStore();
  const { urlSalt } = storeToRefs(tenantStore);

  if (urlSalt.value) {
    return urlSalt.value;
  }

  const authUserStore = useAuthUserStore();
  const { authUser } = storeToRefs(authUserStore);

  if (!authUser.value) {
    throw new Error('Auth user is null');
  }

  const userPrivKey = authUser.value.asymmetric_key_private_decrypted;
  if (!userPrivKey) {
    throw new Error('Could not get user private key');
  }

  const tenantUserSymKeyBytes = await rsaDecrypt(
    userPrivKey as CryptoKey,
    authUser.value.tenant_user_symmetric_key
  );

  const tenantUserSymKey = await importAesKey(tenantUserSymKeyBytes);

  const tenantUserPrivKeyBytes = await aesDecrypt(
    tenantUserSymKey,
    authUser.value.tenant_user_asymmetric_key_private
  );

  const tenantUserPrivKey = await importPrivateKey(tenantUserPrivKeyBytes);

  const tenantSymKeyBytes = await rsaDecrypt(
    tenantUserPrivKey,
    authUser.value.tenant_symmetric_key
  );

  const tenantSymKey = await importAesKey(tenantSymKeyBytes);

  const tenantPrivKeyBytes = await aesDecrypt(
    tenantSymKey,
    authUser.value.tenant_asymmetric_key_private
  );

  const tenantPrivKey = await importPrivateKey(tenantPrivKeyBytes);

  urlSalt.value = (await rsaDecryptText(tenantPrivKey, authUser.value.tenant_url_salt)) ?? null;

  return urlSalt.value;
}
