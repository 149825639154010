import { useAppStore } from '@/stores/app';
import axios, { AxiosError } from 'axios';
import { useToast } from 'vue-toast-notification';
import router from './../../router';

const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: `${import.meta.env.VITE_SERVER_URL}`,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(async (request) => {
  const appStore = useAppStore();

  // For POST, PUT, and DELETE requests make a CSRF preflight if we have not already
  // made one for this page load.
  if (['post', 'put', 'delete'].includes(request.method!.toLowerCase()) && !appStore.hasCsrf) {
    const response = await axiosInstance.get(`${import.meta.env.VITE_SERVER_URL}/csrf-token`);

    appStore.csrfToken = response.data;
  }

  request.headers.set('X-CSRF-TOKEN', appStore.csrfToken);

  return request;
});

interface ErrorResponse {
  data: {
    message?: string;
    // ... any other properties that might be included in the error response
  };
  status: number;
  // ... any other properties of the error object
}

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    const toast = useToast();
    const currentRoute = router.currentRoute.value;

    if (error.config?.url === '/user' || error.config?.url === '/verify/email') {
      return Promise.reject(error);
    }

    if (currentRoute && currentRoute.meta.requiresAuth && currentRoute.name !== 'logout') {
      if (error.response?.status === 401 || error.response?.status === 403) {
        if ((error.response as ErrorResponse)?.data?.message === 'No active subscription') {
          toast.error('You do not have an active subscription.');
          router.push({ name: 'subscribe' });
          return Promise.reject(error);
        }

        toast.error('You do not have permission to do that.');
        router.push({ name: 'main' });
        return Promise.reject(error);
      }

      if (error.response?.status === 500) {
        toast.error('An error occurred, please try again later.');
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
