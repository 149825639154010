import { useGroupStore, type GroupKey } from '@/stores/group';
import {
  useGroupAccessStore,
  type AddUserAccessPayload,
  type GroupAccessOption,
} from '@/stores/group/access';
import { importPublicKey, rsaEncrypt } from '@/utils/encryption/asymmetric';
import { exportAesKey } from '@/utils/encryption/symmetric';
import http from '@/utils/http';

export default function useAddGroupAccess() {
  const groupAccessStore = useGroupAccessStore();
  let groupID: string;
  let accessOpt: GroupAccessOption;
  let sharingPerms: boolean | undefined;

  const addAccess = async (params: {
    groupID: string;
    accessOpt: GroupAccessOption;
    sharingPerms?: boolean;
  }): Promise<void> => {
    groupID = params.groupID;
    accessOpt = params.accessOpt;
    sharingPerms = params.sharingPerms;

    const groupKey = await getGroupKey();
    const userPublicKey = await getUserPublicKey();

    const symKeyEncrypted = await rsaEncrypt(
      userPublicKey,
      await exportAesKey(groupKey.symmetric_key)
    );

    const payload: AddUserAccessPayload = {
      user_id: accessOpt.id,
      symmetric_key: symKeyEncrypted,
      asymmetric_key_public: groupKey.asymmetric_key_public,
      asymmetric_key_private: groupKey.asymmetric_key_private,
      can_grant_access: sharingPerms ?? false,
    };

    await groupAccessStore.addUserAccess(groupID, payload);
  };

  const getUserPublicKey = async (): Promise<CryptoKey> => {
    const response = await http.get(`/users/${accessOpt.id}/public-key`);

    const userPublicKey = await importPublicKey(response.data);

    return userPublicKey;
  };

  const getGroupKey = async (): Promise<GroupKey> => {
    const groupStore = useGroupStore();
    const groupKey = await groupStore.getGroupKey(groupID);
    return groupKey;
  };

  return {
    addAccess,
  };
}
