<template>
  <q-header class="bg-white text-black flex items-center q-py-md q-px-xl">
    <q-toolbar class="flex items-center justify-center">
      <q-toolbar-title>
        <router-link :to="{ name: 'landing' }" class="flex items-center">
          <img src="@/assets/icons/icon.svg" class="q-mr-md" />
          <span>strng.io</span>
        </router-link>
      </q-toolbar-title>

      <q-btn
        class="q-ml-md"
        size="lg"
        color="primary"
        label="Sign In"
        :to="{ name: 'login' }"
        outline
      />

      <q-btn
        class="q-ml-md"
        size="lg"
        color="primary"
        label="Get Started"
        :to="{ name: 'signup' }"
        icon-right="arrow_forward"
      />
    </q-toolbar>
  </q-header>
</template>
