<template>
  <q-input :label="label" :modelValue="valueString" v-bind="attrs">
    <q-popup-proxy ref="qDateProxy" :breakpoint="0" behavior="menu">
      <q-date
        v-model="value"
        @input="($refs.qDateProxy as QPopupProxy).hide()"
        v-bind="attrs"
        :options="optionsFn"
      />
    </q-popup-proxy>
  </q-input>
</template>

<script lang="ts" setup>
import { computed, ref, toRefs, useAttrs } from 'vue';
import type { QPopupProxy } from 'quasar';
import dayjs from 'dayjs';

export interface Range {
  to: string;
  from: string;
}

const qDateProxy = ref<QPopupProxy>();

const props = defineProps<{
  modelValue?: string | Range | null;
  label?: string;
  max?: string;
}>();

const { modelValue, max } = toRefs(props);

const emit = defineEmits<{
  (e: 'update:modelValue', value?: string | Range | null): void;
}>();

const attrs = useAttrs();

const value = computed({
  get: () => modelValue?.value,
  set: (value?: string | Range | null) => emit('update:modelValue', value),
});

const valueString = computed(() => {
  if (!modelValue?.value) {
    return null;
  }

  if (typeof modelValue.value === 'string') {
    return modelValue.value;
  }

  return `${modelValue.value.from} - ${modelValue.value.to}`;
});

const optionsFn = (date: string) => {
  if (!max?.value) {
    return true;
  }

  if (max.value === 'today') {
    return date <= dayjs().format('YYYY/MM/DD');
  }

  return date <= max.value;
};
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>
