import dayjs from 'dayjs';
import { computed, type ComputedRef } from 'vue';
import { useAuthUserStore } from '@/stores/auth-user';
import { storeToRefs } from 'pinia';

export default function formatDatetime(
  datetime: string | null | undefined,
  format: string = 'DD/MM/YYYY hh:mm A'
): ComputedRef<string | null> {
  const authUserStore = useAuthUserStore();
  const { authUser } = storeToRefs(authUserStore);

  return computed(() => {
    if (authUser.value === null || !datetime) {
      return null;
    }

    return dayjs(datetime)
      .tz(authUser.value?.timezone ?? 'UTC')
      .format(format);
  });
}
