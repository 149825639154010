import GroupList from '@/views/groups/GroupList.vue';
import GroupAdd from '@/views/groups/GroupAdd.vue';
import GroupShow from '@/views/groups/GroupShow.vue';
import GroupDetails from '@/views/groups/GroupDetails.vue';
import GroupAccess from '@/views/groups/GroupAccess.vue';
import GroupSecretList from '@/views/groups/GroupSecretList.vue';

export default [
  {
    path: '/groups',
    name: 'groups',
    redirect: { name: 'groups.list' },
    meta: {
      template: 'app',
      ability: {
        action: 'read',
        subject: 'groups',
      },
    },
    children: [
      {
        path: '',
        name: 'groups.list',
        component: GroupList,
      },
      {
        path: 'add',
        name: 'groups.add',
        component: GroupAdd,
        meta: {
          ability: {
            action: 'write',
            subject: 'groups',
          },
        },
      },
      {
        path: ':id',
        name: 'group',
        component: GroupShow,
        children: [
          {
            path: 'details',
            name: 'group.details',
            component: GroupDetails,
          },
          {
            path: 'access',
            name: 'group.access',
            component: GroupAccess,
          },
          {
            path: 'secrets',
            name: 'group.secrets',
            component: GroupSecretList,
          }
        ],
      },
    ],
  },
];
