<template>
  <AppContainer title="Groups">
    <template #buttons>
      <HelpPopup page="groups.list" />

      <q-btn
        v-if="canWriteGroups"
        :to="{ name: 'groups.add' }"
        type="button"
        color="primary"
        icon="add"
        round
        dense
      />

      <q-btn
        type="button"
        color="primary"
        icon="search"
        @click="() => (isFiltersOpen = !isFiltersOpen)"
        round
        dense
      />
    </template>

    <template #drawerRight>
      <FilterDrawer v-model:is-open="isFiltersOpen" v-model:filters="filters" />
    </template>

    <q-page-container>
      <q-page class="q-pa-md">
        <div class="row justify-center">
          <q-list class="q-layout q-pa-md">
            <q-infinite-scroll @load="onLoad" :offset="250" ref="infiniteScroll">
              <template v-slot:loading>
                <div class="row justify-center q-my-md">
                  <q-spinner-dots color="primary" size="40px" />
                </div>
              </template>

              <div v-for="group in groups" :key="group.id">
                <ListItem :group="group" />
              </div>

              <q-item v-if="groups.length < 1" class="text-center">
                <q-item-section>
                  <q-item-label>No groups available.</q-item-label>
                </q-item-section>
              </q-item>
            </q-infinite-scroll>
          </q-list>
        </div>
      </q-page>
    </q-page-container>
  </AppContainer>
</template>

<script lang="ts" setup>
import { useGroupStore, type GroupListFilters } from '@/stores/group';
import { storeToRefs } from 'pinia';
import FilterDrawer from '@/components/groups/FilterDrawer.vue';
import ListItem from '@/components/groups/ListItem.vue';
import { ref, watch, onMounted, onBeforeUnmount, computed } from 'vue';
import type { QInfiniteScroll } from 'quasar';
import { useAuthUserStore } from '@/stores/auth-user';

const infiniteScroll = ref<QInfiniteScroll | null>(null);

const isFiltersOpen = ref(false);
const filters = ref<GroupListFilters>({});

const authUserStore = useAuthUserStore();

const groupStore = useGroupStore();
const { groups } = storeToRefs(groupStore);

const canWriteGroups = computed(() => authUserStore.can({ action: 'write', subject: 'groups' }));

watch(
  filters,
  async () => {
    if (!infiniteScroll.value) {
      return;
    }

    const response = await groupStore.getGroups({
      page: 1,
      filters: filters.value,
      reset: true,
    });

    infiniteScroll.value.setIndex(1);

    if (response.length < 50) {
      infiniteScroll.value.stop();
    }
  },
  { deep: true }
);

const onLoad = async (index: number, done: (stop: boolean) => void) => {
  const response = await groupStore.getGroups({
    page: index,
    filters: filters.value,
  });

  done(response.length < 50);
};

onMounted(() => {
  if (!infiniteScroll.value) {
    return;
  }

  infiniteScroll.value.trigger();
});

onBeforeUnmount(() => {
  if (infiniteScroll.value) {
    infiniteScroll.value.reset();
  }

  groupStore.resetGroups();
});
</script>
