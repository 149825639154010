<template>
  <q-dialog v-model="isOpen" position="top">
    <q-card class="add-access-dialog">
      <q-card-section class="row items-center q-pb-none">
        <div class="text-h6">Add Access</div>
        <q-space />
        <q-btn icon="close" flat round dense v-close-popup />
      </q-card-section>

      <q-form @submit="save">
        <q-card-section>
          <div class="row">
            <Field name="selected_access" v-slot="{ errorMessage }">
              <q-select
                :options="options"
                label="Access"
                clearable
                v-model="selectedAccess"
                :error-message="errorMessage"
                :error="!!errorMessage"
                class="col"
                use-input
                @filter="filterFn"
                @new-value="newValue"
                :display-value="`${values.selected_access ? values.selected_access.name : ''}`"
              >
                <template v-slot:option="scope">
                  <q-item v-if="!scope.opt.group" v-bind="scope.itemProps" :key="scope.opt.name">
                    <q-item-section>
                      <q-item-label>{{ scope.opt.name }}</q-item-label>
                    </q-item-section>
                  </q-item>

                  <q-item v-if="scope.opt.group" v-bind="scope.itemProps" :key="scope.opt.group">
                    <q-item-label class="q-mt-md">{{ scope.opt.group }}</q-item-label>
                  </q-item>
                </template>

                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      <q-item-label>No options available</q-item-label>
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
            </Field>
          </div>

          <div v-if="values.selected_access === null" class="row">
            <div class="text-sm text-grey col">
              Type a non existent group name and press enter to create a new group.
            </div>
          </div>

          <div class="row" v-if="hasNewGroup">
            <div class="text-sm text-grey col">
              Create a new group and automatically add this secret.
            </div>
          </div>

          <template v-if="values.selected_access?.type !== 'Tenants'">
            <div class="row">
              <Field name="sharing_permissions" v-slot="{ errorMessage, value, field }">
                <q-checkbox
                  class="col"
                  label="Grant access sharing permissions"
                  :model-value="value"
                  v-bind="field"
                  :error-message="errorMessage"
                  :error="!!errorMessage"
                />
              </Field>
            </div>

            <div class="row q-pl-md">
              <div class="text-sm text-grey col">
                This will allow the selected user to grant access to other users.
              </div>
            </div>
          </template>

          <div class="row q-mt-md">
            <div class="col"></div>

            <div class="col-auto">
              <q-btn
                class="text-right"
                color="primary"
                label="Add Access"
                type="submit"
                :loading="buttonLoading"
              />
            </div>
          </div>
        </q-card-section>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script lang="ts" setup>
import { Field, useForm } from 'vee-validate';
import { computed, onBeforeUnmount, ref, watch } from 'vue';
import * as yup from 'yup';
import { useSecretStore } from '@/stores/secret';
import {
  useSecretAccessStore,
  type SecretAccessOption,
  type SecretAccessOptionGroup,
} from '@/stores/secret/access';
import { storeToRefs } from 'pinia';
import { AxiosError } from 'axios';
import { useToast } from 'vue-toast-notification';
import useAddSecretAccess from '@/composable/secret/add-access';

const props = defineProps<{
  isOpen: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:isOpen', value: boolean): void;
}>();

const isOpen = computed({
  get() {
    return props.isOpen;
  },

  set(value: boolean) {
    emit('update:isOpen', value);
  },
});

const buttonLoading = ref(false);

const toast = useToast();

const { addAccess } = useAddSecretAccess();

const options = ref<(SecretAccessOption | SecretAccessOptionGroup)[]>([]);

const secretStore = useSecretStore();
const { secret } = storeToRefs(secretStore);

const secretAccessStore = useSecretAccessStore();
const { secretAccessOptions } = storeToRefs(secretAccessStore);

await secretAccessStore.getSecretAccessOptions(secret.value!.id);
options.value = secretAccessOptions.value;

interface AddAccessForm {
  selected_access: SecretAccessOption | null;
  sharing_permissions: boolean;
}

const { handleSubmit, setErrors, setValues, values, resetForm } = useForm<AddAccessForm>({
  validationSchema: yup.object({
    selected_access: yup.object().required().label('Access'),
    sharing_permissions: yup.boolean().nullable().label('Sharing permission'),
  }),
  initialValues: {
    sharing_permissions: false,
  },
});

const save = handleSubmit(async (payload) => {
  try {
    buttonLoading.value = true;

    await addAccess({
      secretID: secret.value!.id,
      accessOpt: payload.selected_access!,
      sharingPerms: payload.sharing_permissions,
    });

    buttonLoading.value = false;
    isOpen.value = false;

    await secretAccessStore.getSecretAccess({
      secretID: secret.value!.id,
      page: 1,
      reset: true,
    });
    toast.success('Successfully added access.');
  } catch (err) {
    buttonLoading.value = false;

    if (err instanceof AxiosError) {
      setErrors(err.response?.data);
      return;
    }

    throw err;
  }
});

const selectedAccess = computed({
  get() {
    return values.selected_access;
  },

  set(value: SecretAccessOption | null) {
    setValues({
      selected_access: value,
    });
  },
});

const hasNewGroup = computed(() => {
  return values.selected_access?.id === null;
});

const newValue = (val: string, done: (val: any, mode: 'add' | 'add-unique') => void) => {
  done({ id: null, name: val, type: 'New Group' }, 'add');
};

const filterFn = (val: string, update: (fn: () => void) => void) => {
  if (val === '') {
    update(() => {
      options.value = secretAccessOptions.value;
    });
    return;
  }

  update(() => {
    const needle = val.toLowerCase();

    options.value = secretAccessOptions.value.filter(
      (t: SecretAccessOption | SecretAccessOptionGroup) => {
        if (!t.name) {
          return true;
        }

        return t.name.toLowerCase().indexOf(needle) > -1;
      }
    );
  });
};

onBeforeUnmount(() => {
  secretAccessStore.resetSecretAccessOptions();
});

watch(isOpen, () => {
  resetForm();
});
</script>
