export function stringToBytes(s: string): Uint8Array {
  return new Uint8Array(Array.from(s).map((ch) => ch.charCodeAt(0)));
}

export function bytesToString(b: ArrayBuffer): string {
  const ctArray = Array.from(new Uint8Array(b));
  const ctStr = ctArray.map((byte) => String.fromCharCode(byte)).join('');
  return ctStr;
}

export function bytesToArrayBuffer(bytes: Uint8Array): ArrayBuffer {
  const bytesAsArrayBuffer = new ArrayBuffer(bytes.length);
  const bytesUint8 = new Uint8Array(bytesAsArrayBuffer);
  bytesUint8.set(bytes);
  return bytesAsArrayBuffer;
}

/*
Get some key material to use as input to the deriveKey method.
The key material is a password supplied by the user.
*/
export async function getKeyMaterial(password: string): Promise<CryptoKey> {
  const enc = new TextEncoder();
  return await window.crypto.subtle.importKey(
    'raw',
    enc.encode(password),
    { name: 'PBKDF2' },
    false,
    ['deriveBits', 'deriveKey']
  );
}
