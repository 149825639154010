<template>
  <q-btn icon="fa-solid fa-question" round dense outline>
    <q-popup-proxy>
      <q-banner class="help-popup">{{ pageContent }}</q-banner>
    </q-popup-proxy>
  </q-btn>
</template>

<script lang="ts" setup>
import { computed, toRefs } from 'vue';

const helpPopupContents = {
  dashboard: `This is a summary of the organisation, and all the secrets you have access to.
`,

  'secrets.list': `Secrets are encrypted credentials or other sensitive data that can optionally be shared with other users.

To add a secret, click the button with the plus icon in the top right corner. You can then enter the secret's name, value, and any notes you'd like to include.

To search for a secret, click the magnifying glass icon in the top right corner. You can search by name, date of creation, and sort the results.

Secrets with unlocked green padlocks are assessable to you. Secrets with locked red padlocks are not assessable to you. Secrets with locked orange padlocks are pending an access request.
`,

  'secrets.add': `To add a secret, enter the secret's name, password, and any other details you'd like to include. Then click the "Save" button.

To prevent users who do not have access to the secret from viewing it, check the "Hide password from users who do not have access" checkbox. This will prevent users from viewing the secret until you grant them access.
`,

  'secrets.details': `Secrets are encrypted credentials or other sensitive data that can optionally be shared with other users.

To edit a secret, you must have access to it. Click the "Decrypt Secret" button in the bottom right corner of the form. This will allow you to read and edit the secret.

If you do not have access to a secret, you can click the "Request Access" button in the bottom right corner of the form. This will send a request to all users who have permissions to share this secret, which can then be accepted or declined.
`,

  'secrets.files': `To store sensitive files against a secret, click the upload input, or drag and drop a file into the input at the top or the list.

These files are encryped prior to being stored in the database, and can only be accessed by users who have access to the secret.

You can organise the files into folders by clicking the "New Folder" button.

All users with access to this secret will also be able to access these files.
`,

  'secrets.access': `This list contains all users, groups, and organisations that have access to this secret.

To add access to a user, group, or your organisation, click the button with the plus icon in the top right corner of the list. You can then search for the user, group, or organisation you'd like to add access to. You can also denote whether the user or group will have the ability to further grant access to other users.

To revoke access from a user, group, or your organisation, click the three dots on the right side of the user's row, then click "Revoke Access".
`,

  'secrets.logs': `This list contains all actions that have been taken on this secret.

To filter the list, click the filter icon in the top right corner. You can filter by action, user, and date, and sort the list as required.
`,

  'groups.list': `Groups are collections of users that can be used to organise access to secrets.

To add a group, click the button with the plus icon in the top right corner. You can then enter the group's name and any notes you'd like to include.

To search for a group, click the magnifying glass icon in the top right corner. You can search by name, date of creation, and sort the results.
`,

  'groups.add': `To add a group, enter the group's name and any other details you'd like to include. Then click the "Save" button.

To hide the group from users who do not have access to it, check the "Hide group from users who do not have access" checkbox. This will prevent users from viewing the group until you grant them access.
`,

  'groups.details': `Groups are collections of users that can be used to organise access to secrets.
`,

  'groups.access': `This list contains all users that have access to this group.

To add access to a user, click the button with the plus icon in the top right corner of the list. You can then search for the user you'd like to add access to.

To revoke access from a user, click the three dots on the right side of the user's row, then click "Revoke Access".
`,

  'groups.secrets': `This list contains all the secrets this group has access to.

To add a secret to this group, click the button with the plus icon in the top right corner of the list. You can then search for the secret you'd like to add to this group.

To remove a secret from this group, click the three dots on the right side of the secret's row, then click "Remove from group".
`,

  'groups.logs': `This list contains all actions that have been taken on this group.
`,

  'access-requests.list': `Access requests are requests from users to access secrets or groups you have permissions to grant or decline.

To grant the user access to the secret or group, click on the record in the list. You can then select how you would like to allow them access. This can be directly, through a group (new or existing), or via granting access to the whole organisation. Then, click the "Accept Request" button.

To decline an access request, click on the record in the list, and click the "Decline Request" button.

You can filter this list to show previously granted or declined requests by clicking the filter icon in the top right corner.
`,

  'users.list': `Users are people who have access to the system.

To add a user, click the button with the plus icon in the top right corner. You can then enter the user's email address, name, and other required details.

The limit to how many users an organisation can have is determined by the organisation's subscription level. To upgrade your subscription, go to the subscription tab under on the organisation page.
`,

  'users.details': `This is the users details, you can edit the users name, email address, timezone, date of birth, and role.

An "Admin" user is able to view other users in the system, and manage the organisation.
`,

  'users.security': `This is the users security details, you can manage the users two factor authentication settings, and view the users login history.
`,

  'tenant.details': `The organisation is the top level of the system, and contains all users, groups, and secrets.

The "Extension Settins" section allows you to modify settings related to the browser extension.

The "Account Recovery" section allows you to set up the "Account Recovery" group if you haven't already. Any user within this group are able to recover a users account if they have forgotten their password. Only Admins can be added to this group.
`,

  'tenant.import': `To import secrets and groups from a previous password manager, such as Bitwarden or Lastpass, fill out the form on this page.

This will give you a summary of the data prior to being imported.
`,

  'tenant.subscription': `To upgrade your subscription, click the "Change Subscription Tier" button. You can then select the subscription level you would like to upgrade to.

To cancel your subscription, click the "Cancel" button. The subscription will remain active until the beginning of your next billing period.

To edit your payment method, click the "Edit" button under the "Payment Method" section, and enter your new card details.

You can also see and download a list of previous invoices.
`,

  'account.details': `To manage your account, edit the required information and click save.

Changes to your email address will be required to be verified before you can login using your new email address.
`,

  'account.security': `To manage your account security, you can edit your two factor authentication methods, and change your password.
`,
};

const props = defineProps<{
  page: keyof typeof helpPopupContents;
}>();

const { page } = toRefs(props);

const pageContent = computed(() => helpPopupContents[page.value]);
</script>
