<template>
  <q-banner
    v-if="showRecoveryGroupBanner"
    :inline-actions="props.inlineActions"
    class="text-white bg-red"
  >
    It is recommended that you create an Account Recovery group for your organisation. This will
    allow users passwords to be reset if lost.
    <template v-slot:action>
      <q-btn flat color="white" label="Dismiss" @click="dismissBanner" />
      <q-btn
        flat
        color="white"
        label="Create Recovery Group"
        @click="createRecoveryGroup"
        :loading="isLoading"
      />
    </template>
  </q-banner>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useAuthUserStore } from '@/stores/auth-user';
import { useGroupStore } from '@/stores/group';
import { useToast } from 'vue-toast-notification';
import createGroup from '@/composable/group/create-group';
import useAddRecoveryKey from '@/composable/group/add-recovery-key';
import http from '@/utils/http';

const props = withDefaults(
  defineProps<{
    overrideShow?: boolean;
    inlineActions?: boolean;
  }>(),
  {
    overrideShow: false,
    inlineActions: true,
  }
);

const isLoading = ref(false);

const authUserStore = useAuthUserStore();
const groupStore = useGroupStore();

const user = authUserStore.currentUser;

const toast = useToast();

const { addRecoveryKey } = useAddRecoveryKey();

const showRecoveryGroupBanner = computed(() => {
  if (props.overrideShow) {
    return true;
  }

  return !user.has_recovery_group && !user.recovery_group_badge_dismissed && authUserStore.isAdmin;
});

async function dismissBanner() {
  await http.post('/recovery-group/dismiss-banner');
  user.recovery_group_badge_dismissed = true;
}

async function createRecoveryGroup() {
  try {
    isLoading.value = true;

    // Create recovery group
    const createGroupPayload = await createGroup({
      name: 'Account Recovery',
      hidden: true,
    });

    await groupStore.addGroup({
      payload: {
        ...createGroupPayload,
        recovery: true,
      },
    });

    await addRecoveryKey(user.id, user.asymmetric_key_private_decrypted as CryptoKey);

    user.has_recovery_group = true;

    isLoading.value = true;

    toast.success('Recovery group created successfully.');
  } catch (error) {
    isLoading.value = false;

    toast.error('Failed to create recovery group.');
  }
}
</script>
