<template>
  <div class="payment-method-card q-mt-md">
    <h4 class="q-my-sm">Invoices</h4>

    <q-list>
      <q-infinite-scroll @load="onLoad" :offset="250" ref="infiniteScroll">
        <template v-slot:loading>
          <div class="row justify-center q-my-md">
            <q-spinner-dots color="primary" size="40px" />
          </div>
        </template>

        <div v-for="stripeInvoice in stripeInvoices" :key="stripeInvoice.id">
          <ListItem :stripe-invoice="stripeInvoice" />
        </div>

        <q-item v-if="stripeInvoices.length < 1" class="text-center">
          <q-item-section>
            <q-item-label>No invoices available.</q-item-label>
          </q-item-section>
        </q-item>
      </q-infinite-scroll>
    </q-list>
  </div>
</template>

<script lang="ts" setup>
import { useStripeInvoiceStore } from '@/stores/tenant/stripe-invoices';
import { storeToRefs } from 'pinia';
import { ref, onMounted, onBeforeUnmount } from 'vue';
import type { QInfiniteScroll } from 'quasar';
import ListItem from './TenantInvoiceListItem.vue';

const infiniteScroll = ref<QInfiniteScroll | null>(null);

const stripeInvoiceStore = useStripeInvoiceStore();
const { stripeInvoices } = storeToRefs(stripeInvoiceStore);

const onLoad = async (index: number, done: (stop: boolean) => void) => {
  const response = await stripeInvoiceStore.getStripeInvoices({ page: index });
  done(response.length < 50);
};

onMounted(() => {
  if (!infiniteScroll.value) {
    return;
  }

  infiniteScroll.value.trigger();
});

onBeforeUnmount(() => {
  if (infiniteScroll.value) {
    infiniteScroll.value.reset();
  }

  stripeInvoiceStore.resetStripeInvoices();
});
</script>
