<template>
  <div class="q-pa-md q-gutter-sm">
    <q-breadcrumbs separator="/">
      <SecretFilePathItem
        v-for="path in secretFilePath"
        :key="`path-${path.id}`"
        :file="path"
        @change-parent="changeParent"
      />
    </q-breadcrumbs>
  </div>
</template>

<script lang="ts" setup>
import { useSecretFileStore, type SecretFile } from '@/stores/secret/files';
import { storeToRefs } from 'pinia';
import SecretFilePathItem from './SecretFilePathItem.vue';

const secretFileStore = useSecretFileStore();
const { secretFilePath, currentParentID } = storeToRefs(secretFileStore);

function changeParent(path: SecretFile) {
  currentParentID.value = path.id;
}
</script>
