<template>
  <q-item class="q-my-sm">
    <q-item-section>
      <q-item-label>
        {{ title }}
      </q-item-label>
    </q-item-section>

    <q-item-section side>
      {{ createdAt }}
    </q-item-section>
  </q-item>
</template>

<script lang="ts" setup>
import { computed, toRefs } from 'vue';
import type { SecretLog } from '@/stores/secret/logs';
import formatDatetime from '@/composable/format-datetime';

const props = defineProps<{
  secretLog: SecretLog;
}>();

const { secretLog } = toRefs(props);

const createdAt = formatDatetime(secretLog.value.created_at);

const title = computed(() => {
  if (secretLog.value.type === 'access') {
    return `${secretLog.value.user_first_name} ${secretLog.value.user_last_name} (${secretLog.value.user_email}) accessed this secret.`;
  }

  if (secretLog.value.type === 'updated') {
    return `${secretLog.value.user_first_name} ${secretLog.value.user_last_name} (${secretLog.value.user_email}) updated this secret.`;
  }

  if (secretLog.value.type === 'created') {
    return `${secretLog.value.user_first_name} ${secretLog.value.user_last_name} (${secretLog.value.user_email}) created this secret.`;
  }

  if (secretLog.value.type === 'gave access') {
    const metadata = JSON.parse(secretLog.value.metadata);
    if (!metadata) {
      return null;
    }

    if (metadata.type === 'user') {
      return `${secretLog.value.user_first_name} ${secretLog.value.user_last_name} (${secretLog.value.user_email}) gave access to the user ${metadata.user_first_name} ${metadata.user_last_name} (${metadata.user_email}).`;
    }

    if (metadata.type === 'group') {
      return `${secretLog.value.user_first_name} ${secretLog.value.user_last_name} (${secretLog.value.user_email}) gave access to the group ${metadata.group_name}.`;
    }

    if (metadata.type === 'new group') {
      return `${secretLog.value.user_first_name} ${secretLog.value.user_last_name} (${secretLog.value.user_email}) created and gave access to the group ${metadata.group_name}.`;
    }

    if (metadata.type === 'tenant') {
      return `${secretLog.value.user_first_name} ${secretLog.value.user_last_name} (${secretLog.value.user_email}) gave access to the organisation.`;
    }
  }

  if (secretLog.value.type === 'revoked access') {
    const metadata = JSON.parse(secretLog.value.metadata);
    if (!metadata) {
      return null;
    }

    return `${secretLog.value.user_first_name} ${secretLog.value.user_last_name} (${secretLog.value.user_email}) revoked access access to the ${metadata.type} ${metadata.name}.`;
  }

  return null;
});
</script>
