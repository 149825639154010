<template>
  <Transition name="fade">
    <q-layout view="lhh LpR fff" container style="height: 100vh" class="main shadow-2">
      <q-drawer v-model="drawerLeft" :width="300" :breakpoint="700" class="sidebar">
        <div class="sidebar-logo">
          <q-img src="@/assets/icons/icon.svg" />

          <h3>strng.io</h3>
        </div>

        <div class="sidebar-links q-gutter-sm">
          <RouterLink
            v-if="authUserStore.can({ action: 'read', subject: 'dashboard' })"
            :to="{ name: 'dashboard' }"
          >
            <q-icon class="fa fa-house q-mr-sm" />
            Dashboard
          </RouterLink>

          <RouterLink
            v-if="authUserStore.can({ action: 'read', subject: 'secrets' })"
            :to="{ name: 'secrets.list' }"
          >
            <q-icon class="fa fa-key q-mr-sm" />
            Secrets
          </RouterLink>

          <RouterLink
            v-if="authUserStore.can({ action: 'read', subject: 'groups' })"
            :to="{ name: 'groups.list' }"
          >
            <q-icon class="fa fa-layer-group q-mr-sm" />
            Groups
          </RouterLink>

          <RouterLink
            v-if="authUserStore.can({ action: 'read', subject: 'access requests' })"
            :to="{ name: 'access-requests.list' }"
          >
            <q-icon class="fa fa-person-circle-question q-mr-sm" />
            Access Requests
            <span v-if="authUser?.access_request_count" class="access-request-count">
              {{ authUser?.access_request_count }}
            </span>
          </RouterLink>

          <RouterLink
            v-if="authUserStore.can({ action: 'read', subject: 'users' })"
            :to="{ name: 'users.list' }"
          >
            <q-icon class="fa fa-users q-mr-sm" />
            Users
          </RouterLink>

          <RouterLink
            v-if="authUserStore.can({ action: 'read', subject: 'tenant' })"
            :to="{ name: 'tenant' }"
          >
            <q-icon class="fa fa-building q-mr-sm" />
            Organisation
          </RouterLink>

          <q-separator class="q-my-md" color="white" />

          <RouterLink :to="{ name: 'account' }">
            <q-icon class="fa fa-user q-mr-sm" />
            Account
          </RouterLink>

          <a @click="logout">
            <q-icon class="fa fa-right-from-bracket q-mr-sm" />
            Logout
          </a>
        </div>
      </q-drawer>

      <RouterView @open-drawer="drawerLeft = !drawerLeft" />
    </q-layout>
  </Transition>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useAuthUserStore } from '@/stores/auth-user';
import { useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';

const drawerLeft = ref(true);

const router = useRouter();

const authUserStore = useAuthUserStore();
const { authUser } = storeToRefs(authUserStore);

const logout = async () => {
  await authUserStore.logout();
  router.replace({ name: 'login' });
};
</script>
