import SubscribeView from '@/views/subscription/SubscribeView.vue';

export default [
  {
    path: '/subscribe',
    name: 'subscribe',
    component: SubscribeView,
    meta: {
      requiresAuth: true,
    },
  },
];
