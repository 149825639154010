<template>
  <AppContainer title="Users">
    <template #buttons>
      <HelpPopup page="users.list" />

      <q-btn
        v-if="canWriteUsers"
        type="button"
        color="primary"
        icon="add"
        :to="{ name: 'users.add' }"
        round
        dense
      />

      <q-btn
        type="button"
        color="primary"
        icon="search"
        @click="() => (isFiltersOpen = !isFiltersOpen)"
        round
        dense
      />
    </template>

    <template #drawerRight>
      <FilterDrawer v-model:is-open="isFiltersOpen" v-model:filters="filters" />
    </template>

    <q-page-container>
      <q-page class="q-pa-md">
        <div class="row justify-center">
          <q-list class="q-layout q-pa-md">
            <q-infinite-scroll @load="onLoad" :offset="250" ref="infiniteScroll">
              <template v-slot:loading>
                <div class="row justify-center q-my-md">
                  <q-spinner-dots color="primary" size="40px" />
                </div>
              </template>

              <div v-for="user in users" :key="user.id">
                <ListItem :user="user" />
              </div>
            </q-infinite-scroll>
          </q-list>
        </div>
      </q-page>
    </q-page-container>
  </AppContainer>
</template>

<script lang="ts" setup>
import { useUserStore, type UserListFilters } from '@/stores/user';
import { useAuthUserStore } from '@/stores/auth-user';
import { storeToRefs } from 'pinia';
import { ref, watch, onMounted, onBeforeUnmount, computed } from 'vue';
import type { QInfiniteScroll } from 'quasar';
import FilterDrawer from '@/components/users/FilterDrawer.vue';
import ListItem from '@/components/users/ListItem.vue';

const infiniteScroll = ref<QInfiniteScroll | null>(null);

const isFiltersOpen = ref(false);
const filters = ref<UserListFilters>({});

const authUserStore = useAuthUserStore();

const userStore = useUserStore();
const { users } = storeToRefs(userStore);

const canWriteUsers = computed(() => authUserStore.can({ action: 'write', subject: 'users' }));

watch(
  filters,
  async () => {
    if (!infiniteScroll.value) {
      return;
    }

    const response = await userStore.getUsers({ page: 1, filters: filters.value, reset: true });
    infiniteScroll.value.setIndex(1);
    if (response.length < 50) {
      infiniteScroll.value.stop();
    }
  },
  { deep: true }
);

const onLoad = async (index: number, done: (stop: boolean) => void) => {
  const response = await userStore.getUsers({ page: index, filters: filters.value });
  done(response.length < 50);
};

onMounted(() => {
  if (!infiniteScroll.value) {
    return;
  }

  infiniteScroll.value.trigger();
});

onBeforeUnmount(() => {
  if (infiniteScroll.value) {
    infiniteScroll.value.reset();
  }

  userStore.resetUsers();
});
</script>
