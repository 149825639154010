<template>
  <RouterLink :to="{ name: 'group.details', params: { id: group.id } }">
    <q-item class="q-my-sm" clickable v-ripple>
      <q-item-section avatar>
        <q-avatar :text-color="lockColour">
          <q-icon v-if="group.has_access" class="fa fa-lock-open" />
          <q-icon v-else class="fa fa-lock" />
        </q-avatar>
      </q-item-section>

      <q-item-section>
        <q-item-label>
          {{ group.name }}

          <q-badge v-if="group.can_grant_access" color="positive" style="margin-left: 0.5rem">
            Grant access
          </q-badge>

          <q-badge v-if="group.recovery" color="negative" style="margin-left: 0.5rem">
            Account Recovery
          </q-badge>
        </q-item-label>
      </q-item-section>

      <q-item-section side>
        {{ createdAt }}
      </q-item-section>
    </q-item>
  </RouterLink>
</template>

<script lang="ts" setup>
import { computed, toRefs } from 'vue';
import type { Group } from '@/stores/group';
import formatDatetime from '@/composable/format-datetime';

const props = defineProps<{
  group: Group;
}>();

const { group } = toRefs(props);

const createdAt = formatDatetime(group.value.created_at);

const lockColour = computed(() => {
  if (group.value.has_access) {
    return 'positive';
  }

  if (group.value.has_requested_access) {
    return 'warning';
  }

  return 'negative';
});
</script>
