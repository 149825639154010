<template>
  <q-drawer side="right" v-model="isOpen" :width="350" class="q-pa-md filter-drawer">
    <div class="column">
      <div class="col q-gutter-md">
        <h5 class="q-ma-sm q-ml-md q-mt-md">Group Secret Filters</h5>

        <q-input label="Search" v-model="filters.search" />

        <DateSelect label="Created At" v-model="filters.created_at" range />

        <q-select
          label="Sort"
          :options="sortOptions"
          v-model="filters.sort"
          map-options
          emit-value
        />
      </div>

      <div class="col-auto">
        <q-btn class="q-mt-auto" @click="resetFilters">Reset Filters</q-btn>
      </div>
    </div>
  </q-drawer>
</template>

<script lang="ts" setup>
import { toRefs, reactive } from 'vue';
import { watchDebounced } from '@vueuse/core';

const props = defineProps<{
  isOpen: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:filters', value: typeof filters): void;
}>();

const { isOpen } = toRefs(props);

const sortOptions = [
  { label: 'A-Z', value: null },
  { label: 'Z-A', value: 'descending' },
  { label: 'Newest First', value: 'newest' },
  { label: 'Oldest First', value: 'oldest' },
];

const filters = reactive({
  search: null as string | null,
  created_at: null as { from: string; to: string } | null,
  sort: null as string | null,
});

function resetFilters() {
  filters.search = null;
  filters.created_at = null;
  filters.sort = null;
  emit('update:filters', filters);
}

watchDebounced(filters, () => emit('update:filters', filters), { debounce: 750 });
</script>
