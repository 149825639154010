import {
  createRouter,
  createWebHistory,
  type NavigationGuardNext,
  type RouteLocationNormalized,
} from 'vue-router';
import { useAuthUserStore, type Ability } from '@/stores/auth-user';
import landingRoutes from './routes/landing';
import authRoutes from './routes/auth';
import accountRecoveryRoutes from './routes/account-recovery';
import subscriptionRoutes from './routes/subscription';
import routes from './routes/index';
import { storeToRefs } from 'pinia';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    ...landingRoutes,
    ...authRoutes,
    ...accountRecoveryRoutes,
    ...subscriptionRoutes,
    ...routes,
  ],
});

router.beforeEach(
  async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ): Promise<void> => {
    const store = useAuthUserStore();
    const { authUser } = storeToRefs(store);

    await store.authorize();

    const redirectPath =
      to.name !== 'login' &&
      to.name !== 'logout' &&
      to.name !== null &&
      to.meta.requiresAuth === true
        ? to.path
        : null;

    const redirectConditions = [
      () => {
        if (to.name === 'logout') {
          return true;
        }
      },

      () => {
        if (to.name === 'recover-account') {
          return true;
        }
      },

      () => {
        if (store.loggedIn && to.name === 'landing') {
          return { name: 'secrets' };
        }
      },

      () => {
        if (!store.loggedIn) {
          if (!to.meta.requiresAuth) {
            return true;
          }

          if (to.name !== 'login') {
            return { name: 'login', query: { redirect: redirectPath } };
          }

          return true;
        }
      },

      () => {
        if (!to.meta.requiresAuth && store.loggedIn && to.name !== 'landing') {
          return { name: 'dashboard' };
        }
      },

      () => {
        if (to.name === 'setup.account') {
          return true;
        }
      },

      () => {
        if (authUser.value?.email_verification_required) {
          if (to.name !== 'verify.email') {
            return { name: 'verify.email' };
          }

          return true;
        }
      },

      () => {
        if (authUser.value?.two_factor_verification_required) {
          if (to.name !== 'verify.two-factor') {
            return { name: 'verify.two-factor', query: { redirect: redirectPath } };
          }
          return true;
        }
      },

      () => {
        if (
          !['active', 'trialing', 'past_due'].includes(
            authUser.value?.current_subscription?.subscription_status ?? ''
          )
        ) {
          if (to.name !== 'subscribe') {
            return { name: 'subscribe' };
          }

          return true;
        }
      },

      () => {
        if (!authUser.value?.asymmetric_key_private_decrypted) {
          if (to.name !== 'enter-password' && to.name !== 'setup.account' && to.name !== 'logout') {
            return { name: 'enter-password', query: { redirect: redirectPath } };
          }

          return true;
        }
      },

      () => {
        if (to.meta.ability && !store.can(to.meta.ability as Ability)) {
          return { name: 'secrets' };
        }
      },

      () => {
        if (
          to.name === 'secret.files' &&
          authUser.value?.current_subscription?.package_name === 'Personal'
        ) {
          return { name: 'secrets' };
        }
      },
    ];

    for (const condition of redirectConditions) {
      const redirect = condition();

      if (redirect) {
        next(redirect as NavigationGuardNext);
        return;
      }
    }

    next();
  }
);

export default router;
