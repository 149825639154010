import { useAuthUserStore } from '@/stores/auth-user';
import { useUserStore, type User } from '@/stores/user';
import { importPrivateKey, rsaDecrypt, wrapCryptoKey } from '@/utils/encryption/asymmetric';
import { aesDecrypt, importAesKey } from '@/utils/encryption/symmetric';
import http from '@/utils/http';
import { generateRandomString } from '@/utils/strings';
import { storeToRefs } from 'pinia';

type RecoveryGroupKeys = {
  group_symmetric_key: string;
  group_asymmetric_key_private: string;
  group_user_symmetric_key: string;
  group_user_asymmetric_key_private: string;
};

export default async function recoverUserAccount(user: User): Promise<void> {
  const authUserStore = useAuthUserStore();
  const userStore = useUserStore();

  const { authUser } = storeToRefs(authUserStore);

  // Get recovery group private key
  const response = await http.get('/recovery-group/private-key');

  const keys: RecoveryGroupKeys = response.data;

  const groupUserSymmetricKeyBytes = await rsaDecrypt(
    authUser.value!.asymmetric_key_private_decrypted as CryptoKey,
    keys.group_user_symmetric_key
  );

  const groupUserSymmetricKey = await importAesKey(groupUserSymmetricKeyBytes);

  const groupUserAsymmetricKeyPrivateBytes = await aesDecrypt(
    groupUserSymmetricKey,
    keys.group_user_asymmetric_key_private
  );

  const groupUserAsymmetricKeyPrivate = await importPrivateKey(groupUserAsymmetricKeyPrivateBytes);

  const groupSymmetricKeyBytes = await rsaDecrypt(
    groupUserAsymmetricKeyPrivate,
    keys.group_symmetric_key
  );

  const groupSymmetricKey = await importAesKey(groupSymmetricKeyBytes);

  const groupAsymmetricKeyPrivateBytes = await aesDecrypt(
    groupSymmetricKey,
    keys.group_asymmetric_key_private
  );

  const groupAsymmetricKeyPrivate = await importPrivateKey(groupAsymmetricKeyPrivateBytes);

  // Decrypt user recovery key
  const recoveryKeys = await userStore.getUserRecoveryKey({ id: user.id });

  const recoveryKeyBytes = await rsaDecrypt(groupAsymmetricKeyPrivate, recoveryKeys.symmetric_key);

  const recoveryKey = await importAesKey(recoveryKeyBytes);

  const userPrivateKeyBytes = await aesDecrypt(recoveryKey, recoveryKeys.asymmetric_key_private);

  const userPrivateKey = await importPrivateKey(userPrivateKeyBytes);

  const randomPassword = generateRandomString(32, true);

  const encryptedUserPrivateKey = await wrapCryptoKey(userPrivateKey, randomPassword);

  await http.post(`/users/${user.id}/recover-account`, {
    password: randomPassword,
    private_key: encryptedUserPrivateKey,
  });
}
