<template>
  <q-layout view="hHh lpR fFf">
    <q-page-container class="auth">
      <q-page class="q-layout-padding flex justify-center items-center">
        <q-form class="card card-auth q-pa-lg rounded" @submit="submit">
          <div class="q-gutter-sm">
            <div class="text-center q-mx-none heading q-mt-md q-mb-lg">
              <img src="@/assets/icons/icon.svg" class="logo-image" />
              <h2>strng.io</h2>
            </div>

            <Field name="email" v-slot="{ errorMessage, value, field }">
              <q-input
                label="Email"
                :model-value="value"
                v-bind="field"
                :error-message="errorMessage"
                :error="!!errorMessage"
              />
            </Field>

            <Field name="password" v-slot="{ errorMessage, value, field }">
              <q-input
                label="Password"
                type="password"
                :model-value="value"
                v-bind="field"
                :error-message="errorMessage"
                :error="!!errorMessage"
              />
            </Field>

            <div class="row">
              <div class="col">
                <RouterLink :to="{ name: 'signup' }" class="text-muted">
                  Don't have an account?
                </RouterLink>
              </div>

              <div class="col-auto">
                <q-btn
                  class="text-right"
                  color="primary"
                  label="Login"
                  type="submit"
                  :loading="isLoading"
                />
              </div>
            </div>
          </div>
        </q-form>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script lang="ts" setup>
import { Field, useForm } from 'vee-validate';
import * as yup from 'yup';
import http from '@/utils/http';
import { useAuthUserStore } from '@/stores/auth-user';
import { useRoute, useRouter } from 'vue-router';
import { unwrapPrivateKey } from '@/utils/encryption/asymmetric/index';
import { computed, ref } from 'vue';

const isLoading = ref(false);

const router = useRouter();
const route = useRoute();

const store = useAuthUserStore();

type LoginForm = {
  email?: string;
  password?: string;
};

const schema = yup.object({
  email: yup.string().required().email().label('Email'),
  password: yup.string().required().min(6).label('Password'),
});

const { handleSubmit, setErrors } = useForm<LoginForm>({
  validationSchema: schema,
  initialValues: {},
});

const submit = handleSubmit(async (values, actions) => {
  try {
    isLoading.value = true;

    const response = await http.post('/login', values);

    store.setUser(response.data);

    const privateKey = await unwrapPrivateKey(
      response.data.asymmetric_key_private,
      values.password as string
    );

    store.setPrivateKey(privateKey);

    isLoading.value = false;

    router.push(redirectTo.value);
  } catch (err) {
    isLoading.value = false;
    actions.resetForm();

    setErrors({ email: 'Email or password is incorrect' });
  }
});

const redirectTo = computed(() => {
  if (route.query.redirect) {
    return route.query.redirect as string;
  }

  return {
    name: store.can({ action: 'read', subject: 'dashboard' }) ? 'dashboard' : 'secrets',
  };
});
</script>
