<template>
  <q-form @submit="save">
    <h4 class="q-my-md">Details</h4>

    <div class="row q-mb-sm">
      <Field name="name" v-slot="{ errorMessage, value, field }">
        <q-input
          class="q-px-sm col"
          label="Name"
          :model-value="value"
          v-bind="field"
          :error-message="errorMessage"
          :error="!!errorMessage"
          :disable="cannotWriteTenants"
        />
      </Field>
    </div>

    <hr />

    <h5 class="q-my-md">Settings</h5>

    <div class="q-mb-sm">
      <Field name="two_factor_remember_device_timeout" v-slot="{ errorMessage, value, field }">
        <q-select
          class="q-px-sm"
          label="Two Factor Remember Device Timeout"
          :model-value="value"
          v-bind="field"
          :error-message="errorMessage"
          :error="!!errorMessage"
          :disable="cannotWriteTenants"
          :options="twoFactorRememberTimeoutOptions"
          map-options
          emit-value
        />
      </Field>

      <p class="text-muted text-sm q-mx-md">
        This is how long strng.io will remember the device when selecting "Remember Device" during
        two factor authentication.
      </p>
    </div>

    <hr />

    <h5 class="q-my-md">Extension Settings</h5>

    <div class="q-mb-sm">
      <Field name="extension_private_key_storage_timeout" v-slot="{ errorMessage, value, field }">
        <q-select
          class="q-px-sm"
          label="Extension Private Key Storage Timeout"
          :model-value="value"
          v-bind="field"
          :error-message="errorMessage"
          :error="!!errorMessage"
          :disable="cannotWriteTenants"
          :options="extensionPrivateKeyTimeoutOptions"
          map-options
          emit-value
        />
      </Field>

      <p class="text-muted text-sm q-mx-md">
        This is how long the browser extension will store the users private key before requiring the
        user to input their master password.
      </p>
    </div>

    <div class="row">
      <div class="col" />
      <div class="col-auto">
        <q-btn
          class="text-right"
          color="primary"
          label="Save"
          type="submit"
          :disable="cannotWriteTenants"
        />
      </div>
    </div>
  </q-form>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { Field, useForm } from 'vee-validate';
import * as yup from 'yup';
import { useTenantStore, type EditTenantForm } from '@/stores/tenant';
import { useAuthUserStore } from '@/stores/auth-user';
import { storeToRefs } from 'pinia';
import { useToast } from 'vue-toast-notification';
import { AxiosError } from 'axios';

const toast = useToast();

const tenantStore = useTenantStore();
const authUserStore = useAuthUserStore();

const { tenant } = storeToRefs(tenantStore);

if (!tenant.value) {
  throw new Error('Tenant is undefined.');
}

const cannotWriteTenants = computed(
  () => !authUserStore.can({ action: 'write', subject: 'tenant' })
);

const twoFactorRememberTimeoutOptions = [
  { label: 'Expire With Session', value: 0 },
  { label: '7 Days', value: 7 },
  { label: '14 Days', value: 14 },
  { label: '30 Days', value: 30 },
];

const extensionPrivateKeyTimeoutOptions = [
  { label: 'No Private Key Storage', value: 0 },
  { label: '15 Minutes', value: 900 },
  { label: '30 Minutes', value: 1800 },
  { label: '1 Hour', value: 3600 },
  { label: '3 Hours', value: 10800 },
];

const schema = yup.object({
  name: yup.string().required().label('Name'),
  two_factor_remember_device_timeout: yup
    .number()
    .required()
    .label('Two Factor Remember Device Timeout'),
  extension_private_key_storage_timeout: yup
    .number()
    .required()
    .label('Extension Private Key Storage Timeout'),
});

const initialValues = {
  name: tenant.value.name,
  two_factor_remember_device_timeout: tenant.value.two_factor_remember_device_timeout,
  extension_private_key_storage_timeout: tenant.value.extension_private_key_storage_timeout,
};

const { handleSubmit, setValues, setErrors } = useForm<EditTenantForm>({
  validationSchema: schema,
  initialValues: initialValues,
});

const save = handleSubmit(async (payload) => {
  try {
    const t = await tenantStore.updateTenant({ payload });

    setValues(t);

    toast.success('Organisation saved successfully.');
  } catch (err) {
    if (err instanceof AxiosError) {
      setErrors(err.response?.data);
      return;
    }

    throw err;
  }
});
</script>
