<template>
  <q-item
    class="q-my-sm"
    clickable
    v-ripple
    :to="{ name: 'secret.details', params: { id: groupSecret.id } }"
  >
    <q-item-section>
      <q-item-label>
        {{ groupSecret.name }}
      </q-item-label>
    </q-item-section>

    <q-item-section side>
      {{ createdAt }}
    </q-item-section>

    <q-item-section side>
      <q-btn-dropdown
        class="dropdown-ellipsis"
        dropdown-icon="fa-solid fa-ellipsis-vertical"
        :disable="disableDropdown"
        @click.prevent
      >
        <q-list>
          <q-item clickable v-close-popup @click.prevent="removeFromGroup">
            <q-item-section>
              <q-item-label color="negative">
                <q-icon name="fa-solid fa-trash" class="q-mr-sm" />
                Remove from group
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-btn-dropdown>
    </q-item-section>
  </q-item>
</template>

<script lang="ts" setup>
import { computed, toRefs } from 'vue';
import type { GroupSecret } from '@/stores/group/secrets';
import { useSecretAccessStore } from '@/stores/secret/access';
import formatDatetime from '@/composable/format-datetime';
import { isAxiosError } from 'axios';
import { useToast } from 'vue-toast-notification';
import { useGroupStore } from '@/stores/group';
import { storeToRefs } from 'pinia';

const props = defineProps<{
  groupSecret: GroupSecret;
}>();

const emit = defineEmits<{
  (e: 'refresh'): void;
}>();

const toast = useToast();

const { groupSecret } = toRefs(props);

const groupStore = useGroupStore();
const { group } = storeToRefs(groupStore);

const secretAccessStore = useSecretAccessStore();

const createdAt = formatDatetime(groupSecret.value.created_at);

const disableDropdown = computed(() => {
  return !group.value?.can_grant_access;
});

async function removeFromGroup() {
  try {
    await secretAccessStore.revokeAccess(groupSecret.value!.id, {
      type: 'group',
      id: groupSecret.value.group_id,
    });

    emit('refresh');

    toast.success('Removed from group successfully.');
  } catch (err) {
    if (isAxiosError(err)) {
      if (err.response?.status === 400) {
        toast.error(err.response?.data.message);
      }
    }
  }
}
</script>
