import { bytesToArrayBuffer, bytesToString } from '@/utils/encryption/utils';

export async function rsaEncrypt(publicKey: CryptoKey, plaintext: Uint8Array): Promise<string> {
  const data: ArrayBuffer = bytesToArrayBuffer(plaintext);

  const encData = await window.crypto.subtle.encrypt(
    {
      name: 'RSA-OAEP',
    },
    publicKey,
    data
  );

  return window.btoa(bytesToString(encData));
}
