<template>
  <h5 class="q-mt-lg q-mb-md text-negative"><b>Danger</b></h5>

  <div class="row">
    <div class="col">
      <b>Delete Vault Contents: </b>
      <p>This will not delete any users in this organisation.</p>
    </div>
    <div class="col-auto">
      <q-btn
        class="text-right"
        color="negative"
        label="Delete"
        @click="deleteEverything"
        :loading="buttonLoading"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useToast } from 'vue-toast-notification';
import { useConfirmationDialog } from '@/composable/form/use-confirmation-dialog';
import { useTenantStore } from '@/stores/tenant';

const buttonLoading = ref(false);

const toast = useToast();
const confirmationDialog = useConfirmationDialog();

const tenantStore = useTenantStore();

async function deleteEverything() {
  try {
    buttonLoading.value = true;

    await confirmationDialog.confirm({
      title: 'Delete Vault Contents',
      message:
        'This will delete all secrets and groups in this organisation, and cannot be undone. Are you sure you want to continue?',
      options: {
        okLabel: 'Delete',
        cancelLabel: 'Cancel',
      },
    });

    await tenantStore.deleteVaultContents();

    buttonLoading.value = false;
  } catch (err) {
    buttonLoading.value = false;
    toast.error('Failed to delete vault contents, please try again later.');

    throw err;
  }
}
</script>
