<template>
  <AppContainer :title="title">
    <template #buttons>
      <HelpPopup page="account.security" />
    </template>

    <template #tabs>
      <slot name="tabs" />
    </template>

    <q-page-container>
      <q-page class="q-pa-md">
        <AccountTwoFactorCard />

        <AccountPasswordCard />
      </q-page>
    </q-page-container>
  </AppContainer>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import AccountTwoFactorCard from '@/components/account/AccountTwoFactorCard.vue';
import AccountPasswordCard from '@/components/account/AccountPasswordCard.vue';
import { useAuthUserStore } from '@/stores/auth-user';

const authUserStore = useAuthUserStore();

const title = computed(() => `${authUserStore.fullName} - Security`);
</script>
