<template>
  <q-layout view="hHh lpR fFf">
    <q-page-container class="auth">
      <q-page class="q-layout-padding flex justify-center items-center">
        <div class="card card-auth q-pa-lg rounded">
          <div class="q-gutter-md text-center">
            <div class="text-center q-mx-none heading q-mt-md q-mb-lg">
              <img src="@/assets/icons/icon.svg" class="logo-image" />
              <h2>strng.io</h2>
            </div>

            <p>
              Thank you for signing up! Before you can proceed, we need to verify your email
              address.
            </p>

            <p>
              If you haven't received the email within a few minutes, please ensure you've entered
              the correct email address and try resending the verification email below:
            </p>

            <q-btn color="primary" @click="resendVerification">Resend Verification Email</q-btn>
            <p>
              If you continue to experience issues, don't hesitate to
              <a href="mailto:support@strng.io">contact our support team</a>.
            </p>
          </div>
        </div>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script lang="ts" setup>
import { onMounted } from 'vue';
import http from '@/utils/http';
import { useAuthUserStore } from '@/stores/auth-user';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toast-notification';

const props = defineProps({
  code: String,
  user_id: String,
});

const router = useRouter();
const toast = useToast();

const authUserStore = useAuthUserStore();

const resendVerification = async () => {
  await http.get('/resend-new-account-verification');
};

onMounted(async () => {
  try {
    if (!props.code || !props.user_id) {
      return;
    }

    await http.post('/verify/email', props);
    await authUserStore.authorize();

    router.replace({ name: 'subscribe' });
  } catch (err) {
    toast.error('An error occurred while verifying your email. Please try again.');
  }
});
</script>
