export async function importAesKey(aesKeyBytes: ArrayBuffer): Promise<CryptoKey> {
  const uint8View = new Uint8Array(aesKeyBytes);

  const imported = await window.crypto.subtle.importKey('raw', uint8View, 'AES-GCM', true, [
    'encrypt',
    'decrypt',
  ]);

  return imported;
}
