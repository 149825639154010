import { useAuthUserStore } from '@/stores/auth-user';
import { importPrivateKey, importPublicKey, rsaDecrypt, rsaEncrypt } from '@/utils/encryption/asymmetric';
import { aesDecrypt, importAesKey } from '@/utils/encryption/symmetric';
import { bytesToString, stringToBytes } from '@/utils/encryption/utils';
import { storeToRefs } from 'pinia';

export interface SecretKey {
  symmetric_key: CryptoKey;
  asymmetric_key_public: CryptoKey | null;
  asymmetric_key_private: CryptoKey | null;
}

type PotentialGroupKeys = {
  group_id: string;
  group_symmetric_key: string;
  group_asymmetric_key_public: string;
  group_asymmetric_key_private: string;
  group_user_symmetric_key: string;
  group_user_asymmetric_key_public: string;
  group_user_asymmetric_key_private: string;
  group_secret_symmetric_key: string;
  group_secret_asymmetric_key_public: string;
  group_secret_asymmetric_key_private: string;

  tenant_id: undefined;
};

type PotentialTenantKeys = {
  tenant_id: string;
  tenant_symmetric_key: string;
  tenant_asymmetric_key_public: string;
  tenant_asymmetric_key_private: string;
  tenant_user_symmetric_key: string;
  tenant_user_asymmetric_key_public: string;
  tenant_user_asymmetric_key_private: string;
  tenant_secret_symmetric_key: string;
  tenant_secret_asymmetric_key_public: string;
  tenant_secret_asymmetric_key_private: string;

  group_id: undefined;
};

export type PotentialKeys = {
  symmetric_key: string;
  asymmetric_key_public: string;
  asymmetric_key_private: string;

  group_id: string | undefined;
  tenant_id: string | undefined;
};

export default function useParseKeys(
  keys: PotentialKeys | PotentialGroupKeys | PotentialTenantKeys
) {
  const authUserStore = useAuthUserStore();

  const parseKeys = async (): Promise<SecretKey> => {
    const { authUser } = storeToRefs(authUserStore);

    if (authUser.value === null) {
      throw new Error('You must be logged in to parse encryption keys.');
    }

    const userPrivKey = authUser.value.asymmetric_key_private_decrypted;
    if (!userPrivKey) {
      throw new Error('Could not get user private key');
    }

    if (keys.group_id !== undefined) {
      return await parseGroupKeys(userPrivKey);
    }

    if (keys.tenant_id !== undefined) {
      return await parseTenantKeys(userPrivKey);
    }

    return await parseUserKeys(userPrivKey);
  };

  const parseUserKeys = async (userPrivKey: CryptoKey): Promise<SecretKey> => {
    keys = keys as PotentialKeys;

    const aesKeyBuffer = await rsaDecrypt(userPrivKey, keys.symmetric_key);

    const aesKeyBytes = new Uint8Array(aesKeyBuffer);

    const aesKey = await importAesKey(aesKeyBytes);

    const privateKeyBytes = await aesDecrypt(aesKey, keys.asymmetric_key_private as string);

    const privateKey = await importPrivateKey(privateKeyBytes);

    const secretPubKey = await importPublicKey(keys.asymmetric_key_public as string);

    return {
      symmetric_key: aesKey,
      asymmetric_key_public: secretPubKey,
      asymmetric_key_private: privateKey,
    };
  };

  const parseGroupKeys = async (userPrivKey: CryptoKey): Promise<SecretKey> => {
    const groupKeys = keys as PotentialGroupKeys;

    const groupUserSymKeyBytes = await rsaDecrypt(userPrivKey, groupKeys.group_user_symmetric_key);

    const groupUserSymKey = await importAesKey(groupUserSymKeyBytes);

    const groupUserPrivKeyBytes = await aesDecrypt(
      groupUserSymKey,
      groupKeys.group_user_asymmetric_key_private
    );

    const groupUserPrivKey = await importPrivateKey(groupUserPrivKeyBytes);

    const groupSymKeyBytes = await rsaDecrypt(
      groupUserPrivKey,
      groupKeys.group_symmetric_key as string
    );

    const groupSymKey = await importAesKey(groupSymKeyBytes);

    const groupPrivKeyBytes = await aesDecrypt(
      groupSymKey,
      groupKeys.group_asymmetric_key_private as string
    );

    const groupPrivKey = await importPrivateKey(groupPrivKeyBytes);

    const groupSecretSymKeyBytes = await rsaDecrypt(
      groupPrivKey,
      groupKeys.group_secret_symmetric_key
    );

    const groupSecretSymKey = await importAesKey(groupSecretSymKeyBytes);

    const groupSecretPrivKeyBytes = await aesDecrypt(
      groupSecretSymKey,
      groupKeys.group_secret_asymmetric_key_private
    );

    const groupSecretPrivKey = await importPrivateKey(groupSecretPrivKeyBytes);

    const groupSecretPubKey = await importPublicKey(groupKeys.group_secret_asymmetric_key_public);

    return {
      symmetric_key: groupSecretSymKey,
      asymmetric_key_public: groupSecretPubKey,
      asymmetric_key_private: groupSecretPrivKey,
    };
  };

  const parseTenantKeys = async (userPrivKey: CryptoKey): Promise<SecretKey> => {
    const tenantKeys = keys as PotentialTenantKeys;

    const tenantUserSymKeyBytes = await rsaDecrypt(
      userPrivKey,
      tenantKeys.tenant_user_symmetric_key
    );

    const tenantUserSymKey = await importAesKey(tenantUserSymKeyBytes);

    const tenantUserPrivKeyBytes = await aesDecrypt(
      tenantUserSymKey,
      tenantKeys.tenant_user_asymmetric_key_private
    );

    const tenantUserPrivKey = await importPrivateKey(tenantUserPrivKeyBytes);

    const tenantSymKeyBytes = await rsaDecrypt(tenantUserPrivKey, tenantKeys.tenant_symmetric_key);

    const tenantSymKey = await importAesKey(tenantSymKeyBytes);

    const tenantPrivKeyBytes = await aesDecrypt(
      tenantSymKey,
      tenantKeys.tenant_asymmetric_key_private
    );

    const tenantPrivKey = await importPrivateKey(tenantPrivKeyBytes);

    const tenantSecretSymKeyBytes = await rsaDecrypt(
      tenantPrivKey,
      tenantKeys.tenant_secret_symmetric_key
    );

    const tenantSecretSymKey = await importAesKey(tenantSecretSymKeyBytes);

    const tenantSecretPrivKeyBytes = await aesDecrypt(
      tenantSecretSymKey,
      tenantKeys.tenant_secret_asymmetric_key_private
    );

    const tenantSecretPrivKey = await importPrivateKey(tenantSecretPrivKeyBytes);

    const tenantSecretPubKey = await importPublicKey(
      tenantKeys.tenant_secret_asymmetric_key_public
    );

    return {
      symmetric_key: tenantSecretSymKey,
      asymmetric_key_public: tenantSecretPubKey,
      asymmetric_key_private: tenantSecretPrivKey,
    };
  };

  // public static async getTenantSecretUrlSalt(): Promise<string> {
  //   const store = useAuthUserStore();
  //   const { authUser } = storeToRefs(store);
  //
  //   const tenantUserSymKeyBytes = await rsaDecrypt(
  //     authUser.value.asymmetric_private_key_decrypted as CryptoKey,
  //     authUser.value.tenant_user_symmetric_key
  //   );
  //
  //   const tenantUserSymKey = await importAesKey(tenantUserSymKeyBytes);
  //
  //   const urlSalt = await aesDecrypt(tenantUserSymKey, authUser.value.tenant_user_secret_url_salt);
  //
  //   return bytesToString(urlSalt);
  // }

  return {
    parseKeys,
  };
}
