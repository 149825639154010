import { useAuthUserStore } from '@/stores/auth-user';
import { storeToRefs } from 'pinia';
import {
  exportPublicKey,
  generateRsaKey,
  rsaDecrypt,
  rsaEncrypt,
  wrapCryptoKey,
} from '@/utils/encryption/asymmetric';
import { generateRandomString } from '@/utils/strings';

export type NewTenantUserKeys = {
  asymmetric_key_private_decrypted?: CryptoKey;

  asymmetric_key_public: string;
  asymmetric_key_private: string;

  tenant_user_symmetric_key: string;
  tenant_user_symmetric_key_password: string;
};

export default async function createTenantUserKeys(): Promise<NewTenantUserKeys> {
  const authUserStore = useAuthUserStore();
  const { authUser } = storeToRefs(authUserStore);

  if (!authUser.value || !authUser.value.asymmetric_key_private_decrypted) {
    throw new Error('Cannot create user keys without a decrypted private key.');
  }

  const tenantUserSymmetricKeyDecrypted = await rsaDecrypt(
    authUser.value.asymmetric_key_private_decrypted,
    authUser.value.tenant_user_symmetric_key
  );

  const tenantUserSymmetricKeyBuffer = new Uint8Array(tenantUserSymmetricKeyDecrypted);

  const newTenantUserSymmetricKeyPassword = generateRandomString(32, true);

  const newUserAsymmetricKeyPair = await generateRsaKey();

  if (
    newUserAsymmetricKeyPair.publicKey === undefined ||
    newUserAsymmetricKeyPair.privateKey === undefined
  ) {
    throw new Error('Failed to create a keypair for new tenant user.');
  }

  const newTenantUserSymmetricKeyEncrypted = await rsaEncrypt(
    newUserAsymmetricKeyPair.publicKey,
    tenantUserSymmetricKeyBuffer
  );

  const newUserAsymmetricKeyPrivateEncrypted = await wrapCryptoKey(
    newUserAsymmetricKeyPair.privateKey,
    newTenantUserSymmetricKeyPassword
  );

  const newUserAsymmetricKeyPublicExported = await exportPublicKey(
    newUserAsymmetricKeyPair.publicKey
  );

  return {
    asymmetric_key_private_decrypted: newUserAsymmetricKeyPair.privateKey,

    asymmetric_key_public: newUserAsymmetricKeyPublicExported,
    asymmetric_key_private: newUserAsymmetricKeyPrivateEncrypted,

    tenant_user_symmetric_key: newTenantUserSymmetricKeyEncrypted,
    tenant_user_symmetric_key_password: newTenantUserSymmetricKeyPassword,
  };
}
