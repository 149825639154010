import { defineStore } from 'pinia';
import http from '@/utils/http';
import type { Role } from './role';

export interface UserState {
  users: User[];
  user?: User;
  permissions: Permission[];
}

export interface User {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  new_email?: string;
  timezone: string;
  date_of_birth: string;
  role: Role;
  status: string;
  created_at: string;
  profile_pic_url?: string;
  two_factor_enabled: boolean;
  two_factor_method: string;
  last_login?: string;
  last_login_ip?: string;
}

export type Permission = {
  id: string;
  name: string;
  title: string;
  has_permission?: boolean;
};

export interface UserListFilters {
  search?: string;
  created_at?: { from: string; to: string };
  status?: string;
  sort?: string;
}

export const useUserStore = defineStore('users', {
  state: (): UserState => ({
    users: [],
    user: undefined,
    permissions: [],
  }),

  getters: {
    fullName: (state) => `${state.user?.first_name} ${state.user?.last_name}`,
  },

  actions: {
    async getUsers({
      page,
      filters,
      reset = false,
    }: {
      page: number;
      filters?: UserListFilters;
      reset?: boolean;
    }) {
      const response = await http.get('/users', { params: { page, ...filters } });

      if (reset) {
        this.users = [];
      }

      this.users.push(...response.data);

      return response.data;
    },

    resetUsers() {
      this.users = [];
    },

    async getUser({ id }: { id: string }): Promise<User> {
      const response = await http.get(`/users/${id}`);

      this.user = response.data;

      return this.user as User;
    },

    async addUser({ payload }: { payload: CreateUserPayload }): Promise<User> {
      const response = await http.post('/users', payload);

      this.user = response.data;

      return this.user as User;
    },

    async updateUser({ id, payload }: { id: string; payload: EditUserForm }): Promise<User> {
      const formData = new FormData();
      const form: Record<string, unknown> = {};

      Object.entries(payload).forEach(([key, value]) => {
        if (value instanceof File) {
          formData.append(key, value);
        } else {
          form[key] = value;
        }
      });

      formData.append('form', JSON.stringify(form));

      const response = await http.post(`/users/${id}`, formData, {
        headers: { 'Content-Type': undefined },
      });

      this.user = response.data;

      return this.user as User;
    },

    async getPermissions({ id }: { id: string }): Promise<Permission[]> {
      const response = await http.get(`/users/${id}/permissions`);

      this.permissions = response.data;

      return this.permissions;
    },

    async updatePermissions({
      id,
      payload,
    }: {
      id: string;
      payload: Permission[];
    }): Promise<Permission[]> {
      const response = await http.put(`/users/${id}/permissions`, payload);

      this.permissions = response.data;

      return this.permissions;
    },

    async getUserRecoveryKey({ id }: { id: string }): Promise<UserRecoveryKey> {
      const response = await http.get(`/users/${id}/recovery-key`);
      return response.data;
    },
  },
});

export type AddUserForm = {
  first_name: string;
  last_name: string;
  email: string;
  timezone: string;
  date_of_birth: string;
  role_id: string;
};

export type EditUserForm = {
  first_name?: string;
  last_name?: string;
  email?: string;
  timezone?: string;
  date_of_birth?: string;
  role_id?: string;
  profile_pic?: File;
};

export type EditUserSecurityForm = {
  two_factor_enabled?: boolean;
  two_factor_method?: string;
};

export type CreateUserPayload = {
  first_name: string;
  last_name: string;
  email: string;
  timezone: string;
  date_of_birth: string;
  role_id: string;
  asymmetric_key_public: string;
  asymmetric_key_private: string;
  tenant_user_symmetric_key: string;
  tenant_user_symmetric_key_password: string;
};

export type UserRecoveryKey = {
  id: string;
  user_id: string;
  symmetric_key: string;
  asymmetric_key_private: string;
};
