<template>
  <AppContainer :title="title">
    <template #buttons>
      <HelpPopup page="groups.details" />
    </template>

    <template #tabs>
      <slot name="tabs" />
    </template>

    <q-page-container>
      <q-page class="q-pa-md">
        <div class="row justify-center">
          <q-form class="q-layout col-12 col-lg-6 q-pa-md" @submit="save">
            <div class="row q-mb-md">
              <div class="q-px-sm col">
                <RecoveryGroupInfoBanner v-if="group?.recovery" />
              </div>
            </div>

            <div class="row q-mb-sm">
              <Field name="name" v-slot="{ errorMessage, value, field }">
                <q-input
                  class="q-px-sm col"
                  label="Name"
                  :model-value="value"
                  v-bind="field"
                  :error-message="errorMessage"
                  :error="!!errorMessage"
                  :disable="!group?.has_access || group?.recovery"
                />
              </Field>
            </div>

            <div class="row q-mb-sm">
              <Field name="note" v-slot="{ errorMessage, value, field }">
                <q-input
                  class="q-px-sm col"
                  label="Note"
                  type="textarea"
                  :model-value="value"
                  v-bind="field"
                  :error-message="errorMessage"
                  :error="!!errorMessage"
                  :disable="!group?.has_access || group?.recovery"
                />
              </Field>
            </div>

            <div class="row q-mb-sm">
              <Field name="hidden" v-slot="{ errorMessage, value, field }">
                <q-checkbox
                  class="q-px-sm col"
                  label="Hide group from users who do not have access."
                  :model-value="value"
                  v-bind="field"
                  :error-message="errorMessage"
                  :error="!!errorMessage"
                  :disable="!group?.has_access || group?.recovery"
                />
              </Field>
            </div>

            <div class="row" v-if="!group?.recovery">
              <div class="col">
                <q-btn
                  v-if="group?.has_access"
                  class="text-right"
                  color="negative"
                  label="Delete"
                  type="button"
                  @click="deleteGroup"
                  :loading="deleteButtonLoading"
                />
              </div>

              <div class="col-auto">
                <q-btn
                  v-if="group?.has_access"
                  class="text-right"
                  color="primary"
                  label="Save"
                  type="submit"
                  :loading="buttonLoading"
                />

                <q-btn
                  v-if="!group?.has_access && !group?.has_requested_access"
                  class="text-right"
                  color="primary"
                  label="Request Access"
                  @click="requestAccess"
                  :loading="buttonLoading"
                />
              </div>
            </div>
          </q-form>
        </div>
      </q-page>
    </q-page-container>
  </AppContainer>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import { Field, useForm } from 'vee-validate';
import * as yup from 'yup';
import { useGroupStore, type AddGroupForm } from '@/stores/group';
import { isAxiosError } from 'axios';
import { storeToRefs } from 'pinia';
import { useToast } from 'vue-toast-notification';
import { useRouter } from 'vue-router';
import { useConfirmationDialog } from '@/composable/form/use-confirmation-dialog';
import RecoveryGroupInfoBanner from '@/components/groups/RecoveryGroupInfoBanner.vue';

const buttonLoading = ref(false);
const deleteButtonLoading = ref(false);

const groupStore = useGroupStore();
const { group } = storeToRefs(groupStore);

const toast = useToast();
const router = useRouter();
const confirmationDialog = useConfirmationDialog();

const schema = yup.object({
  name: yup.string().required().label('Name'),
  note: yup.string().label('Note'),
  hidden: yup.boolean().label('Hidden'),
});

const { handleSubmit, setErrors } = useForm<AddGroupForm>({
  validationSchema: schema,
  initialValues: {
    name: group.value?.name,
    note: group.value?.note,
    hidden: group.value?.hidden,
  },
});

const save = handleSubmit(async (payload) => {
  if (!group.value) {
    toast.error('An error occurred, please try again later.');
    return;
  }

  buttonLoading.value = true;

  try {
    await groupStore.updateGroup({ id: group.value.id, payload });

    buttonLoading.value = false;

    toast.success('Group updated successfully.');
  } catch (err) {
    if (isAxiosError(err) && err.response?.status === 422) {
      setErrors(err.response?.data);
      return;
    }

    throw err;
  }
});

const requestAccess = async () => {
  if (group.value === null) {
    toast.error('An error occurred, please try again later.');
    return;
  }

  buttonLoading.value = true;

  try {
    await groupStore.requestAccess(group.value.id);
    buttonLoading.value = false;
    toast.success('Access requested successfully.');
  } catch (err) {
    toast.error('Failed to request access.');
    buttonLoading.value = false;
  }
};

async function deleteGroup() {
  if (!group.value) {
    toast.error('An error occurred, please try again later.');
    return;
  }

  if (
    !(await confirmationDialog.confirm({
      title: 'Delete Group',
      message: 'Are you sure you want to delete this group? This action cannot be undone.',
      options: {
        okLabel: 'Delete',
        cancelLabel: 'Cancel',
      },
    }))
  ) {
    return;
  }

  deleteButtonLoading.value = true;

  try {
    await groupStore.deleteGroup(group.value.id);
    deleteButtonLoading.value = false;
    toast.success('Group deleted successfully.');
    router.replace({ name: 'groups.list' });
  } catch (err) {
    deleteButtonLoading.value = false;
    if (isAxiosError(err) && err.response?.status === 400) {
      toast.error(err.response?.data.message);
      return;
    }

    toast.error('Failed to delete group.');
    throw err;
  }
}

const title = computed(() => `${group.value?.name} - Details`);
</script>
