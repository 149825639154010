<template>
  <RouterView v-if="user?.id" v-slot="{ Component }: any">
    <component :is="Component">
      <template #tabs>
        <q-tabs align="left">
          <q-route-tab :to="{ name: 'user.details' }" label="Details" />
          <q-route-tab :to="{ name: 'user.security' }" label="Security" />
          <q-route-tab
            v-if="user?.id !== authUser?.id"
            :to="{ name: 'user.permissions' }"
            label="Permissions"
          />
        </q-tabs>
      </template>
    </component>
  </RouterView>
</template>

<script lang="ts" setup>
import { useUserStore } from '@/stores/user';
import { useAuthUserStore } from '@/stores/auth-user';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';

const route = useRoute();

const authUserStore = useAuthUserStore();
const { authUser } = storeToRefs(authUserStore);

const userStore = useUserStore();
const { user } = storeToRefs(userStore);

await userStore.getUser({
  id: route.params.id as string,
});

if (user?.value === undefined) {
  throw new Error('User is undefined.');
}
</script>
