import { bytesToArrayBuffer, stringToBytes } from '@/utils/encryption/utils';

export async function aesDecrypt(aesKey: CryptoKey, ciphertext: string): Promise<ArrayBuffer> {
  const decoded = window.atob(ciphertext);

  const dataBytes: Uint8Array = stringToBytes(decoded.slice(12));
  const dataBuffer: ArrayBuffer = bytesToArrayBuffer(dataBytes);

  const ivStr: string = decoded.slice(0, 12);
  const ivBytes: Uint8Array = stringToBytes(ivStr);
  const ivBuffer: ArrayBuffer = bytesToArrayBuffer(ivBytes);

  return window.crypto.subtle.decrypt(
    {
      name: 'AES-GCM',
      iv: ivBuffer,
    },
    aesKey,
    dataBuffer
  );
}

export async function aesDecryptFile(
  aesKey: CryptoKey,
  ciphertext: ArrayBuffer
): Promise<ArrayBuffer> {
  const ciphertextBytes: Uint8Array = new Uint8Array(ciphertext);

  const iv: Uint8Array = ciphertextBytes.slice(0, 12);

  const encryptedData: Uint8Array = ciphertextBytes.slice(12);

  return window.crypto.subtle.decrypt(
    {
      name: 'AES-GCM',
      iv: iv,
    },
    aesKey,
    encryptedData
  );
}
