import { defineStore } from 'pinia';
import http from '@/utils/http';

export interface GroupAccessState {
  groupAccess: GroupAccess[];
  groupAccessOptions: GroupAccessOption[];
}

export interface GroupAccess {
  id: string;
  name: string;
  created_at: string;
}

export interface GroupAccessOption {
  id: string;
  name: string;
}

export interface GroupAccessListFilters {
  search?: string;
  created_at?: { from: string; to: string };
}

export const useGroupAccessStore = defineStore('groupAccess', {
  state: (): GroupAccessState => ({
    groupAccess: [],
    groupAccessOptions: [],
  }),

  getters: {},

  actions: {
    async getGroupAccess({
      groupID,
      page,
      filters,
      reset = false,
    }: {
      groupID: string;
      page: number;
      filters?: GroupAccessListFilters;
      reset: boolean;
    }) {
      const response = await http.get(`/groups/${groupID}/access`, {
        params: { page, ...filters },
      });

      if (reset) {
        this.groupAccess = [];
      }

      this.groupAccess.push(...(response.data ?? []));

      return this.groupAccess;
    },

    resetGroupAccess() {
      this.groupAccess = [];
    },

    async getGroupAccessOptions(groupID: string, adminOnly: boolean = false) {
      const response = await http.get(`/groups/${groupID}/access-options`, {
        params: {
          admin_only: adminOnly,
        },
      });

      this.groupAccessOptions = response.data ?? [];

      return this.groupAccessOptions;
    },

    resetGroupAccessOptions() {
      this.groupAccessOptions = [];
    },

    async addUserAccess(groupID: string, payload: AddUserAccessPayload) {
      await http.post(`/groups/${groupID}/add/user`, payload);
      await this.getGroupAccess({ groupID, page: 1, reset: true });
    },

    async revokeAccess(groupID: string, payload: RevokeAccessPayload) {
      await http.post(`/groups/${groupID}/revoke`, payload);
    },
  },
});

export type AddUserAccessPayload = {
  user_id: string;
  symmetric_key: string;
  asymmetric_key_private: string;
  asymmetric_key_public: string;
  can_grant_access: boolean;
};

export type RevokeAccessPayload = {
  id: string;
};
