<template>
  <AppContainer :title="title">
    <template #buttons>
      <HelpPopup page="tenant.subscription" />
    </template>

    <template #tabs>
      <slot name="tabs" />
    </template>

    <q-page-container>
      <q-page class="q-pa-md">
        <div class="row justify-center">
          <div class="q-layout col-12 col-lg-6 q-pa-md">
            <TenantSubscriptionCard />

            <hr />

            <TenantPaymentMethodCard />

            <hr />

            <TenantInvoiceList />
          </div>
        </div>
      </q-page>
    </q-page-container>
  </AppContainer>
</template>

<script lang="ts" setup>
import { useTenantStore } from '@/stores/tenant';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import TenantSubscriptionCard from '@/components/tenant/TenantSubscriptionCard.vue';
import TenantPaymentMethodCard from '@/components/tenant/TenantPaymentMethodCard.vue';
import TenantInvoiceList from '@/components/tenant/TenantInvoiceList.vue';

const tenantStore = useTenantStore();

const { tenant } = storeToRefs(tenantStore);

const title = computed(() => `${tenant.value?.name} - Subscription`);
</script>
