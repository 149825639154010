<template>
  <AppContainer :title="title">
    <template #buttons>
      <HelpPopup page="secrets.logs" />

      <q-btn
        type="button"
        color="primary"
        icon="search"
        class="q-ml-md"
        @click="() => (isFiltersOpen = !isFiltersOpen)"
        round
        dense
      />
    </template>

    <template #tabs>
      <slot name="tabs" />
    </template>

    <template #drawerRight>
      <FilterDrawer v-model:is-open="isFiltersOpen" v-model:filters="filters" />
    </template>

    <q-page-container>
      <q-page class="q-pa-md">
        <div class="row justify-center">
          <q-list class="q-layout q-pa-md">
            <q-infinite-scroll @load="onLoad" :offset="250" ref="infiniteScroll">
              <template v-slot:loading>
                <div class="row justify-center q-my-md">
                  <q-spinner-dots color="primary" size="40px" />
                </div>
              </template>

              <div v-for="log in secretLogs" :key="log.id">
                <ListItem :secret-log="log" />
              </div>
            </q-infinite-scroll>
          </q-list>
        </div>
      </q-page>
    </q-page-container>
  </AppContainer>
</template>

<script lang="ts" setup>
import { ref, watch, onMounted, onBeforeUnmount, computed } from 'vue';
import { useSecretStore } from '@/stores/secret';
import { useSecretLogStore, type SecretLogListFilters } from '@/stores/secret/logs';
import type { QInfiniteScroll } from 'quasar';
import { storeToRefs } from 'pinia';
import ListItem from '@/components/secrets/logs/ListItem.vue';
import FilterDrawer from '@/components/secrets/logs/FilterDrawer.vue';

const infiniteScroll = ref<QInfiniteScroll | null>(null);

const isFiltersOpen = ref(false);
const filters = ref<SecretLogListFilters>({});

const secretStore = useSecretStore();
const { secret } = storeToRefs(secretStore);

const secretLogStore = useSecretLogStore();
const { secretLogs } = storeToRefs(secretLogStore);

watch(
  filters,
  async () => {
    if (!infiniteScroll.value) {
      return;
    }

    const response = await secretLogStore.getSecretLogs({
      secretID: secret.value!.id,
      page: 1,
      filters: filters.value,
      reset: true,
    });
    infiniteScroll.value.setIndex(1);
    if (response.length < 50) {
      infiniteScroll.value.stop();
    }
  },
  { deep: true }
);

const onLoad = async (index: number, done: (stop: boolean) => void) => {
  const response = await secretLogStore.getSecretLogs({
    secretID: secret.value!.id,
    page: index,
    filters: filters.value,
    reset: false,
  });
  done(response.length < 50);
};

onMounted(() => {
  if (!infiniteScroll.value) {
    return;
  }

  infiniteScroll.value.trigger();
});

onBeforeUnmount(() => {
  if (infiniteScroll.value) {
    infiniteScroll.value.reset();
  }

  secretLogStore.resetSecretLogs();
});

const title = computed(() => `${secret.value?.name} - Logs`);
</script>
