import { useAuthUserStore } from '@/stores/auth-user';
import type { AddGroupForm, CreateGroupPayload } from '@/stores/group';
import {
  exportPrivateKey,
  exportPublicKey,
  generateRsaKey,
  importPublicKey,
  rsaEncrypt,
} from '@/utils/encryption/asymmetric';
import { aesEncrypt, exportAesKey, generateAesKey } from '@/utils/encryption/symmetric';
import { stringToBytes } from '@/utils/encryption/utils';
import { storeToRefs } from 'pinia';

async function createKeys(): Promise<{
  symmetricKey: CryptoKey;
  asymmetricKeyPublic: CryptoKey;
  asymmetricKeyPrivate: string;
}> {
  const symmetricKey: CryptoKey = await generateAesKey();

  const asymmetricKeyPair = await generateRsaKey();

  if (asymmetricKeyPair.publicKey === undefined || asymmetricKeyPair.privateKey === undefined) {
    throw new Error('Could not generate RSA key pair.');
  }

  const privateKeyString = await exportPrivateKey(asymmetricKeyPair.privateKey);

  const privateKeyBytes = stringToBytes(privateKeyString);
  const encryptedPrivateKey: string = await aesEncrypt(symmetricKey, privateKeyBytes);

  return {
    symmetricKey: symmetricKey,
    asymmetricKeyPublic: asymmetricKeyPair.publicKey,
    asymmetricKeyPrivate: encryptedPrivateKey,
  };
}

export default async function createGroup(groupForm: AddGroupForm): Promise<CreateGroupPayload> {
  const authUserStore = useAuthUserStore();
  const { authUser } = storeToRefs(authUserStore);

  if (authUser.value === null) {
    throw new Error('User is not authenticated.');
  }

  const groupKeys = await createKeys();
  const groupUserKeys = await createKeys();

  const groupSymmetricKeyExported = await exportAesKey(groupKeys.symmetricKey);
  const groupSymmetricKeyEncrypted = await rsaEncrypt(
    groupUserKeys.asymmetricKeyPublic,
    groupSymmetricKeyExported
  );

  const userPublicKey = await importPublicKey(authUser.value.asymmetric_key_public);

  const groupUserSymmetricKeyExported = await exportAesKey(groupUserKeys.symmetricKey);
  const groupUserSymmetricKeyEncrypted = await rsaEncrypt(
    userPublicKey,
    groupUserSymmetricKeyExported
  );

  const payload: CreateGroupPayload = {
    name: groupForm.name,
    note: groupForm.note ?? null,
    hidden: groupForm.hidden,
    symmetric_key: groupSymmetricKeyEncrypted,
    asymmetric_key_public: await exportPublicKey(groupKeys.asymmetricKeyPublic),
    asymmetric_key_private: groupKeys.asymmetricKeyPrivate,
    group_user_symmetric_key: groupUserSymmetricKeyEncrypted,
    group_user_asymmetric_key_public: await exportPublicKey(groupUserKeys.asymmetricKeyPublic),
    group_user_asymmetric_key_private: groupUserKeys.asymmetricKeyPrivate,
  };

  return payload;
}
