import http from '@/utils/http';
import { defineStore } from 'pinia';

export type TenantState = {
  tenant: Tenant | null;
  urlSalt: string | null;
};

export type Tenant = {
  id: string;
  name: string;
  symmetric_key: string;
  asymmetric_key_public: string;
  asymmetric_key_private: string;
  two_factor_remember_device_timeout: number;
  extension_private_key_storage_timeout: number;
  created_at: string;
  updated_at: string;

  user_count: number;

  // Subscription
  stripe_subscription_id: string;
  stripe_subscription_status: string;
  stripe_subscription_billing_cycle_anchor: string;
  stripe_subscription_current_period_start: string;
  stripe_subscription_current_period_end: string;
  stripe_subscription_cancel_at: string | null;
  stripe_subscription_ended_at: string | null;
  stripe_subscription_trial_start: string | null;
  stripe_subscription_trial_end: string | null;

  stripe_package_id: string;
  stripe_package_name: string;
  stripe_package_price: number;
  stripe_package_min_users: number;
  stripe_package_max_users: number;

  stripe_payment_method: TenantStripePaymentMethod | null;
};

export type TenantStripePaymentMethod = {
  id: string;
  card_last_four: string;
  card_exp_month: number;
  card_exp_year: number;
  card_country: string;
  card_brand: string;
};

export const useTenantStore = defineStore('tenants', {
  state: (): TenantState => ({
    tenant: null,
    urlSalt: null,
  }),

  getters: {},

  actions: {
    async getTenant(): Promise<Tenant> {
      const response = await http.get('/tenant');

      this.tenant = response.data;

      return this.tenant as Tenant;
    },

    async updateTenant({ payload }: { payload: EditTenantForm }): Promise<Tenant> {
      const response = await http.put('/tenant', payload);

      this.tenant = response.data;

      return this.tenant as Tenant;
    },

    async resubscribeSubscription(): Promise<void> {
      await http.post('/tenant/subscription/resubscribe');
    },

    async cancelSubscription(): Promise<void> {
      await http.delete('/tenant/subscription');
    },

    async deleteVaultContents(): Promise<void> {
      await http.delete('/tenant/vault');
    },
  },
});

export type EditTenantForm = {
  name: string;
  extension_private_key_storage_timeout: number;
};
