<template>
  <q-dialog v-model="isOpen" position="top">
    <q-card style="min-width: 30rem">
      <q-card-section class="row items-center q-pb-none">
        <div class="text-h6">Generate</div>
        <q-space />
        <q-btn icon="close" flat round dense v-close-popup />
      </q-card-section>

      <q-card-section>
        <q-input class="q-mb-md" type="text" v-model="modelValue" :readonly="true">
          <template v-slot:append>
            <q-btn round dense flat icon="fa fa-copy" @click="copyPassword" />
          </template>
        </q-input>

        <div class="row q-px-sm">
          <q-slider class="col" v-model="passwordLength" :min="8" :max="32" />
        </div>

        <div class="row">
          <q-toggle class="col" v-model="includeUppercase" label="Uppercase" />
        </div>

        <div class="row">
          <q-toggle v-model="includeLowercase" label="Lowercase" />
        </div>

        <div class="row">
          <q-toggle v-model="includeNumbers" label="Numbers" />
        </div>

        <div class="row">
          <q-toggle v-model="includeSymbols" label="Symbols" />
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang="ts" setup>
import { ref, computed, toRefs, watch } from 'vue';

const props = defineProps<{
  isOpen: boolean;
  modelValue?: string | null;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
  (e: 'update:isOpen', value: boolean): void;
}>();

const { modelValue } = toRefs(props);

const isOpen = computed({
  get() {
    return props.isOpen;
  },

  set(value: boolean) {
    emit('update:isOpen', value);
  },
});

const passwordLength = ref<number>(16);
const includeUppercase = ref<boolean>(true);
const includeLowercase = ref<boolean>(true);
const includeNumbers = ref<boolean>(true);
const includeSymbols = ref<boolean>(true);

function generatePassword(): string {
  let characters = '';

  if (includeUppercase.value) {
    characters += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  }

  if (includeLowercase.value) {
    characters += 'abcdefghijklmnopqrstuvwxyz';
  }

  if (includeNumbers.value) {
    characters += '1234567890';
  }

  if (includeSymbols.value) {
    characters += '!@#$%^&*()_-+=~`\\/<>,.?[]{}';
  }

  let result = '';

  const charactersLength = characters.length;
  for (let i = 0; i < passwordLength.value; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

const copyPassword = () => {
  if (!modelValue?.value) {
    return;
  }

  navigator.clipboard.writeText(modelValue.value);
};

watch([passwordLength, includeUppercase, includeLowercase, includeNumbers, includeSymbols], () => {
  emit('update:modelValue', generatePassword());
});

watch(isOpen, () => {
  if (!isOpen.value) {
    return;
  }

  emit('update:modelValue', generatePassword());
});
</script>
