<template>
  <AppContainer :title="title">
    <template #buttons>
      <HelpPopup page="secrets.details" />
    </template>

    <template #tabs>
      <slot name="tabs" />
    </template>

    <q-page-container>
      <q-page class="q-pa-md">
        <div class="row justify-center">
          <q-form class="q-layout col-12 col-lg-6 q-pa-md secret-form" @submit="save">
            <div class="row q-mb-sm">
              <Field name="name" v-slot="{ errorMessage, value, field }">
                <q-input
                  class="q-px-sm col"
                  label="Name"
                  :model-value="value"
                  v-bind="field"
                  :error-message="errorMessage"
                  :error="!!errorMessage"
                  :disable="!secretDecrypted"
                />
              </Field>
            </div>

            <div class="row q-mb-sm">
              <Field name="username" v-slot="{ errorMessage, value, field }">
                <q-input
                  class="q-px-sm col"
                  label="Username"
                  :model-value="value"
                  v-bind="field"
                  :error-message="errorMessage"
                  :error="!!errorMessage"
                  :type="secretDecrypted ? 'text' : 'password'"
                  :disable="!secretDecrypted"
                />
              </Field>
            </div>

            <div class="row">
              <Field name="secret" v-slot="{ errorMessage, value, field }">
                <q-input
                  class="q-px-sm col"
                  label="Secret"
                  :model-value="value"
                  v-bind="field"
                  :error-message="errorMessage"
                  :error="!!errorMessage"
                  :type="showPassword && secretDecrypted ? 'text' : 'password'"
                  :disable="!secretDecrypted"
                >
                  <template v-slot:append v-if="secretDecrypted">
                    <q-icon
                      :name="showPassword ? 'visibility' : 'visibility_off'"
                      @click="showPassword = !showPassword"
                      class="q-mx-sm"
                    />

                    <q-icon
                      name="content_copy"
                      @click="() => copyTextToClipboard(values?.secret)"
                      class="q-mx-sm"
                    />

                    <q-icon
                      name="shuffle"
                      @click="
                        () => {
                          generateDialogOpen = true;
                          showPassword = true;
                        }
                      "
                      class="q-mx-sm"
                    />
                  </template>
                </q-input>
              </Field>
            </div>

            <PasswordStrength
              v-if="secretDecrypted"
              :password="values.secret"
              @update="updatePasswordStrength"
              class="q-mx-sm q-mb-md"
            />

            <div class="row q-mb-sm">
              <Field name="url" v-slot="{ errorMessage, value, field }">
                <q-input
                  class="q-px-sm col"
                  label="URL"
                  :model-value="value"
                  v-bind="field"
                  :error-message="errorMessage"
                  :error="!!errorMessage"
                  :type="secretDecrypted ? 'text' : 'password'"
                  :disable="!secretDecrypted"
                />
              </Field>
            </div>

            <div class="row q-mb-sm">
              <Field name="note" v-slot="{ errorMessage, value, field }">
                <q-input
                  class="q-px-sm col"
                  label="Notes"
                  type="textarea"
                  :model-value="value"
                  v-bind="field"
                  :error-message="errorMessage"
                  :error="!!errorMessage"
                  :disable="!secretDecrypted"
                />
              </Field>
            </div>

            <div class="row q-mb-sm">
              <Field name="otp_secret" v-slot="{ errorMessage, value, field }">
                <q-input
                  class="q-px-sm col-6"
                  label="OTP Secret"
                  :model-value="value"
                  v-bind="field"
                  :error-message="errorMessage"
                  :error="!!errorMessage"
                  :type="secretDecrypted ? 'text' : 'password'"
                  :disable="!secretDecrypted"
                />
              </Field>

              <div v-if="secretDecrypted && secret?.otp_secret" class="col-6 row totp-code">
                <p class="col">
                  Code: <b>{{ totpToken.code }}</b> - {{ totpToken.seconds }}s
                </p>

                <div class="col-auto">
                  <q-icon
                    name="content_copy"
                    @click="() => copyTextToClipboard(totpToken.code)"
                    class="q-mr-lg text-muted"
                    size="1.5rem"
                  />
                </div>
              </div>

              <div v-else class="col-6 row totp-code">
                <p v-if="!secretDecrypted" class="col" :class="{ 'text-muted': !secretDecrypted }">
                  Code: <b>●●● ●●●</b>
                </p>
                <p v-else class="col">Code: <b>-</b></p>

                <div class="col-auto">
                  <q-icon name="content_copy" class="q-mr-lg text-muted" size="1.5rem" disabled />
                </div>
              </div>
            </div>

            <div class="row q-mb-sm">
              <Field name="hidden" v-slot="{ errorMessage, value, field }">
                <q-checkbox
                  class="q-px-sm col"
                  label="Hide passwords from users who do not have access."
                  :model-value="value"
                  v-bind="field"
                  :error-message="errorMessage"
                  :error="!!errorMessage"
                  :disable="!secretDecrypted"
                />
              </Field>
            </div>

            <div class="row">
              <div class="col">
                <q-btn
                  v-if="secretDecrypted"
                  class="text-right"
                  color="negative"
                  label="Delete"
                  type="button"
                  @click="deleteSecret"
                  :loading="deleteButtonLoading"
                />
              </div>

              <div v-if="secret?.has_access" class="col-auto">
                <q-btn
                  v-if="secretDecrypted"
                  class="text-right"
                  color="primary"
                  label="Save"
                  type="submit"
                  :loading="buttonLoading"
                />

                <q-btn
                  v-if="!secretDecrypted"
                  class="text-right"
                  color="primary"
                  label="Decrypt Secret"
                  type="button"
                  @click="decrypt"
                  :loading="buttonLoading"
                />
              </div>

              <div v-if="!secret?.has_access && !secret?.has_requested_access" class="col-auto">
                <q-btn
                  class="text-right"
                  color="primary"
                  label="Request Access"
                  type="button"
                  @click="requestAccess"
                  :loading="buttonLoading"
                />
              </div>
            </div>
          </q-form>
        </div>
      </q-page>
    </q-page-container>
  </AppContainer>

  <GenerateDialog v-model:is-open="generateDialogOpen" v-model="values.secret" />
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import { Field, useForm } from 'vee-validate';
import * as yup from 'yup';
import { useSecretStore, type UpdateSecretForm } from '@/stores/secret';
import { AxiosError } from 'axios';
import { useToast } from 'vue-toast-notification';
import useSecretEncryption from '@/composable/secret';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import { useConfirmationDialog } from '@/composable/form/use-confirmation-dialog';
import totp from 'totp-generator';
import PasswordStrength from '@/components/form/PasswordStrength.vue';
import type zxcvbn from 'zxcvbn';
import GenerateDialog from '@/components/secrets/GenerateDialog.vue';

const showPassword = ref(false);
const secretDecrypted = ref(false);
const buttonLoading = ref(false);
const deleteButtonLoading = ref(false);
const generateDialogOpen = ref(false);

const toast = useToast();
const router = useRouter();
const confirmationDialog = useConfirmationDialog();

const { decryptSecret, encryptSecret } = useSecretEncryption();

const secretStore = useSecretStore();
const { secret } = storeToRefs(secretStore);

const schema = yup.object({
  name: yup.string().required().label('Name'),
  username: yup.string().nullable().label('Username'),
  secret: yup.string().nullable().label('Password'),
  url: yup.string().nullable().url().label('URL'),
  note: yup.string().nullable().label('Notes'),
  otp_secret: yup.string().nullable().label('OTP Secret'),
  hidden: yup.boolean().label('Hidden'),
  score: yup.number().nullable(),
});

const initialValues = {
  name: secret.value?.name,
  username: '●●●●●●●●',
  secret: '●●●●●●●●',
  url: '●●●●●●●●',
  note: '●●●●●●●●',
  otp_secret: '●●●●●●●●',
  hidden: false,
};

const { handleSubmit, setErrors, values, setValues, setFieldValue } = useForm<UpdateSecretForm>({
  validationSchema: schema,
  initialValues: initialValues,
});

const save = handleSubmit(async (payload) => {
  try {
    if (!secret.value) {
      toast.error('An error occurred, please try again later.');
      return;
    }

    buttonLoading.value = true;

    const encryptedSecret = await encryptSecret(secret.value.id, payload);

    await secretStore.updateSecret({
      id: secret.value.id,
      payload: encryptedSecret,
    });

    buttonLoading.value = false;

    toast.success('Secret updated successfully.');
  } catch (err) {
    buttonLoading.value = false;
    if (err instanceof AxiosError) {
      setErrors(err.response?.data);
    }
  }
});

const deleteSecret = async () => {
  if (!secret.value) {
    toast.error('An error occurred, please try again later.');
    return;
  }

  if (
    !(await confirmationDialog.confirm({
      title: 'Delete Secret',
      message: 'Are you sure you want to delete this secret? This action cannot be undone.',
      options: {
        okLabel: 'Delete',
        cancelLabel: 'Cancel',
      },
    }))
  ) {
    return;
  }

  deleteButtonLoading.value = true;

  try {
    await secretStore.deleteSecret(secret.value.id);
    deleteButtonLoading.value = false;
    toast.success('Secret deleted successfully.');
    router.replace({ name: 'secrets.list' });
  } catch (err) {
    deleteButtonLoading.value = false;
    toast.error('Failed to delete secret.');
    throw err;
  }
};

const copyTextToClipboard = (text?: string | null) => {
  if (!text) {
    return;
  }

  navigator.clipboard.writeText(text);
  toast.success('Copied to clipboard');
};

const decrypt = async () => {
  if (secret.value === null) {
    toast.error('An error occurred, please try again later.');
    return;
  }

  buttonLoading.value = true;

  try {
    const decryptedSecret = await decryptSecret({ ...secret.value });

    setValues({
      name: decryptedSecret.name,
      username: decryptedSecret.username,
      secret: decryptedSecret.secret,
      url: decryptedSecret.url,
      note: decryptedSecret.note,
      otp_secret: decryptedSecret.otp_secret,
      hidden: false,
      score_previous: decryptedSecret.score as number,
    });

    secretDecrypted.value = true;
    buttonLoading.value = false;
    toast.success('Secret decrypted successfully.');
  } catch (err) {
    toast.error('Failed to decrypt secret.');
    buttonLoading.value = false;
  }
};

const requestAccess = async () => {
  if (secret.value === null) {
    toast.error('An error occurred, please try again later.');
    return;
  }

  buttonLoading.value = true;

  try {
    await secretStore.requestAccess(secret.value.id);
    buttonLoading.value = false;
    toast.success('Access requested successfully.');
  } catch (err) {
    toast.error('Failed to request access.');
    buttonLoading.value = false;
  }
};

const totpSecondsLeft = ref<number>(Date.now());
setInterval(function () {
  totpSecondsLeft.value = Date.now();
}, 1000);

const totpToken = computed(() => {
  if (!secretDecrypted.value || !values.otp_secret) {
    return {
      code: null,
      seconds: null,
    };
  }

  return {
    code: totp(values.otp_secret),
    seconds: 60 - new Date(totpSecondsLeft.value).getSeconds(),
  };
});

const updatePasswordStrength = (score: zxcvbn.ZXCVBNResult) => {
  setFieldValue('score', score.score.valueOf());
};

const title = computed(() => `${secret.value?.name} - Details`);
</script>
