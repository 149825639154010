<template>
  <RouterLink :to="{ name: 'user.details', params: { id: user.id } }">
    <q-item class="q-my-sm" clickable v-ripple>
      <q-item-section avatar>
        <q-avatar color="primary" text-color="white">
          <img v-if="user.profile_pic_url" :src="user.profile_pic_url" />
          <template v-else>
            {{ initials }}
          </template>
        </q-avatar>
      </q-item-section>

      <q-item-section>
        <q-item-label>
          {{ user.first_name }} {{ user.last_name }}

          <q-badge v-if="user.status != 'Active'" color="secondary" style="margin-left: 0.5rem">
            {{ user.status }}
          </q-badge>
        </q-item-label>

        <q-item-label caption lines="1">{{ user.email }}</q-item-label>
      </q-item-section>

      <q-item-section side class="q-mr-md text-black">
        {{ user.role.name }}
      </q-item-section>

      <q-item-section side>
        {{ createdAt }}
      </q-item-section>
    </q-item>
  </RouterLink>
</template>

<script lang="ts" setup>
import { computed, toRefs } from 'vue';
import type { User } from '@/stores/user';
import formatDatetime from '@/composable/format-datetime';

const props = defineProps<{
  user: User;
}>();

const { user } = toRefs(props);

const initials = computed(() => {
  return `${user.value.first_name.charAt(0)}${user.value.last_name.charAt(0)}`;
});

const createdAt = formatDatetime(user.value.created_at);
</script>
