<template>
  <AppContainer :title="title">
    <template #buttons>
      <HelpPopup page="users.security" />
    </template>

    <template #tabs>
      <slot name="tabs" />
    </template>

    <q-page-container>
      <q-page class="q-pa-md">
        <div class="row justify-center">
          <div class="q-layout col-12 col-lg-6 card rounded q-pa-md">
            <q-form @submit="save">
              <div class="row">
                <div class="col-12">
                  <h6 class="q-ma-sm">Two Factor Authentication</h6>
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <Field name="two_factor_enabled" v-slot="{ errorMessage, value, field }">
                    <q-checkbox
                      :model-value="value"
                      v-bind="field"
                      :val="true"
                      label="Enabled"
                      :error-message="errorMessage"
                      :error="!!errorMessage"
                      :disable="cannotWriteUsers"
                    />
                  </Field>
                </div>

                <div v-if="values.two_factor_enabled" class="col-6">
                  <Field name="two_factor_method" v-slot="{ value, field }">
                    <q-radio
                      class="q-mr-sm"
                      :model-value="value"
                      v-bind="field"
                      val="email"
                      label="Email"
                      :disable="cannotWriteUsers"
                    />

                    <q-radio
                      class="q-mr-sm"
                      :model-value="value"
                      v-bind="field"
                      val="totp"
                      label="Google Authenticator"
                      :disable="cannotWriteUsers"
                    />
                  </Field>
                </div>
              </div>

              <div class="row q-pt-md q-px-sm">
                <div class="col"></div>

                <div class="col-auto">
                  <q-btn
                    class="text-right"
                    color="primary"
                    label="Save"
                    type="submit"
                    :disable="cannotWriteUsers"
                  />
                </div>
              </div>
            </q-form>

            <div class="row">
              <div class="col-12">
                <h6 class="q-ma-sm">Login Information</h6>
              </div>
            </div>

            <div class="row q-mx-sm">
              <div class="col"><b>Last Login At: </b> {{ lastLoginAt }}</div>

              <div class="col"><b>Last Login From: </b> {{ user?.last_login_ip }}</div>
            </div>
          </div>
        </div>
      </q-page>
    </q-page-container>
  </AppContainer>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { Field, useForm } from 'vee-validate';
import * as yup from 'yup';
import { useUserStore, type EditUserSecurityForm } from '@/stores/user';
import { storeToRefs } from 'pinia';
import { useToast } from 'vue-toast-notification';
import http from '@/utils/http';
import { useAuthUserStore } from '@/stores/auth-user';
import formatDatetime from '@/composable/format-datetime';

const toast = useToast();

const authUserStore = useAuthUserStore();

const userStore = useUserStore();
const { user } = storeToRefs(userStore);

if (user?.value === undefined) {
  throw new Error('User is undefined.');
}

const cannotWriteUsers = computed(() => !authUserStore.can({ action: 'write', subject: 'users' }));

const schema = yup.object({
  two_factor_enabled: yup.boolean().label('Two factor enabled'),
  two_factor_method: yup.string().label('Two factor method'),
});

const initialValues = {
  two_factor_enabled: user?.value?.two_factor_enabled,
  two_factor_method: user?.value?.two_factor_method,
};

const { handleSubmit, values } = useForm<EditUserSecurityForm>({
  validationSchema: schema,
  initialValues: initialValues,
});

const save = handleSubmit(async (payload) => {
  await http.put(`/users/${user.value?.id}/security`, payload);

  toast.success('User updated successfully.');
});

const title = computed(() => `${userStore.fullName} - Security`);

const lastLoginAt = formatDatetime(user.value?.last_login);
</script>
