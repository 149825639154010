<template>
  <q-card flat bordered class="q-my-sm" v-show="hasData">
    <q-card-section class="q-pa-md">
      <div class="text-h5 q-mb-sm">Password Strength</div>

      <canvas ref="analyticsChart"></canvas>
    </q-card-section>
  </q-card>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import {
  Chart,
  LinearScale,
  BarController,
  CategoryScale,
  BarElement,
  Tooltip,
  Legend,
} from 'chart.js';
import http from '@/utils/http';

type AnalyticsData = {
  score: number;
  count: number;
};

const analyticsChart = ref<HTMLCanvasElement>();
const analyticsData = ref<AnalyticsData[]>([]);
const hasData = ref(false);

onMounted(async () => {
  if (!analyticsChart.value) {
    return;
  }

  const resp = await http.get('/dashboard/secret-analytics');
  analyticsData.value = resp.data;

  if (!analyticsData.value?.length) {
    return;
  }

  hasData.value = true;

  Chart.register(LinearScale, BarController, CategoryScale, BarElement, Tooltip, Legend);

  new Chart(analyticsChart.value, {
    type: 'bar',
    data: {
      labels: ['Very Weak', 'Weak', 'Moderate', 'Strong', 'Very Strong'],
      datasets: [
        {
          label: '# of passwords',
          data: [
            analyticsData.value.find((item) => item.score === 0)?.count || 0,
            analyticsData.value.find((item) => item.score === 1)?.count || 0,
            analyticsData.value.find((item) => item.score === 2)?.count || 0,
            analyticsData.value.find((item) => item.score === 3)?.count || 0,
            analyticsData.value.find((item) => item.score === 4)?.count || 0,
          ],
          backgroundColor: ['#C10015', '#F2C037', '#ECC55B', '#4dc76a', '#21BA45'],
          borderWidth: 0,
        },
      ],
    },
    options: {
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    },
  });
});
</script>
