<template>
  <AppContainer :title="tenant?.name">
    <template #tabs>
      <slot name="tabs" />
    </template>

    <q-page-container>
      <q-page class="q-pa-md">
        <div class="row justify-center">
          <div class="q-layout col-12 col-lg-10 q-pa-md card-auth-choose-subscription">
            <h4 class="q-my-sm text-center">Select Subscription</h4>

            <ChooseSubscription
              :max-users="tenant?.user_count"
              :current-subscription="tenant?.stripe_package_id"
              @selected="changeSubscription"
            />
          </div>
        </div>
      </q-page>
    </q-page-container>
  </AppContainer>
</template>

<script lang="ts" setup>
import ChooseSubscription from '@/components/subscriptions/ChooseSubscription.vue';
import type { StripePackage } from '@/stores/subscription';
import { useConfirmationDialog } from '@/composable/form/use-confirmation-dialog';
import { useTenantStore } from '@/stores/tenant';
import { useSubscriptionStore } from '@/stores/subscription';
import { storeToRefs } from 'pinia';
import { useToast } from 'vue-toast-notification';
import { isAxiosError } from 'axios';
import { useRouter } from 'vue-router';

const confirmationDialog = useConfirmationDialog();
const toast = useToast();
const router = useRouter();

const tenantStore = useTenantStore();
const { tenant } = storeToRefs(tenantStore);

const subscriptionStore = useSubscriptionStore();

async function changeSubscription(pkg: StripePackage) {
  if (
    !(await confirmationDialog.confirm({
      title: 'Change Subscription',
      message: `Are you sure you want to change your subscription tier to ${pkg.name}?`,
      options: {
        okLabel: 'Confirm',
        cancelLabel: 'Cancel',
      },
    }))
  ) {
    return;
  }

  try {
    await subscriptionStore.subscribe({
      stripe_package_id: pkg.id,
      use_existing_payment_method: true,
    });
    await tenantStore.getTenant();

    toast.success('Subscription updated successfully.');

    router.push({ name: 'tenant.subscription' });
  } catch (err) {
    if (isAxiosError(err) && err.response?.status === 400) {
      toast.error(
        'Cannot change to subscription with less users than currently in use by your organization. Please remove users before changing your subscription.'
      );
      return;
    }

    throw err;
  }
}
</script>
