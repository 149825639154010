<template>
  <AppContainer :title="title">
    <template #buttons>
      <HelpPopup page="account.details" />
    </template>

    <template #tabs>
      <slot name="tabs" />
    </template>

    <q-page-container>
      <q-page class="q-pa-md">
        <div class="row justify-center">
          <q-form class="q-layout col-12 col-lg-6 q-pa-md" @submit="save">
            <div class="row q-mb-sm">
              <Field name="first_name" v-slot="{ errorMessage, value, field }">
                <q-input
                  class="q-px-sm col"
                  label="First Name"
                  :model-value="value"
                  v-bind="field"
                  :error-message="errorMessage"
                  :error="!!errorMessage"
                />
              </Field>
            </div>

            <div class="row q-mb-sm">
              <Field name="last_name" v-slot="{ errorMessage, value, field }">
                <q-input
                  class="q-px-sm col"
                  label="Last Name"
                  :model-value="value"
                  v-bind="field"
                  :error-message="errorMessage"
                  :error="!!errorMessage"
                />
              </Field>
            </div>

            <div class="row q-mb-sm">
              <Field name="email" v-slot="{ errorMessage, value, field }">
                <q-input
                  class="q-px-sm col"
                  label="Email"
                  :model-value="value"
                  v-bind="field"
                  :error-message="errorMessage"
                  :error="!!errorMessage"
                />
              </Field>

              <span v-if="authUser?.new_email" class="q-pl-sm q-mb-sm text-grey-7 text-sm">
                {{ authUser?.new_email }} will be set on email verification.
              </span>
            </div>

            <div class="row q-mb-sm">
              <Field name="timezone" v-slot="{ errorMessage, value, field }">
                <TimezoneSelect
                  class="q-px-sm col"
                  label="Timezone"
                  :model-value="value"
                  v-bind="field"
                  :error-message="errorMessage"
                  :error="!!errorMessage"
                />
              </Field>
            </div>

            <div class="row q-mb-sm">
              <Field name="date_of_birth" v-slot="{ errorMessage, value, field }">
                <DateSelect
                  class="q-px-sm col"
                  label="Date Of Birth"
                  :model-value="value"
                  v-bind="field"
                  :error-message="errorMessage"
                  :error="!!errorMessage"
                  max="today"
                />
              </Field>
            </div>

            <div class="row q-mb-sm">
              <Field name="profile_pic" v-slot="{ errorMessage, value, field }">
                <q-file
                  class="q-px-sm col-10"
                  :model-value="value"
                  label="Profile Picture"
                  :error-message="errorMessage"
                  :error="!!errorMessage"
                  @blur="field.onBlur"
                  @input="field.onChange"
                >
                  <template v-slot:prepend>
                    <q-icon name="attach_file" />
                  </template>
                </q-file>
              </Field>

              <div class="col flex justify-center">
                <q-avatar color="primary" text-color="white">
                  <img v-if="fileUrl" :src="fileUrl" />
                  <template v-else>
                    {{ initials }}
                  </template>
                </q-avatar>
              </div>
            </div>

            <div class="row">
              <div class="col"></div>

              <div class="col-auto">
                <q-btn class="text-right" color="primary" label="Save" type="submit" />
              </div>
            </div>
          </q-form>
        </div>
      </q-page>
    </q-page-container>
  </AppContainer>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { Field, useForm } from 'vee-validate';
import * as yup from 'yup';
import { useUserStore, type User, type EditUserForm } from '@/stores/user';
import { storeToRefs } from 'pinia';
import TimezoneSelect from '@/components/form/TimezoneSelect.vue';
import DateSelect from '@/components/form/DateSelect.vue';
import RoleSelect from '@/components/form/RoleSelect.vue';
import { AxiosError } from 'axios';
import { useToast } from 'vue-toast-notification';
import { useAuthUserStore } from '@/stores/auth-user';

const toast = useToast();

const authUserStore = useAuthUserStore();
const { authUser } = storeToRefs(authUserStore);

const userStore = useUserStore();

if (!authUser?.value) {
  throw new Error('Auth user is undefined.');
}

const schema = yup.object({
  first_name: yup.string().required().label('First name'),
  last_name: yup.string().required().label('Last name'),
  email: yup.string().required().email().label('Email'),
  timezone: yup.string().required().label('Timezone'),
  date_of_birth: yup.string().required().label('Date of Birth'),
  role_id: yup.string().required().label('Role'),
  profile_pic: yup.mixed().label('Profile Picture'),
});

const initialValues = {
  first_name: authUser.value.first_name,
  last_name: authUser.value.last_name,
  email: authUser.value.email,
  timezone: authUser.value.timezone,
  date_of_birth: authUser.value.date_of_birth,
  role_id: authUser.value.role.id,
  profile_pic: undefined,
};

const { handleSubmit, setErrors, setValues, setFieldValue, values } = useForm<EditUserForm>({
  validationSchema: schema,
  initialValues: initialValues,
});

const initials = computed(() => {
  return `${authUser?.value?.first_name.charAt(0)}${authUser?.value?.last_name.charAt(0)}`;
});

const fileUrl = computed(() => {
  if (values.profile_pic) {
    return URL.createObjectURL(values.profile_pic);
  }

  if (authUser.value?.profile_pic_url) {
    return authUser.value?.profile_pic_url;
  }

  return null;
});

const save = handleSubmit(async (payload) => {
  try {
    const u: User = await userStore.updateUser({ id: authUser.value?.id as string, payload });

    setValues(u);
    setFieldValue('role_id', u.role.id);

    toast.success('User saved successfully.');
  } catch (err) {
    if (err instanceof AxiosError) {
      setErrors(err.response?.data);
    }
  }
});

const title = computed(() => `${authUserStore.fullName} - Details`);
</script>
