<template>
  <q-layout view="hHh lpR fFf" class="landing">
    <LandingHeader />

    <q-page-container>
      <q-page>
        <div class="row justify-center landing-image-desktop">
          <div class="col-6 flex justify-center">
            <img src="@/assets/images/landing-page-header.svg" class="landing-image" />
          </div>
        </div>

        <div class="row text-center justify-center">
          <div class="col-lg-6">
            <h1 class=""><span class="text-gradient">Empowering</span> Teams,</h1>
            <h1 class="">Ensuring <span class="text-gradient">Security</span></h1>
          </div>
        </div>

        <div class="row text-center justify-center">
          <div class="col-lg-6 col-11">
            <p class="q-mt-md first-text">
              Unlock your team's potential with strng.io, a revolutionary platform that goes beyond
              securing accounts to empower your teams and ensure top-notch security in the dynamic
              world of cybersecurity.
            </p>
          </div>
        </div>

        <div class="row text-center justify-center q-mt-xl">
          <div class="col-lg-6 col-12">
            <q-btn
              class="q-mt-md free-trial-button"
              size="lg"
              color="primary"
              label="Start Free Trial"
              :to="{ name: 'signup' }"
              icon-right="arrow_forward"
            />
          </div>
        </div>

        <div class="row text-center justify-center logo-imgs" v-if="false">
          <div class="col-6 q-gutter-lg">
            <img class="logo-img" src="@/assets/images/chrome.svg" />
            <img class="logo-img" src="@/assets/images/firefox.svg" />
            <img class="logo-img" src="@/assets/images/brave.svg" />
            <img class="logo-img" src="@/assets/images/apple.svg" />
            <img class="logo-img" src="@/assets/images/android.svg" />
          </div>
        </div>

        <div class="row justify-center q-mt-xl landing-info">
          <div class="col-lg-4 col-12 flex justify-center q-mr-md">
            <h3 class="q-mb-lg">Refining Access Management</h3>

            <ul class="q-mt-none" style="width: 100%">
              <li>Safely store and autofill passwords on all devices.</li>
              <li>Prevent password reuse with the built-in generator.</li>
              <li>Auto-fill passwords with one click on any device.</li>
              <li>Evaluate security practices and monitor for data breaches.</li>
            </ul>
          </div>

          <div class="col-4 flex justify-center langing-info-img-desktop">
            <img src="@/assets/images/landing-info-img.svg" class="landing-info-img" />
          </div>
        </div>

        <div class="justify-center text-center unlock-potential">
          <h3 class="q-mb-lg">Unlock potential, not just accounts!</h3>

          <p>
            We understand that managing access is not just about securing accounts; it's about
            unlocking the true potential of your teams.
          </p>

          <q-btn
            class="q-mt-md free-trial-button"
            size="lg"
            color="primary"
            outline
            label="Start Free Trial"
            :to="{ name: 'signup' }"
            icon-right="arrow_forward"
          />
        </div>

        <div class="selling-points q-gutter-lg">
          <div class="row justify-center q-gutter-lg">
            <div class="col-lg-4 col-12 flex">
              <img src="@/assets/images/landing-img-1.svg" class="landing-point-img" />
              <h5>Request-Based Access Model</h5>
              <p>
                Embrace spontaneity in access management. Our platform liberates you from the
                constraints of meticulous planning and configuration. With strng.io, users can
                seamlessly request access to essential resources, ensuring a harmonious blend of
                security and efficiency.
              </p>
            </div>

            <div class="col-lg-4 col-12 flex">
              <img src="@/assets/images/landing-img-2.svg" class="landing-point-img" />
              <h5>Engineered for Scalability</h5>
              <p>
                Growth without compromise. Strng.io is meticulously engineered for scalability,
                ensuring seamless access management to sensitive data as your organization expands.
                Because we understand that your security should scale with your success.
              </p>
            </div>
          </div>

          <div class="row justify-center q-gutter-lg">
            <div class="col-lg-4 col-12 flex">
              <img src="@/assets/images/landing-img-3.svg" class="landing-point-img" />
              <h5>Dynamic Approval Process</h5>
              <p>
                Experience unparalleled flexibility in granting access. When a request is initiated,
                strng.io swiftly notifies designated approvers. These decision-makers are presented
                with a dynamic array of access methods, from individual grants to organization-wide
                extensions. Say goodbye to pre-emptive planning - strng.io streamlines the access
                permission process effortlessly.
              </p>
            </div>

            <div class="col-lg-4 col-12 flex">
              <img src="@/assets/images/landing-img-4.svg" class="landing-point-img" />

              <h5>Unrivaled Security Technology</h5>
              <p>
                strng.io employs a robust combination of AES-GCM (Advanced Encryption Standard -
                Galois/Counter Mode) and RSA-OAEP (Rivest-Shamir-Adleman - Optimal Asymmetric
                Encryption Padding)
              </p>
            </div>
          </div>
        </div>

        <div class="flex items-center justify-center selling-points-2">
          <div class="flex items-center selling-points-2-item">
            <h3 class="text-primary q-mr-md">11M+</h3>
            <p class="q-mb-none">Passwords stolen from cloud<br />services every year.</p>
          </div>

          <div class="flex items-center selling-points-2-item">
            <h3 class="text-primary q-mr-md">40%</h3>
            <p class="q-mb-none">Of organisations store passwords<br />in a word document.</p>
          </div>

          <div class="flex items-center selling-points-2-item">
            <h3 class="text-primary q-mr-md">9/10</h3>
            <p class="q-mb-none">Phishing emails carried<br />ransomware</p>
          </div>
        </div>

        <div class="justify-center text-center choose-plan">
          <h3 class="q-mb-lg">Choose your plan!</h3>

          <p>Try strng.io today and usher in a new era of access management excellence.</p>

          <div class="row justify-center">
            <div class="col-lg-9 col-12">
              <ChooseSubscription @selected="subscriptionSelected" />
            </div>
          </div>
        </div>
      </q-page>

      <LandingFooter />
    </q-page-container>
  </q-layout>
</template>

<script lang="ts" setup>
import LandingHeader from '@/components/landing/LandingHeader.vue';
import LandingFooter from '@/components/landing/LandingFooter.vue';
import ChooseSubscription from '@/components/subscriptions/ChooseSubscription.vue';
import { useRouter } from 'vue-router';
import type { StripePackage } from '@/stores/subscription';

const router = useRouter();

function subscriptionSelected(stripePkg: StripePackage) {
  localStorage.setItem('landing-page-pkg-selected', stripePkg.id);
  router.push({ name: 'signup' });
}
</script>
