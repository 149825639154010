<template>
  <div class="pricing-option" :class="{ priority: isPriority, 'has-current-badge': isCurrrent }">
    <div class="row" v-if="isCurrrent">
      <div class="col" />
      <div class="col-auto">
        <q-badge color="positive">Current Subscription</q-badge>
      </div>
    </div>

    <div class="top-row">
      <div class="row items-center">
        <div class="col-md col-12">
          <h2 class="pricing-title">{{ stripePackage.name }}</h2>
        </div>
        <div class="col-md-auto col-12">
          <p class="user-count">{{ userCount }}</p>
        </div>
      </div>

      <ul class="pricing-features">
        <li v-for="(hasAbility, text) in sellingPoints" :key="`${stripePackage.id}-${text}`">
          <q-icon v-if="hasAbility" name="fa fa-check" color="positive" class="q-mr-md q-mb-sm" />
          <q-icon v-else name="fa fa-xmark" color="negative" class="q-mr-md q-mb-sm" />
          <span>{{ text }}</span>
        </li>
      </ul>
    </div>

    <div class="row flex items-center q-mt-lg bottom-row">
      <p class="pricing-price">{{ stripePackage.price }} / month</p>

      <q-btn
        color="primary"
        icon-right="arrow_forward"
        :label="buttonText"
        :disable="isDisabled"
        outline
        @click="() => emit('selected', stripePackage)"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { StripePackage } from '@/stores/subscription';
import { toRefs, computed } from 'vue';

const props = withDefaults(
  defineProps<{
    stripePackage: StripePackage;
    buttonText?: string;
    maxUsers?: number | null;
    currentSubscription?: string | null;
  }>(),
  {
    buttonText: 'Select',
    maxUsers: null,
    currentSubscription: null,
  }
);

const emit = defineEmits<{
  (e: 'selected', value: StripePackage): void;
}>();

const { stripePackage, buttonText, maxUsers, currentSubscription } = toRefs(props);

const isPriority = computed(() => stripePackage.value.name === 'Business');

const userCount = computed(() => {
  if (stripePackage.value.min_users === 0) {
    return `${stripePackage.value.max_users} Users`;
  }

  if (stripePackage.value.max_users === 0) {
    return `${stripePackage.value.min_users}+ Users`;
  }

  return `${stripePackage.value.min_users} - ${stripePackage.value.max_users} Users`;
});

const sellingPointData = {
  'Personal': {
    'Integrated 2FA authentication': true,
    'Unlimited password storage': true,
    'Request based sharing': true,
    'File storage': false,
  },
  Team: {
    'Integrated 2FA authentication': true,
    'Unlimited password storage': true,
    'Request based sharing': true,
    'File storage': true,
  },
  Business: {
    'Integrated 2FA authentication': true,
    'Unlimited password storage': true,
    'Request based sharing': true,
    'File storage': true,
  },
  Enterprise: {
    'Integrated 2FA authentication': true,
    'Unlimited password storage': true,
    'Request based sharing': true,
    'File storage': true,
  },
};

const sellingPoints = computed(() => {
  return sellingPointData[stripePackage.value.name as keyof typeof sellingPointData] ?? {};
});

const isCurrrent = computed(() => currentSubscription.value === stripePackage.value.id);

const isDisabled = computed(() => {
  if (currentSubscription.value !== null && currentSubscription.value === stripePackage.value.id) {
    return true;
  }

  if (
    maxUsers.value !== null &&
    maxUsers.value > stripePackage.value.max_users &&
    stripePackage.value.max_users !== 0
  ) {
    return true;
  }

  return false;
});
</script>
