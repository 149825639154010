import AccessRequestList from '@/views/accessRequests/AccessRequestList.vue';

export default [
  {
    path: '/access-requests',
    name: 'access-requests',
    redirect: { name: 'access-requests.list' },
    meta: {
      template: 'app',
      ability: {
        action: 'read',
        subject: 'access requests',
      },
    },
    children: [
      {
        path: '',
        name: 'access-requests.list',
        component: AccessRequestList,
      },
    ],
  },
];
