<template>
  <AppContainer title="Add User">
    <q-page-container>
      <q-page class="q-pa-md">
        <div class="row justify-center">
          <q-form class="q-layout col-12 col-lg-6 q-pa-md" @submit="save">
            <div class="row q-mb-sm">
              <Field name="first_name" v-slot="{ errorMessage, value, field }">
                <q-input
                  class="q-px-sm col"
                  label="First Name"
                  :model-value="value"
                  v-bind="field"
                  :error-message="errorMessage"
                  :error="!!errorMessage"
                />
              </Field>
            </div>

            <div class="row q-mb-sm">
              <Field name="last_name" v-slot="{ errorMessage, value, field }">
                <q-input
                  class="q-px-sm col"
                  label="Last Name"
                  :model-value="value"
                  v-bind="field"
                  :error-message="errorMessage"
                  :error="!!errorMessage"
                />
              </Field>
            </div>

            <div class="row q-mb-sm">
              <Field name="email" v-slot="{ errorMessage, value, field }">
                <q-input
                  class="q-px-sm col"
                  label="Email"
                  :model-value="value"
                  v-bind="field"
                  :error-message="errorMessage"
                  :error="!!errorMessage"
                />
              </Field>
            </div>

            <div class="row q-mb-sm">
              <Field name="timezone" v-slot="{ errorMessage, value, field }">
                <TimezoneSelect
                  class="q-px-sm col"
                  label="Timezone"
                  :model-value="value"
                  v-bind="field"
                  :error-message="errorMessage"
                  :error="!!errorMessage"
                />
              </Field>
            </div>

            <div class="row q-mb-sm">
              <Field name="date_of_birth" v-slot="{ errorMessage, value, field }">
                <DateSelect
                  class="q-px-sm col"
                  label="Date Of Birth"
                  :model-value="value"
                  v-bind="field"
                  :error-message="errorMessage"
                  :error="!!errorMessage"
                  max="today"
                />
              </Field>
            </div>

            <div class="row q-mb-sm">
              <Field name="role_id" v-slot="{ errorMessage, value, field }">
                <RoleSelect
                  class="q-px-sm col"
                  label="Role"
                  :model-value="value"
                  v-bind="field"
                  :error-message="errorMessage"
                  :error="!!errorMessage"
                />
              </Field>
            </div>

            <div class="row">
              <div class="col"></div>

              <div class="col-auto">
                <q-btn
                  class="text-right"
                  color="primary"
                  label="Save"
                  type="submit"
                  :loading="isLoading"
                />
              </div>
            </div>
          </q-form>
        </div>
      </q-page>
    </q-page-container>
  </AppContainer>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { Field, useForm } from 'vee-validate';
import * as yup from 'yup';
import { useUserStore, type AddUserForm } from '@/stores/user';
import { useAuthUserStore } from '@/stores/auth-user';
import { AxiosError } from 'axios';
import { useRouter } from 'vue-router';
import useTenantUserEncryption from '@/composable/tenant_user';
import useAddRecoveryKey from '@/composable/group/add-recovery-key';
import TimezoneSelect from '@/components/form/TimezoneSelect.vue';
import DateSelect from '@/components/form/DateSelect.vue';
import RoleSelect from '@/components/form/RoleSelect.vue';
import { useConfirmationDialog } from '@/composable/form/use-confirmation-dialog';

const isLoading = ref(false);

const router = useRouter();
const confirmationDialog = useConfirmationDialog();

const authUserStore = useAuthUserStore();
const authUser = authUserStore.currentUser;

const userStore = useUserStore();

const { createTenantUserKeys } = useTenantUserEncryption();

const { addRecoveryKey } = useAddRecoveryKey();

const schema = yup.object({
  first_name: yup.string().required().label('First name'),
  last_name: yup.string().required().label('Last name'),
  email: yup.string().required().email().label('Email'),
  timezone: yup.string().required().label('Timezone'),
  date_of_birth: yup.string().required().label('Date of Birth'),
  role_id: yup.string().required().label('Role'),
});

const initialValues = {};

const { handleSubmit, setErrors } = useForm<AddUserForm>({
  validationSchema: schema,
  initialValues: initialValues,
});

const save = handleSubmit(async (payload) => {
  try {
    isLoading.value = true;

    const tenantUserKeys = await createTenantUserKeys();

    const user = await userStore.addUser({
      payload: {
        ...payload,
        ...{
          asymmetric_key_public: tenantUserKeys.asymmetric_key_public,
          asymmetric_key_private: tenantUserKeys.asymmetric_key_private,

          tenant_user_symmetric_key: tenantUserKeys.tenant_user_symmetric_key,
          tenant_user_symmetric_key_password: tenantUserKeys.tenant_user_symmetric_key_password,
        },
      },
    });

    if (authUser.has_recovery_group && tenantUserKeys.asymmetric_key_private_decrypted) {
      await addRecoveryKey(user.id, tenantUserKeys.asymmetric_key_private_decrypted);
    }

    isLoading.value = false;

    router.push({ name: 'user.details', params: { id: user.id } });
  } catch (err) {
    isLoading.value = false;

    if (err instanceof AxiosError) {
      if (err.response?.status === 422) {
        setErrors(err.response?.data);
        return;
      }

      if (err.response?.status === 400) {
        if (
          !(await confirmationDialog.confirm({
            title: 'Upgrade Required to Add More Users',
            message:
              'You have reached the maximum number of users for your current plan. Please upgrade your plan to add more users.',
            options: {
              okLabel: 'Upgrade Plan',
              cancelLabel: 'Cancel',
            },
          }))
        ) {
          return;
        }

        router.push({ name: 'tenant.subscription' });
      }

      throw err;
    }
  }
});
</script>
