<template>
  <q-select
    v-model="selected"
    :options="roles"
    :label="label"
    option-value="id"
    option-label="name"
    map-options
    emit-value
    :error-message="errorMessage"
    :error="error"
  />
</template>

<script lang="ts" setup>
import { computed, toRefs } from 'vue';
import { useRolesStore, type Role } from '@/stores/role';
import { storeToRefs } from 'pinia';

const props = defineProps<{
  modelValue?: Role | null;
  label?: string;
  errorMessage?: string;
  error?: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: string | null): void;
}>();

const { modelValue } = toRefs(props);

const rolesStore = useRolesStore();
const { roles } = storeToRefs(rolesStore);

rolesStore.getRoles({});

const selected = computed({
  get: () => modelValue?.value as string | null,
  set: (value: string | null) => {
    if (!value) {
      emit('update:modelValue', null);
      return;
    }

    emit('update:modelValue', value);
  },
});
</script>
