<template>
  <AppContainer title="Add Secret">
    <template #buttons>
      <HelpPopup page="secrets.add" />
    </template>

    <q-page-container>
      <q-page class="q-pa-md">
        <div class="row justify-center">
          <q-form class="q-layout col-12 col-lg-6 q-pa-md secret-form" @submit="save">
            <div class="row q-mb-sm">
              <Field name="name" v-slot="{ errorMessage, value, field }">
                <q-input
                  class="q-px-sm col"
                  label="Name"
                  :model-value="value"
                  v-bind="field"
                  :error-message="errorMessage"
                  :error="!!errorMessage"
                />
              </Field>
            </div>

            <div class="row q-mb-sm">
              <Field name="username" v-slot="{ errorMessage, value, field }">
                <q-input
                  class="q-px-sm col"
                  label="Username"
                  :model-value="value"
                  v-bind="field"
                  :error-message="errorMessage"
                  :error="!!errorMessage"
                />
              </Field>
            </div>

            <div class="row q-mb-sm">
              <Field name="secret" v-slot="{ errorMessage, value, field }">
                <q-input
                  class="q-px-sm col"
                  label="Secret"
                  :type="showPassword ? 'text' : 'password'"
                  :model-value="value"
                  v-bind="field"
                  :error-message="errorMessage"
                  :error="!!errorMessage"
                >
                  <template v-slot:append>
                    <q-icon
                      :name="showPassword ? 'visibility' : 'visibility_off'"
                      @click="showPassword = !showPassword"
                      class="q-mx-sm"
                    />

                    <q-icon name="content_copy" @click="copyPassword" class="q-mx-sm" />

                    <q-icon
                      name="shuffle"
                      @click="
                        () => {
                          generateDialogOpen = true;
                          showPassword = true;
                        }
                      "
                      class="q-mx-sm"
                    />
                  </template>
                </q-input>
              </Field>
            </div>

            <PasswordStrength
              :password="values.secret"
              @update="updatePasswordScore"
              class="q-mx-sm q-mb-md"
            />

            <div class="row q-mb-sm">
              <Field name="url" v-slot="{ errorMessage, value, field }">
                <q-input
                  class="q-px-sm col"
                  label="URL"
                  :model-value="value"
                  v-bind="field"
                  :error-message="errorMessage"
                  :error="!!errorMessage"
                />
              </Field>
            </div>

            <div class="row q-mb-sm">
              <Field name="note" v-slot="{ errorMessage, value, field }">
                <q-input
                  class="q-px-sm col"
                  label="Notes"
                  type="textarea"
                  :model-value="value"
                  v-bind="field"
                  :error-message="errorMessage"
                  :error="!!errorMessage"
                />
              </Field>
            </div>

            <div class="row q-mb-sm">
              <Field name="otp_secret" v-slot="{ errorMessage, value, field }">
                <q-input
                  class="q-px-sm col-6"
                  label="OTP Secret"
                  :model-value="value"
                  v-bind="field"
                  :error-message="errorMessage"
                  :error="!!errorMessage"
                />
              </Field>
            </div>

            <div class="row q-mb-sm">
              <Field name="hidden" v-slot="{ errorMessage, value, field }">
                <q-checkbox
                  class="q-px-sm col"
                  label="Hide password from users who do not have access."
                  :model-value="value"
                  v-bind="field"
                  :error-message="errorMessage"
                  :error="!!errorMessage"
                />
              </Field>
            </div>

            <div class="row">
              <div class="col"></div>

              <div class="col-auto">
                <q-btn
                  class="text-right"
                  color="primary"
                  label="Save"
                  type="submit"
                  :loading="buttonLoading"
                />
              </div>
            </div>
          </q-form>
        </div>
      </q-page>
    </q-page-container>
  </AppContainer>

  <GenerateDialog v-model:is-open="generateDialogOpen" v-model="values.secret" />
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { Field, useForm } from 'vee-validate';
import * as yup from 'yup';
import { useSecretStore, type AddSecretForm } from '@/stores/secret';
import { AxiosError } from 'axios';
import { useToast } from 'vue-toast-notification';
import useSecretEncryption from '@/composable/secret';
import { useRouter } from 'vue-router';
import PasswordStrength from '@/components/form/PasswordStrength.vue';
import type zxcvbn from 'zxcvbn';
import GenerateDialog from '@/components/secrets/GenerateDialog.vue';

const showPassword = ref(false);
const buttonLoading = ref(false);
const generateDialogOpen = ref(false);

const toast = useToast();
const router = useRouter();

const { createSecret } = useSecretEncryption();

const secretStore = useSecretStore();

const schema = yup.object({
  name: yup.string().required().label('Name'),
  username: yup.string().label('Username'),
  secret: yup.string().label('Password'),
  url: yup.string().url().label('URL'),
  note: yup.string().label('Notes'),
  otp_secret: yup.string().label('OTP Secret'),
  hidden: yup.boolean().label('Hidden'),
  score: yup.number().nullable(),
});

const initialValues = {
  hidden: false,
};

const { handleSubmit, setErrors, values, setFieldValue } = useForm<AddSecretForm>({
  validationSchema: schema,
  initialValues: initialValues,
});

const save = handleSubmit(async (payload) => {
  try {
    buttonLoading.value = true;

    const encryptedSecret = await createSecret(payload);
    const secret = await secretStore.addSecret({ payload: encryptedSecret });

    buttonLoading.value = false;

    toast.success('Secret created successfully.');

    router.push({ name: 'secret.details', params: { id: secret.id } });
  } catch (err) {
    buttonLoading.value = false;
    if (err instanceof AxiosError) {
      setErrors(err.response?.data);
      return;
    }

    throw err;
  }
});

const copyPassword = () => {
  if (!values.secret) {
    return;
  }

  navigator.clipboard.writeText(values.secret);
  toast.success('Copied password to clipboard');
};

const updatePasswordScore = (score: zxcvbn.ZXCVBNResult) => {
  setFieldValue('score', score.score.valueOf());
};
</script>
