import AccountShow from '@/views/account/AccountShow.vue';
import AccountDetails from '@/views/account/AccountDetails.vue';
import AccountSecurity from '@/views/account/AccountSecurity.vue';

export default [
  {
    name: 'account',
    path: '/account',
    component: AccountShow,
    redirect: { name: 'account.details' },
    children: [
      {
        name: 'account.details',
        path: 'details',
        component: AccountDetails,
      },
      {
        name: 'account.security',
        path: 'security',
        component: AccountSecurity,
      },
    ],
  },
];
