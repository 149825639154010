<template>
  <RouterView v-if="tenant?.id" v-slot="{ Component }: any">
    <component :is="Component">
      <template #tabs>
        <q-tabs align="left">
          <q-route-tab :to="{ name: 'tenant.details' }" label="Details" />
          <q-route-tab :to="{ name: 'tenant.import' }" label="Import" />
          <q-route-tab :to="{ name: 'tenant.subscription' }" label="Subscription" />
        </q-tabs>
      </template>
    </component>
  </RouterView>
</template>

<script lang="ts" setup>
import { useTenantStore } from '@/stores/tenant';
import { storeToRefs } from 'pinia';

const tenantStore = useTenantStore();
const { tenant } = storeToRefs(tenantStore);

await tenantStore.getTenant();

if (!tenant.value) {
  throw new Error('Tenant is undefined.');
}
</script>
