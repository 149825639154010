<template>
  <AppContainer :title="title">
    <template #buttons>
      <HelpPopup page="secrets.files" />
    </template>

    <template #tabs>
      <slot name="tabs" />
    </template>

    <q-page-container>
      <q-page class="q-pa-md">
        <div class="row justify-center">
          <q-list class="q-layout q-pa-md">
            <div class="row">
              <div class="col">
                <SecretFileAddFile />
              </div>
            </div>
            <div class="row">
              <div class="col">
                <SecretFilePath />
              </div>
              <div class="col-auto">
                <q-btn
                  type="button"
                  color="primary"
                  icon="add"
                  label="Add Folder"
                  @click="isCreateFolderOpen = true"
                />
              </div>
            </div>

            <q-infinite-scroll @load="onLoad" :offset="250" ref="infiniteScroll">
              <template v-slot:loading>
                <div class="row justify-center q-my-md">
                  <q-spinner-dots color="primary" size="40px" />
                </div>
              </template>

              <div v-for="file in secretFiles" :key="file.id">
                <ListItem :item="file" />
              </div>
            </q-infinite-scroll>
          </q-list>
        </div>
      </q-page>
    </q-page-container>

    <SecretFileAddDirectoryDialog
      v-model:is-open="isCreateFolderOpen"
      @refresh="() => refreshList({ index: 1, reset: true })"
    />
  </AppContainer>
</template>

<script lang="ts" setup>
import { ref, watch, computed, onMounted, onBeforeUnmount } from 'vue';
import { useSecretStore } from '@/stores/secret';
import { useSecretFileStore, type SecretFileListFilters } from '@/stores/secret/files';
import type { QInfiniteScroll } from 'quasar';
import { storeToRefs } from 'pinia';
import ListItem from '@/components/secrets/files/ListItem.vue';
import SecretFileAddDirectoryDialog from '@/components/secrets/files/SecretFileAddDirectoryDialog.vue';
import SecretFilePath from '@/components/secrets/files/SecretFilePath.vue';
import SecretFileAddFile from '@/components/secrets/files/SecretFileAddFile.vue';

const infiniteScroll = ref<QInfiniteScroll | null>(null);

const isCreateFolderOpen = ref(false);
const filters = ref<SecretFileListFilters>({});

const secretStore = useSecretStore();
const { secret } = storeToRefs(secretStore);

const secretFileStore = useSecretFileStore();
const { secretFiles, secretFilePath, currentParentID } = storeToRefs(secretFileStore);

const title = computed(() => `${secret.value?.name} - Files`);

watch(
  [filters, currentParentID],
  async () => {
    if (!infiniteScroll.value) {
      return;
    }

    const response = await secretFileStore.getSecretFiles({
      secretID: secret.value!.id,
      page: 1,
      filters: filters.value,
      reset: true,
    });

    if (currentParentID.value) {
      currentParentID.value = secretFilePath.value[secretFilePath.value.length - 1].id;
    }

    infiniteScroll.value.setIndex(1);
    if (response.length < 50) {
      infiniteScroll.value.stop();
    }
  },
  { deep: true }
);

async function refreshList({
  index,
  done,
  reset = false,
}: {
  index: number;
  done?: (stop: boolean) => void;
  reset?: boolean;
}) {
  if (!infiniteScroll.value) {
    return;
  }

  const response = await secretFileStore.getSecretFiles({
    secretID: secret.value!.id,
    page: index,
    filters: filters.value,
    reset,
  });

  if (currentParentID.value) {
    currentParentID.value = secretFilePath.value[secretFilePath.value.length - 1].id;
  }

  if (done) {
    done(response.length < 50);
    return;
  }

  infiniteScroll.value.setIndex(1);
  if (response.length < 50) {
    infiniteScroll.value.stop();
  }
}

async function onLoad(index: number, done: (stop: boolean) => void) {
  refreshList({ index, done });
}

onMounted(async () => {
  if (!infiniteScroll.value) {
    return;
  }

  infiniteScroll.value.trigger();
});

onBeforeUnmount(() => {
  if (infiniteScroll.value) {
    infiniteScroll.value.reset();
  }

  secretFileStore.resetSecretFiles();
});
</script>
