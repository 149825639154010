<template>
  <RouterView v-if="secret?.id" v-slot="{ Component }: any">
    <component :is="Component">
      <template #tabs>
        <q-tabs align="left">
          <q-route-tab :to="{ name: 'secret.details' }" label="Details" />

          <template v-if="secret.has_access">
            <q-route-tab
              v-if="authUser?.current_subscription?.package_name !== 'Personal'"
              :to="{ name: 'secret.files' }"
              label="Files"
            />

            <q-route-tab :to="{ name: 'secret.access' }" label="Access" />

            <q-route-tab :to="{ name: 'secret.logs' }" label="Logs" />
          </template>
        </q-tabs>
      </template>
    </component>
  </RouterView>
</template>

<script lang="ts" setup>
import { useAuthUserStore } from '@/stores/auth-user';
import { useSecretStore } from '@/stores/secret';
import { storeToRefs } from 'pinia';
import { onBeforeUnmount } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const authUserStore = useAuthUserStore();
const { authUser } = storeToRefs(authUserStore);

const secretStore = useSecretStore();
const { secret } = storeToRefs(secretStore);

await secretStore.getSecret({
  id: route.params.id as string,
});

if (secret?.value === undefined) {
  throw new Error('Secret is undefined.');
}

onBeforeUnmount(() => {
  secretStore.resetSecret();
});
</script>
