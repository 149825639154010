import { rsaEncrypt } from '@/utils/encryption/asymmetric';
import { stringToBytes } from '@/utils/encryption/utils';

export default async function rsaEncryptText(
  key: CryptoKey,
  field: string | undefined
): Promise<string | undefined> {
  if (!field) {
    return undefined;
  }

  const bytes = stringToBytes(field as string);

  return await rsaEncrypt(key, bytes);
}
