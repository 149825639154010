<template>
  <AppContainer title="Secrets">
    <template #buttons>
      <HelpPopup page="secrets.list" />

      <q-btn :to="{ name: 'secrets.add' }" type="button" color="primary" icon="add" round dense />

      <q-btn
        type="button"
        color="primary"
        icon="search"
        @click="() => (isFiltersOpen = !isFiltersOpen)"
        round
        dense
      />
    </template>

    <template #drawerRight>
      <FilterDrawer v-model:is-open="isFiltersOpen" v-model:filters="filters" />
    </template>

    <q-page-container>
      <q-page class="q-pa-md">
        <div class="row justify-center">
          <q-list class="q-layout q-pa-md">
            <q-infinite-scroll @load="onLoad" :offset="250" ref="infiniteScroll">
              <template v-slot:loading>
                <div class="row justify-center q-my-md">
                  <q-spinner-dots color="primary" size="40px" />
                </div>
              </template>

              <div v-for="secret in secrets" :key="secret.id">
                <ListItem :secret="secret" />
              </div>

              <q-item v-if="secrets.length < 1" class="text-center">
                <q-item-section>
                  <q-item-label>No secrets available.</q-item-label>
                </q-item-section>
              </q-item>
            </q-infinite-scroll>
          </q-list>
        </div>
      </q-page>
    </q-page-container>
  </AppContainer>
</template>

<script lang="ts" setup>
import { ref, watch, onMounted, onBeforeUnmount } from 'vue';
import { useSecretStore, type SecretListFilters } from '@/stores/secret';
import type { QInfiniteScroll } from 'quasar';
import { storeToRefs } from 'pinia';
import ListItem from '@/components/secrets/ListItem.vue';
import FilterDrawer from '@/components/secrets/FilterDrawer.vue';

const infiniteScroll = ref<QInfiniteScroll | null>(null);

const isFiltersOpen = ref(false);
const filters = ref<SecretListFilters>({});

const secretStore = useSecretStore();
const { secrets } = storeToRefs(secretStore);

watch(
  filters,
  async () => {
    if (!infiniteScroll.value) {
      return;
    }

    const response = await secretStore.getSecrets({ page: 1, filters: filters.value, reset: true });
    infiniteScroll.value.setIndex(1);
    if (response.length < 50) {
      infiniteScroll.value.stop();
    }
  },
  { deep: true }
);

const onLoad = async (index: number, done: (stop: boolean) => void) => {
  const response = await secretStore.getSecrets({ page: index, filters: filters.value });
  done(response.length < 50);
};

onMounted(() => {
  if (!infiniteScroll.value) {
    return;
  }

  infiniteScroll.value.trigger();
});

onBeforeUnmount(() => {
  if (infiniteScroll.value) {
    infiniteScroll.value.reset();
  }

  secretStore.resetSecrets();
});
</script>
