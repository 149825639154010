<template>
  <q-item
    class="q-my-sm"
    clickable
    v-ripple
    :to="{ name: 'secret.details', params: { id: secret.id } }"
  >
    <q-item-section avatar>
      <q-avatar :text-color="lockColour">
        <q-icon v-if="secret.has_access" class="fa fa-lock-open" />
        <q-icon v-else class="fa fa-lock" />
      </q-avatar>
    </q-item-section>

    <q-item-section>
      <q-item-label>
        {{ secret.name }}

        <q-badge v-if="secret.can_grant_access" color="positive" style="margin-left: 0.5rem">
          Grant access
        </q-badge>
      </q-item-label>
    </q-item-section>

    <q-item-section side>
      {{ createdAt }}
    </q-item-section>
  </q-item>
</template>

<script lang="ts" setup>
import { computed, toRefs } from 'vue';
import type { Secret } from '@/stores/secret';
import formatDatetime from '@/composable/format-datetime';

const props = defineProps<{
  secret: Secret;
}>();

const { secret } = toRefs(props);

const createdAt = formatDatetime(secret.value.created_at);

const lockColour = computed(() => {
  if (secret.value.has_access) {
    return 'positive';
  }

  if (secret.value.has_requested_access) {
    return 'warning';
  }

  return 'negative';
});
</script>
