<template>
  <div class="q-pa-md q-gutter-sm">
    <q-file
      v-model="fakeVModel"
      filled
      use-chips
      multiple
      label="Drag and drop files here or click to select"
      hint="File size should not exceed 20MB"
      max-file-size="20971520"
      @update:model-value="updateFiles"
    >
      <template v-slot:prepend>
        <q-icon name="cloud_upload" class="cursor-pointer" />
      </template>
    </q-file>
  </div>

  <q-list class="q-pa-md">
    <ProcessingListItem
      v-for="processing in uploadQueueProcessing"
      :key="processing.id"
      :item="processing"
      @cancel="cancelsQueuedFile"
      @remove="removesQueuedFile"
    />
  </q-list>
</template>

<script lang="ts" setup>
import rsaEncryptText from '@/composable/secret/rsa-encrypt-text';
import { useSecretStore } from '@/stores/secret';
import {
  mapFileToUploadingFile,
  useSecretFileStore,
  type UploadingFile,
} from '@/stores/secret/files';
import { aesEncryptFile } from '@/utils/encryption/symmetric';
import http from '@/utils/http';
import { isAxiosError, isCancel, type AxiosProgressEvent } from 'axios';
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import ProcessingListItem from './ProcessingListItem.vue';

const fakeVModel = computed({
  get: () => {
    return null;
  },
  set: () => {},
});

const secretStore = useSecretStore();
const { secret, secretPublicKey, secretSymmetricKey } = storeToRefs(secretStore);

const secretFileStore = useSecretFileStore();
const { secretFiles, currentParentID } = storeToRefs(secretFileStore);

const uploadQueue = ref<UploadingFile[]>([]);
const concurrent = 3;

const uploadQueueProcessing = computed(() => uploadQueue.value.filter(({ finished }) => !finished));
const uploadQueueUploading = computed(() => uploadQueue.value.filter(({ uploading }) => uploading));

function updateFiles(newFiles: File[]) {
  if (!newFiles) {
    return;
  }

  // Handle the files. For example, upload to a server or process them
  uploadQueue.value.unshift(...newFiles.map(mapFileToUploadingFile));
}

watch(
  uploadQueue,
  () => {
    uploadQueue.value.forEach(async (file: UploadingFile) => {
      if (
        file.finished ||
        file.uploading ||
        file.encrypting ||
        file.failed ||
        uploadQueueUploading.value.length === concurrent
      ) {
        return;
      }

      if (file.file.size > 20971520) {
        return updatesQueuedFile(file.id, {
          failed: true,
          error: 'The file must be smaller than 20MB.',
        });
      }

      const abortController = new AbortController();

      try {
        updatesQueuedFile(file.id, {
          encrypting: true,
          abortController,
        });

        const data = await encryptFile(file);
        if (!data) {
          return;
        }

        updatesQueuedFile(file.id, {
          uploading: true,
          abortController,
        });

        const response = await http.post(`/secrets/${secret.value!.id}/files/file`, data, {
          signal: abortController.signal,
          onUploadProgress: (event: AxiosProgressEvent) => {
            if (event.total === undefined) {
              return;
            }

            updatesQueuedFile(file.id, {
              progress: Math.round((event.loaded * 100) / event.total),
            });
          },
          headers: { 'Content-Type': undefined },
        });

        removesQueuedFile(file.id);

        secretFiles.value.unshift({
          ...response.data,
          nameDecrypted: file.file.name,
        });
      } catch (error) {
        const updates: Partial<UploadingFile> = { uploading: false, failed: true };

        if (isCancel(error)) {
          updates.error = 'Upload cancelled.';
        } else if (isAxiosError(error) && error.response) {
          if (error.response.status === 422) {
            updates.error = error.response.data.errors.file[0];
          } else {
            updates.error = error.response.data.message;
          }
        } else {
          updates.error = (error as Error).message;
        }

        updatesQueuedFile(file.id, updates);
      }
    });
  },
  { deep: true }
);

async function encryptFile(file: UploadingFile): Promise<FormData | null> {
  if (!secretPublicKey.value || !secretSymmetricKey.value) {
    await secretStore.getSecretKey(secret.value!.id);
  }

  if (!secretPublicKey.value || !secretSymmetricKey.value) {
    updatesQueuedFile(file.id, {
      failed: true,
      error: 'Failed to get secret key.',
    });

    return null;
  }

  const [nameCiphertext, fileCiphertext] = await Promise.all([
    rsaEncryptText(secretPublicKey.value, file.file.name),
    aesEncryptFile(secretSymmetricKey.value, file.file),
  ]);

  updatesQueuedFile(file.id, {
    nameCiphertext: nameCiphertext,
    fileContentsCiphertext: fileCiphertext,
  });

  const blob = new Blob([fileCiphertext], { type: 'application/octet-stream' });

  const formData = new FormData();
  formData.append('file', blob, nameCiphertext); // You can name the file as needed

  const form: Record<string, unknown> = {
    id: file.id,
    parent_id: currentParentID.value,
    name: nameCiphertext,
  };

  formData.append('form', JSON.stringify(form));

  return formData;
}

function updatesQueuedFile(uuid: string, updates: Partial<UploadingFile>) {
  const index = uploadQueue.value.findIndex((file) => file.id === uuid);

  if (index === -1) {
    return;
  }

  uploadQueue.value.splice(index, 1, { ...uploadQueue.value[index], ...updates });
}

function removesQueuedFile(uuid: string) {
  const index = uploadQueue.value.findIndex((file) => file.id === uuid);

  if (index === -1) {
    return;
  }

  uploadQueue.value.splice(index, 1);
}

function cancelsQueuedFile(uuid: string) {
  const index = uploadQueue.value.findIndex((file) => file.id === uuid);

  if (index === -1) {
    return;
  }

  const file = uploadQueue.value[index];

  if (file.abortController) {
    file.abortController.abort();
  }
}
</script>
