import { defineStore } from 'pinia';
import http from '@/utils/http';
import type { CreateGroupPayload } from '@/stores/group';

export interface SecretAccessState {
  secretAccess: SecretAccess[];
  secretAccessOptions: (SecretAccessOption | SecretAccessOptionGroup)[];
}

export interface SecretAccess {
  id: string;
  name: string;
  can_grant_access: boolean;
  type: string;
  created_at: string;
}

export interface SecretAccessOption {
  id: string | null;
  name: string;
  type: string;
}

export interface SecretAccessOptionGroup {
  group: string;
  name?: string;
  disable: boolean;
}

export interface SecretAccessListFilters {
  search?: string;
  created_at?: { from: string; to: string };
}

export const useSecretAccessStore = defineStore('secretAccess', {
  state: (): SecretAccessState => ({
    secretAccess: [],
    secretAccessOptions: [],
  }),

  getters: {},

  actions: {
    async getSecretAccess({
      secretID,
      page,
      filters,
      reset = false,
    }: {
      secretID: string;
      page: number;
      filters?: SecretAccessListFilters;
      reset: boolean;
    }) {
      const response = await http.get(`/secrets/${secretID}/access`, {
        params: { page, ...filters },
      });

      if (reset) {
        this.secretAccess = [];
      }

      this.secretAccess.push(...(response.data ?? []));

      return this.secretAccess;
    },

    resetSecretAccess() {
      this.secretAccess = [];
    },

    async getSecretAccessOptions(secretID: string) {
      const response = await http.get(`/secrets/${secretID}/access-options`);

      this.secretAccessOptions = [];

      const availableTypes = ['Users', 'Groups', 'Tenants'];

      availableTypes.forEach((type: string) => {
        const typeValues = response.data.filter(
          (option: SecretAccessOption) => option.type === type
        );

        if (typeValues.length === 0) {
          return;
        }

        this.secretAccessOptions.push({
          group: type,
          disable: true,
        });

        this.secretAccessOptions.push(...typeValues);
      });

      return this.secretAccessOptions;
    },

    resetSecretAccessOptions() {
      this.secretAccessOptions = [];
    },

    async addUserAccess(secretID: string, payload: AddUserAccessPayload) {
      await http.post(`/secrets/${secretID}/add/user`, payload);
    },

    async addGroupAccess(secretID: string, payload: AddGroupAccessPayload) {
      await http.post(`/secrets/${secretID}/add/group`, payload);
    },

    async addNewGroupAccess(secretID: string, payload: AddNewGroupAccessPayload) {
      const response = await http.post(`/secrets/${secretID}/add/group/new`, payload);
      return response.data?.id;
    },

    async addTenantAccess(secretID: string, payload: AddTenantAccessPayload) {
      await http.post(`/secrets/${secretID}/add/tenant`, payload);
    },

    async revokeAccess(secretID: string, payload: RevokeAccessPayload) {
      await http.post(`/secrets/${secretID}/revoke`, payload);
    },
  },
});

export type AddUserAccessPayload = {
  user_id: string;
  symmetric_key: string;
  asymmetric_key_private: string;
  asymmetric_key_public: string;
  can_grant_access: boolean;
};

export type AddGroupAccessPayload = {
  group_id: string;
  symmetric_key: string;
  asymmetric_key_private: string;
  asymmetric_key_public: string;
  can_grant_access: boolean;
  access_request_user_id?: string;
};

export type AddNewGroupAccessPayload = {
  group: CreateGroupPayload;
  group_secret: {
    symmetric_key: string;
    asymmetric_key_private: string;
    asymmetric_key_public: string;
    can_grant_access: boolean;
    access_request_user_id?: string;
  };
};

export type AddTenantAccessPayload = {
  tenant_id: string;
  symmetric_key: string;
  asymmetric_key_private: string;
  asymmetric_key_public: string;
  can_grant_access: boolean;
  access_request_user_id?: string;
};

export type RevokeAccessPayload = {
  type: string;
  id: string;
};
