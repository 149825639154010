<template>
  <q-item class="q-my-sm" clickable v-ripple @click="handleClick">
    <q-item-section avatar>
      <q-avatar text-color="primary">
        <q-icon v-if="item.type === 'file'" class="fa-solid fa-file" />
        <q-icon v-else class="fa-solid fa-folder" />
      </q-avatar>
    </q-item-section>

    <q-item-section>
      <q-item-label>
        {{ item.nameDecrypted ?? 'Unable to decrypt name' }}
      </q-item-label>
    </q-item-section>

    <q-item-section side>
      {{ createdAt }}
    </q-item-section>

    <q-item-section side>
      <q-btn-dropdown
        class="dropdown-ellipsis"
        dropdown-icon="fa-solid fa-ellipsis-vertical"
        @click.stop
      >
        <q-list>
          <q-item clickable v-close-popup @click.stop="deleteItem">
            <q-item-section>
              <q-item-label color="negative">
                <q-icon name="fa-solid fa-trash" class="q-mr-sm" />
                Delete
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-btn-dropdown>
    </q-item-section>
  </q-item>
</template>

<script lang="ts" setup>
import { toRefs } from 'vue';
import { useSecretFileStore, type SecretFile } from '@/stores/secret/files';
import { storeToRefs } from 'pinia';
import { useSecretStore } from '@/stores/secret';
import decryptDownloadFile from '@/composable/secret/decrypt-download-file';
import { useToast } from 'vue-toast-notification';
import { isAxiosError } from 'axios';
import formatDatetime from '@/composable/format-datetime';

const props = defineProps<{
  item: SecretFile;
}>();

const { item } = toRefs(props);

const toast = useToast();

const secretStore = useSecretStore();
const { secret } = storeToRefs(secretStore);

const secretFileStore = useSecretFileStore();
const { currentParentID } = storeToRefs(secretFileStore);

const createdAt = formatDatetime(item.value.created_at);

function handleClick() {
  if (item.value.type === 'directory') {
    currentParentID.value = item.value.id;
    return;
  }

  decryptDownloadFile(item.value);
}

async function deleteItem() {
  try {
    await secretFileStore.deleteSecretFileDirectory(secret.value!.id, item.value.id);
    toast.success('Item deleted successfully.');
  } catch (err) {
    if (isAxiosError(err) && err.response?.status === 400) {
      toast.error('Cannot delete records with children. Please delete the children first.');
      return;
    }

    throw err;
  }
}
</script>
