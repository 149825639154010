import createSecret from './create-secret';
import decryptSecret from './decrypt-secret';
import encryptSecret from './encrypt-secret';

export default function useSecretEncryption() {
  return {
    createSecret,
    decryptSecret,
    encryptSecret,
  };
}
