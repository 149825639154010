<template>
  <q-item class="q-my-sm" clickable v-ripple>
    <q-item-section avatar>
      <q-spinner
        v-if="(item.uploading || item.encrypting) && !item.failed"
        color="primary"
        size="1.5em"
      />
      <q-icon v-else name="fa-solid fa-circle-exclamation" color="negative" />
    </q-item-section>

    <q-item-section class="col-auto">
      <q-item-label>
        {{ item.file.name }}
      </q-item-label>
    </q-item-section>

    <q-item-section>
      <template v-if="item.failed">
        <span class="text-negative">
          {{ item.error }}
        </span>
      </template>
      <template v-else>
        <q-linear-progress :value="item.progress" color="positive" class="q-mt-sm" />
      </template>
    </q-item-section>

    <q-item-section side>
      <q-btn
        v-if="!item.error"
        flat
        dense
        color="negative"
        icon="fa-solid fa-xmark"
        @click="() => emit('cancel', item.id)"
      />
      <q-btn
        v-else
        flat
        dense
        color="negative"
        icon="fa-solid fa-trash"
        @click="() => emit('remove', item.id)"
      />
    </q-item-section>
  </q-item>
</template>

<script lang="ts" setup>
import type { UploadingFile } from '@/stores/secret/files';
import { toRefs } from 'vue';

const props = defineProps<{
  item: UploadingFile;
}>();

const emit = defineEmits<{
  (e: 'cancel', value: string): void;
  (e: 'remove', value: string): void;
}>();

const { item } = toRefs(props);
</script>
