<template>
  <q-layout view="hHh lpR fFf">
    <q-page-container class="auth">
      <q-page class="q-layout-padding flex justify-center items-center">
        <q-form class="card card-auth q-pa-lg rounded" @submit="submit">
          <div class="q-gutter-sm">
            <div class="text-center q-mx-none heading q-mt-md q-mb-lg">
              <img src="@/assets/icons/icon.svg" class="logo-image" />
              <h2>strng.io</h2>
            </div>

            <Field name="tenant_name" v-slot="{ errorMessage, value, field }">
              <q-input
                label="Organisation Name"
                :model-value="value"
                v-bind="field"
                :error-message="errorMessage"
                :error="!!errorMessage"
              />
            </Field>

            <Field name="email" v-slot="{ errorMessage, value, field }">
              <q-input
                label="Email"
                :model-value="value"
                v-bind="field"
                :error-message="errorMessage"
                :error="!!errorMessage"
              />
            </Field>

            <div class="row">
              <div class="col q-pr-md">
                <Field name="first_name" v-slot="{ errorMessage, value, field }">
                  <q-input
                    label="First Name"
                    :model-value="value"
                    v-bind="field"
                    :error-message="errorMessage"
                    :error="!!errorMessage"
                  />
                </Field>
              </div>

              <div class="col q-pl-md">
                <Field name="last_name" v-slot="{ errorMessage, value, field }" class="col">
                  <q-input
                    label="Last Name"
                    :model-value="value"
                    v-bind="field"
                    :error-message="errorMessage"
                    :error="!!errorMessage"
                  />
                </Field>
              </div>
            </div>

            <div class="row">
              <div class="col q-pr-md">
                <Field name="timezone" v-slot="{ errorMessage, value, field }">
                  <TimezoneSelect
                    label="Timezone"
                    :model-value="value"
                    v-bind="field"
                    :error-message="errorMessage"
                    :error="!!errorMessage"
                  />
                </Field>
              </div>

              <div class="col q-pl-md">
                <Field name="date_of_birth" v-slot="{ errorMessage, value, field }">
                  <DateSelect
                    label="Date Of Birth"
                    :model-value="value"
                    v-bind="field"
                    :error-message="errorMessage"
                    :error="!!errorMessage"
                    max="today"
                  />
                </Field>
              </div>
            </div>

            <Field name="password" v-slot="{ errorMessage, value, field }">
              <q-input
                label="Password"
                type="password"
                :model-value="value"
                v-bind="field"
                :error-message="errorMessage"
                :error="!!errorMessage"
              />
            </Field>

            <PasswordStrength :password="values.password" />

            <Field name="confirm_password" v-slot="{ errorMessage, value, field }">
              <q-input
                label="Confirm Password"
                type="password"
                :model-value="value"
                v-bind="field"
                :error-message="errorMessage"
                :error="!!errorMessage"
                class="q-mt-sm"
              />
            </Field>

            <div class="row">
              <div class="col">
                <RouterLink :to="{ name: 'login' }" class="text-muted">
                  Already have an account?
                </RouterLink>
              </div>

              <div class="col-auto">
                <q-btn
                  class="text-right"
                  color="primary"
                  label="SIGN UP"
                  type="submit"
                  :loading="isLoading"
                />
              </div>
            </div>
          </div>
        </q-form>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script lang="ts" setup>
import { Field, useForm } from 'vee-validate';
import * as yup from 'yup';
import http from '@/utils/http';
import { useAuthUserStore } from '@/stores/auth-user';
import { useRouter } from 'vue-router';
import TimezoneSelect from '@/components/form/TimezoneSelect.vue';
import DateSelect from '@/components/form/DateSelect.vue';
import PasswordStrength from '@/components/form/PasswordStrength.vue';
import { AxiosError } from 'axios';
import {
  generateRsaKey,
  wrapCryptoKey,
  exportPublicKey,
  exportPrivateKey,
  rsaEncrypt,
} from '@/utils/encryption/asymmetric/index';
import { generateAesKey, aesEncrypt, exportAesKey } from '@/utils/encryption/symmetric/index';
import { stringToBytes } from '@/utils/encryption/utils';
import { ref } from 'vue';
import { generateSalt } from '@/composable/url-hash/generate-salt';
import rsaEncryptText from '@/composable/secret/rsa-encrypt-text';

const isLoading = ref(false);

const router = useRouter();

const store = useAuthUserStore();

type SignupForm = {
  tenant_name: string;
  email: string;
  first_name: string;
  last_name: string;
  password: string;
  confirm_password: string;
  timezone: string;
  date_of_birth: string;
};

const schema = yup.object({
  tenant_name: yup.string().required().label('Organisation Name'),
  email: yup.string().required().email().label('Email'),
  first_name: yup.string().required().label('First name'),
  last_name: yup.string().required().label('Last name'),
  password: yup.string().required().min(6).label('Password'),
  confirm_password: yup.string().required().min(6).label('Confirm password'),
  timezone: yup.string().required().label('Timezone'),
  date_of_birth: yup.string().required().label('Date of birth'),
});

const initialValues = {};

const { handleSubmit, setErrors, values } = useForm<SignupForm>({
  validationSchema: schema,
  initialValues: initialValues,
});

const generateTenantKeys = async (publicUserKey: CryptoKey) => {
  const tenantUserKeyPair = await generateRsaKey();
  const tenantUserSymmetricKey: CryptoKey = await generateAesKey();

  const tenantKeyPair = await generateRsaKey();
  const tenantSymmetricKey: CryptoKey = await generateAesKey();

  // Tenant Keys
  const tenantPrivateKeyString = await exportPrivateKey(tenantKeyPair.privateKey as CryptoKey);
  const tenantPrivateKeyBytes = stringToBytes(tenantPrivateKeyString);
  const encryptedTenantPrivateKey: string = await aesEncrypt(
    tenantSymmetricKey,
    tenantPrivateKeyBytes
  );

  const tenantSymmetricKeyExported = await exportAesKey(tenantSymmetricKey);

  const encryptedtenantAesKey = await rsaEncrypt(
    tenantUserKeyPair.publicKey as CryptoKey,
    tenantSymmetricKeyExported
  );

  const tenantPublicKeyString = await exportPublicKey(tenantKeyPair.publicKey as CryptoKey);

  // Tenant User Keys
  const usertenantPrivateKeyString = await exportPrivateKey(
    tenantUserKeyPair.privateKey as CryptoKey
  );
  const tenantUserPrivateKeyBytes = stringToBytes(usertenantPrivateKeyString);
  const encryptedTenantUserPrivateKey: string = await aesEncrypt(
    tenantUserSymmetricKey,
    tenantUserPrivateKeyBytes
  );

  const tenantUserSymmetricKeyExported = await exportAesKey(tenantUserSymmetricKey);

  const encryptedtenantUserAesKey = await rsaEncrypt(publicUserKey, tenantUserSymmetricKeyExported);

  const tenantUserPublicKeyString = await exportPublicKey(tenantUserKeyPair.publicKey as CryptoKey);

  const urlSalt = await rsaEncryptText(tenantKeyPair.publicKey as CryptoKey, generateSalt());

  return {
    tenant_symmetric_key: encryptedtenantAesKey,
    tenant_asymmetric_private_key: encryptedTenantPrivateKey,
    tenant_asymmetric_public_key: tenantPublicKeyString,
    tenant_user_symmetric_key: encryptedtenantUserAesKey,
    tenant_user_asymmetric_private_key: encryptedTenantUserPrivateKey,
    tenant_user_asymmetric_public_key: tenantUserPublicKeyString,
    tenant_url_salt: urlSalt,
  };
};

const submit = handleSubmit(async (values) => {
  try {
    isLoading.value = true;

    const userKey = await generateRsaKey();

    if (!userKey.publicKey) {
      throw new Error('Unable to generate RSA key');
    }

    const tenantKeys = await generateTenantKeys(userKey.publicKey);

    const encryptedKey = await wrapCryptoKey(userKey.privateKey as CryptoKey, values.password);

    const response = await http.post('/signup', {
      ...values,
      asymmetric_public_key: await exportPublicKey(userKey.publicKey as CryptoKey),
      asymmetric_private_key: encryptedKey,
      tenant_name: values.tenant_name,
      ...tenantKeys,
    });

    store.setUser(response.data);
    store.setPrivateKey(userKey.privateKey);

    isLoading.value = false;

    router.push({ name: 'verify.email' });
  } catch (err) {
    isLoading.value = false;

    if (err instanceof AxiosError && err.status === 422) {
      setErrors(err.response!.data);
      return;
    }

    throw err;
  }
});
</script>
