<template>
  <q-layout view="hHh lpR fFf">
    <q-page-container class="auth">
      <q-page class="q-layout-padding flex justify-center items-center">
        <q-form class="card card-auth q-pa-lg rounded" @submit="submit">
          <div class="q-gutter-md">
            <div class="text-center q-mx-none heading q-mt-md q-mb-lg">
              <img src="@/assets/icons/icon.svg" class="logo-image" />
              <h2>strng.io</h2>
            </div>

            <p class="text-center">
              Successfully recovered your account. Please enter a new password.
            </p>

            <Field name="password" v-slot="{ errorMessage, value, field }">
              <q-input
                label="Password"
                type="password"
                :model-value="value"
                v-bind="field"
                :error-message="errorMessage"
                :error="!!errorMessage"
              />
            </Field>

            <Field name="confirm_password" v-slot="{ errorMessage, value, field }">
              <q-input
                label="Confirm Password"
                type="password"
                :model-value="value"
                v-bind="field"
                :error-message="errorMessage"
                :error="!!errorMessage"
              />
            </Field>

            <div class="row">
              <div class="col"></div>

              <div class="col-auto">
                <q-btn class="text-right" color="primary" label="Unlock" type="submit" />
              </div>
            </div>
          </div>
        </q-form>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script lang="ts" setup>
import { Field, useForm } from 'vee-validate';
import * as yup from 'yup';
import { useAuthUserStore, type RecoverAccountForm } from '@/stores/auth-user';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toast-notification';
import { AxiosError } from 'axios';
import { onMounted, ref } from 'vue';
import http from '@/utils/http';
import { unwrapPrivateKey, wrapCryptoKey } from '@/utils/encryption/asymmetric';

type RecoveryKeys = {
  id: string;
  user_id: string;
  asymmetric_key_private_wrapped: string;
};

const recoveryKeys = ref<RecoveryKeys | null>(null);
const asymmetricKeyPrivate = ref<CryptoKey | null>(null);

const props = defineProps({
  decryption_key: String,
  user_id: String,
});

const router = useRouter();
const toast = useToast();

const authUserStore = useAuthUserStore();

const schema = yup.object({
  password: yup.string().required().min(6).label('Password'),
  confirm_password: yup.string().required().min(6).label('Password'),
});

const { handleSubmit, setErrors } = useForm<RecoverAccountForm>({
  validationSchema: schema,
  initialValues: {},
});

const submit = handleSubmit(async (values, actions) => {
  try {
    if (!recoveryKeys.value || !asymmetricKeyPrivate.value) {
      toast.error('Unable to recover your account, please request a new recovery link.');
      return;
    }

    const unwrappedPrivateKey = await wrapCryptoKey(asymmetricKeyPrivate.value, values.password);

    await authUserStore.recoverAccount({
      payload: {
        ...values,
        asymmetric_key_private: unwrappedPrivateKey,
      },
    });

    authUserStore.setPrivateKey(asymmetricKeyPrivate.value);

    toast.success('Successfully recovered your account.');
    router.push({ name: 'secrets' });
  } catch (err) {
    actions.resetForm();

    if (err instanceof AxiosError) {
      setErrors(err.response!.data);
      return;
    }

    throw err;
  }
});

onMounted(async () => {
  if (!props.decryption_key || !props.user_id) {
    toast.error('Unable to recover your account, please request a new recovery link.');
    router.push({ name: 'login' });
    return;
  }

  try {
    const response = await http.get(`/users/${props.user_id}/recover-account-keys`);
    recoveryKeys.value = response.data;

    asymmetricKeyPrivate.value = await unwrapPrivateKey(
      recoveryKeys.value!.asymmetric_key_private_wrapped,
      props.decryption_key as string
    );

    await authUserStore.authorize();
  } catch (err) {
    toast.error('Unable to recover your account, please request a new recovery link.');
    router.push({ name: 'login' });
  }
});
</script>
