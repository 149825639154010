<template>
  <q-card flat bordered class="q-my-sm">
    <q-card-section class="q-pa-md">
      <div class="text-h6">Active Sessions</div>
      <div class="text-h3">{{ activeSessions }}</div>
    </q-card-section>
  </q-card>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import http from '@/utils/http';

const activeSessions = ref(0);

http
  .get('/dashboard/session-count')
  .then((response) => {
    activeSessions.value = response.data;
  })
  .catch(() => null);
</script>
