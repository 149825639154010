import { bytesToArrayBuffer, stringToBytes } from '@/utils/encryption/utils';

export async function rsaDecrypt(privateKey: CryptoKey, ciphertext: string): Promise<ArrayBuffer> {
  const decoded: string = window.atob(ciphertext);
  const data: ArrayBuffer = bytesToArrayBuffer(stringToBytes(decoded));

  return await window.crypto.subtle.decrypt(
    {
      name: 'RSA-OAEP',
    },
    privateKey,
    data
  );
}
