import { defineStore } from 'pinia';
import http from '@/utils/http';

export interface TimezoneState {
  timezones: Timezone[];
}

export interface Timezone {
  id: string;
  name: string;
}

export const useTimezonesStore = defineStore('timezones', {
  state: (): TimezoneState => ({
    timezones: [],
  }),

  getters: {},

  actions: {
    async getTimezones({ reset = false }: { reset?: boolean }): Promise<Timezone[]> {
      if (reset) {
        this.timezones = [];
      }

      if (this.timezones.length > 0) {
        return this.timezones;
      }

      const response = await http.get('/timezones');

      this.timezones = response.data;

      return this.timezones;
    },
  },
});
