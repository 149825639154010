<template>
  <AppContainer :title="title">
    <template #buttons>
      <HelpPopup page="tenant.details" />
    </template>

    <template #tabs>
      <slot name="tabs" />
    </template>

    <q-page-container>
      <q-page class="q-pa-md">
        <div class="row justify-center">
          <div class="q-layout col-12 col-lg-6 q-pa-md">
            <TenantDetailsForm />

            <hr class="q-mt-lg" />

            <h5 class="q-my-md">Account Recovery</h5>

            <RecoveryGroupBanner
              v-if="!authUser?.has_recovery_group"
              override-show
              :inline-actions="false"
            />

            <p>
              Any user who has been added to the <b>Account Recovery</b> group, and who have the
              necessary <b>User Permissions</b> will be able to recover a users account when
              required. It is recommended to have at least two users in this group.
            </p>

            <hr class="q-mt-lg" />

            <TenantDanger />
          </div>
        </div>
      </q-page>
    </q-page-container>
  </AppContainer>
</template>

<script lang="ts" setup>
import { useTenantStore } from '@/stores/tenant';
import { useAuthUserStore } from '@/stores/auth-user';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import TenantDetailsForm from '@/components/tenant/TenantDetailsForm.vue';
import RecoveryGroupBanner from '@/components/recoveryGroups/RecoveryGroupBanner.vue';
import TenantDanger from '@/components/tenant/TenantDanger.vue';

const tenantStore = useTenantStore();
const { tenant } = storeToRefs(tenantStore);

const authUserStore = useAuthUserStore();
const { authUser } = storeToRefs(authUserStore);

const title = computed(() => `${tenant.value?.name} - Details`);
</script>
