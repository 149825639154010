<template>
  <q-dialog v-model="isOpen" position="top">
    <q-card class="add-access-dialog">
      <q-card-section class="row items-center q-pb-none">
        <div class="text-h6">Add Folder</div>
        <q-space />
        <q-btn icon="close" flat round dense v-close-popup />
      </q-card-section>

      <q-form @submit="save">
        <q-card-section>
          <div class="row">
            <Field name="name" v-slot="{ errorMessage, value, field }">
              <q-input
                class="q-px-sm col"
                label="Name"
                :model-value="value"
                v-bind="field"
                :error-message="errorMessage"
                :error="!!errorMessage"
              />
            </Field>
          </div>

          <div class="row q-mt-md">
            <div class="col"></div>

            <div class="col-auto">
              <q-btn class="text-right" color="primary" label="Add Folder" type="submit" />
            </div>
          </div>
        </q-card-section>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script lang="ts" setup>
import { Field, useForm } from 'vee-validate';
import { computed, watch } from 'vue';
import * as yup from 'yup';
import { AxiosError } from 'axios';
import { useToast } from 'vue-toast-notification';
import { useSecretFileStore, type SecretFileDirectoryCreateForm } from '@/stores/secret/files';
import { useSecretStore } from '@/stores/secret';
import { storeToRefs } from 'pinia';
import rsaEncryptText from '@/composable/secret/rsa-encrypt-text';

const props = defineProps<{
  isOpen: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:isOpen', value: boolean): void;
  (e: 'refresh'): void;
}>();

const isOpen = computed({
  get() {
    return props.isOpen;
  },

  set(value: boolean) {
    emit('update:isOpen', value);
  },
});

const toast = useToast();

const secretStore = useSecretStore();
const { secret, secretPublicKey } = storeToRefs(secretStore);

const secretFileStore = useSecretFileStore();
const { currentParentID } = storeToRefs(secretFileStore);

const { handleSubmit, setErrors, setFieldValue } = useForm<SecretFileDirectoryCreateForm>({
  validationSchema: yup.object({
    name: yup.string().required().nullable().label('Folder Name'),
    parent_id: yup.string().nullable().label('Parent ID'),
  }),
  initialValues: {
    parent_id: currentParentID.value,
  },
});

const save = handleSubmit(async (payload) => {
  if (!secretPublicKey.value) {
    toast.error('Cannot get secret public key.');
    return;
  }

  try {
    const name = await rsaEncryptText(secretPublicKey.value, payload.name);

    if (!name) {
      toast.error('Failed to encrypt folder name.');
      return;
    }

    await secretFileStore.createSecretFileDirectory(secret.value!.id, {
      ...payload,
      name,
    });

    isOpen.value = false;
    toast.success('Successfully added folder.');
    emit('refresh');
  } catch (err) {
    if (err instanceof AxiosError) {
      setErrors(err.response?.data);
      return;
    }

    throw err;
  }
});

watch(isOpen, () => setErrors({}));

watch(currentParentID, () => {
  if (currentParentID?.value) {
    setFieldValue('parent_id', currentParentID.value);
  }
});
</script>
