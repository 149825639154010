<template>
  <AppContainer title="Access Requests">
    <template #buttons>
      <HelpPopup page="access-requests.list" />

      <q-btn
        type="button"
        color="primary"
        icon="search"
        class="q-ml-md"
        @click="() => (isFiltersOpen = !isFiltersOpen)"
        round
        dense
      />
    </template>

    <template #drawerRight>
      <FilterDrawer v-model:is-open="isFiltersOpen" v-model:filters="filters" />
    </template>

    <q-page-container>
      <q-page class="q-pa-md">
        <div class="row justify-center">
          <q-list class="q-layout q-pa-md">
            <q-infinite-scroll @load="onLoad" :offset="250" ref="infiniteScroll">
              <template v-slot:loading>
                <div class="row justify-center q-my-md">
                  <q-spinner-dots color="primary" size="40px" />
                </div>
              </template>

              <div v-for="accessRequest in accessRequests" :key="accessRequest.id">
                <ListItem
                  :access-request="accessRequest"
                  @open-dialog="isGrantAccessRequestDialogOpen = true"
                />
              </div>

              <q-item v-if="accessRequests.length < 1" class="text-center">
                <q-item-section>
                  <q-item-label>No results available.</q-item-label>
                </q-item-section>
              </q-item>
            </q-infinite-scroll>
          </q-list>
        </div>
      </q-page>
    </q-page-container>

    <GrantAccessRequestDialog
      v-model:is-open="isGrantAccessRequestDialogOpen"
      @refresh-list="refreshList"
    />
  </AppContainer>
</template>

<script lang="ts" setup>
import { ref, watch, onMounted, onBeforeUnmount } from 'vue';
import { useAccessRequestStore, type AccessRequestListFilters } from '@/stores/access-request';
import type { QInfiniteScroll } from 'quasar';
import { storeToRefs } from 'pinia';
import ListItem from '@/components/accessRequests/ListItem.vue';
import FilterDrawer from '@/components/accessRequests/FilterDrawer.vue';
import GrantAccessRequestDialog from '@/components/accessRequests/GrantAccessRequestDialog.vue';
import { useRoute } from 'vue-router';

const infiniteScroll = ref<QInfiniteScroll | null>(null);

const isFiltersOpen = ref(false);
const isGrantAccessRequestDialogOpen = ref(false);

const route = useRoute();

const filters = ref<AccessRequestListFilters>({});

const accessRequestStore = useAccessRequestStore();
const { accessRequests } = storeToRefs(accessRequestStore);

watch(
  filters,
  async () => {
    if (!infiniteScroll.value) {
      return;
    }

    const response = await accessRequestStore.getAccessRequests({
      page: 1,
      filters: filters.value,
      reset: true,
    });
    infiniteScroll.value.setIndex(1);
    if (response.length < 50) {
      infiniteScroll.value.stop();
    }
  },
  { deep: true }
);

const onLoad = async (index: number, done: (stop: boolean) => void) => {
  const response = await accessRequestStore.getAccessRequests({
    page: index,
    filters: filters.value,
  });
  done(response.length < 50);

  const ac = accessRequests.value.find((accessRequest) => accessRequest.id === route.query.id);
  if (ac) {
    accessRequestStore.setAccessRequest(ac);
    isGrantAccessRequestDialogOpen.value = true;
  }
};

const refreshList = async () => {
  if (!infiniteScroll.value) {
    return;
  }

  const response = await accessRequestStore.getAccessRequests({
    page: 1,
    filters: filters.value,
    reset: true,
  });
  infiniteScroll.value.setIndex(1);
  if (response.length < 50) {
    infiniteScroll.value.stop();
  }
};

onMounted(() => {
  if (!infiniteScroll.value) {
    return;
  }

  infiniteScroll.value.trigger();
});

onBeforeUnmount(() => {
  if (infiniteScroll.value) {
    infiniteScroll.value.reset();
  }

  accessRequestStore.resetAccessRequests();
});
</script>
