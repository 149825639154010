import { defineStore } from 'pinia';
import http from '@/utils/http';

export interface SubscriptionState {
  stripePackages: StripePackage[];
}

export interface StripePackage {
  id: string;
  stripe_id: string;
  stripe_product_id: string;
  name: string;
  price: number;
  min_users: number;
  max_users: number;
  display_order: number;
}

export const useSubscriptionStore = defineStore('subscriptions', {
  state: (): SubscriptionState => ({
    stripePackages: [],
  }),

  getters: {},

  actions: {
    async getStripePackages(): Promise<StripePackage[]> {
      const response = await http.get('/subscriptions/packages');

      this.stripePackages = response.data;

      return this.stripePackages;
    },

    async subscribe(payload: SubscribePayload): Promise<void> {
      await http.post('/subscriptions/subscribe', payload);
    },
  },
});

export type SubscribePayload = {
  payment_method?: string;
  stripe_package_id?: string;
  use_existing_payment_method?: boolean;
  use_existing_package?: boolean;
  skip_payment_method?: boolean;
};
