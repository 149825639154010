<template>
  <q-layout view="hHh lpR fFf">
    <q-page-container class="auth">
      <q-page class="q-layout-padding flex justify-center items-center">
        <q-form class="card card-auth q-pa-lg rounded" @submit="submit">
          <div class="q-gutter-md">
            <div class="text-center q-mx-none heading q-mt-md q-mb-lg">
              <img src="@/assets/icons/icon.svg" class="logo-image" />
              <h2>strng.io</h2>
            </div>

            <Field name="password" v-slot="{ errorMessage, value, field }">
              <q-input
                label="Master Password"
                type="password"
                :model-value="value"
                v-bind="field"
                :error-message="errorMessage"
                :error="!!errorMessage"
                v-focus
              />
            </Field>

            <div class="row">
              <div class="col">
                <q-btn
                  class="text-left"
                  color="negative"
                  label="Logout"
                  type="button"
                  @click="logout"
                />
              </div>

              <div class="col-auto">
                <q-btn
                  class="text-right"
                  color="primary"
                  label="Unlock"
                  type="submit"
                  :loading="isLoading"
                />
              </div>
            </div>
          </div>
        </q-form>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script lang="ts" setup>
import { Field, useForm } from 'vee-validate';
import * as yup from 'yup';
import http from '@/utils/http';
import { useAuthUserStore } from '@/stores/auth-user';
import { useRoute, useRouter } from 'vue-router';
import { unwrapPrivateKey } from '@/utils/encryption/asymmetric/index';
import { AxiosError } from 'axios';
import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';
import useAddRecoveryKey from '@/composable/group/add-recovery-key';

const isLoading = ref(false);

const router = useRouter();
const route = useRoute();

const store = useAuthUserStore();
const { authUser } = storeToRefs(store);

const { addRecoveryKey } = useAddRecoveryKey();

type EnterPasswordForm = {
  password: string;
};

const schema = yup.object({
  password: yup.string().required().label('Password'),
});

const { handleSubmit, setErrors } = useForm<EnterPasswordForm>({
  validationSchema: schema,
  initialValues: {},
});

const submit = handleSubmit(async (values, actions) => {
  try {
    isLoading.value = true;

    const response = await http.post('/user/enter-password', values);
    const privateKey = await unwrapPrivateKey(response.data, values.password);

    store.setPrivateKey(privateKey);

    if (authUser.value?.has_recovery_group) {
      await addRecoveryKey(authUser.value!.id, privateKey);
    }

    router.push(redirectTo.value);

    isLoading.value = false;
  } catch (err) {
    isLoading.value = false;
    actions.resetForm();

    if (err instanceof AxiosError) {
      setErrors(err.response!.data);
    }
  }
});

const logout = () => {
  store.logout();
  router.replace({ name: 'login' });
};

const redirectTo = computed(() => {
  if (route.query.redirect) {
    return route.query.redirect as string;
  }

  return {
    name: store.can({ action: 'read', subject: 'dashboard' }) ? 'dashboard' : 'secrets',
  };
});
</script>
