import type { Secret } from '@/stores/secret';
import aesDecryptField from './aes-decrypt-text';
import getSecretSymmetricKey from './get-secret-symmetric-key';

export default async function decryptSecret(secret: Secret): Promise<Secret> {
  const privateKey = await getSecretSymmetricKey(secret.id);

  secret.username = await aesDecryptField(privateKey, secret.username);
  secret.secret = await aesDecryptField(privateKey, secret.secret);
  secret.otp_secret = await aesDecryptField(privateKey, secret.otp_secret);
  secret.url = await aesDecryptField(privateKey, secret.url);
  secret.note = await aesDecryptField(privateKey, secret.note);
  secret.score = parseInt((await aesDecryptField(privateKey, secret.score as string)) ?? '0');

  return Promise.resolve(secret);
}
