import { formatJSONtoPlainText } from './format-json-plain-text';
import type { ImportStructure } from './import-passwords';

export type BitwardenExport = {
  encrypted: boolean;
  folders: Folder[];
  items: Item[];
  organizations?: Organization[];
};

type Folder = {
  id: string;
  name: string;
};

type Organization = {
  id: string;
  name: string;
  collections: Collection[];
};

type Collection = {
  id: string;
  name: string;
};

type Item = {
  id: string;
  organizationId?: string;
  folderId?: string;
  type: ItemType;
  name: string;
  notes?: string;
  favorite: boolean;
  fields?: Field[];
  login?: Login;
  secureNote?: SecureNote;
  card?: Card;
  identity?: Identity;
  collectionIds?: string[];
  attachments?: Attachment[];
};

type ItemType = 1 | 2 | 3 | 4; // 1: Login, 2: Secure note, 3: Card, 4: Identity

type Field = {
  name: string;
  value: string;
  type: number; // 0: Text, 1: Hidden, 2: Boolean
};

type Login = {
  username: string;
  password: string;
  totp?: string;
  uris?: Uri[];
};

type Uri = {
  match?: number;
  uri: string;
};

type SecureNote = {
  type: number;
};

type Card = {
  cardholderName?: string;
  brand?: string;
  number?: string;
  expMonth?: string;
  expYear?: string;
  code?: string;
};

type Identity = {
  title?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  country?: string;
  company?: string;
  email?: string;
  phone?: string;
  ssn?: string;
  username?: string;
  passportNumber?: string;
  licenseNumber?: string;
};

type Attachment = {
  id: string;
  fileName: string;
  size: string;
  url: string;
};

export const parseBitwardenExport = async (data: BitwardenExport): Promise<ImportStructure> => {
  const importData: ImportStructure = {
    groups: [],
    secrets: [],
    errors: [],
  };

  for (let i = 0; i < data.folders.length; i++) {
    const folder = data.folders[i];

    importData.groups.push({
      name: folder.name,
      external_id: folder.id,
      internal_id: null,
    });
  }

  for (let i = 0; i < data.items.length; i++) {
    const item = data.items[i];

    let url = undefined;
    if (item.login && (item.login?.uris ?? []).length > 0) {
      url = (item.login?.uris ?? [])[0]?.uri;
    }

    let notes = item.notes ?? '';

    if (item.type === 3 && item.card) {
      notes += notes === '' ? '' : '\n\n';

      notes += formatJSONtoPlainText(item.card);
    }

    if (item.type === 4 && item.identity) {
      notes += notes === '' ? '' : '\n\n';

      notes += formatJSONtoPlainText(item.identity);
    }

    if (item.fields && item.fields.length > 0) {
      const fields = item.fields.map((field) => ({
        [field.name]: field.value,
      }));

      notes += notes === '' ? '' : '\n\n';

      notes += formatJSONtoPlainText(fields);
    }

    importData.secrets.push({
      name: item.name,
      username: item.login?.username,
      secret: item.login?.password ?? 'N/A',
      notes: notes,
      url: url,
      otp_secret: item.login?.totp,

      external_id: item.id,
      internal_id: null,

      group_external_id: item.folderId ?? null,
      group_internal_id: null,
    });
  }

  return importData;
};
