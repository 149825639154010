import type { AddSecretForm, CreateSecretPayload } from '@/stores/secret';
import { useAuthUserStore } from '@/stores/auth-user';
import {
  exportPrivateKey,
  generateRsaKey,
  importPublicKey,
  exportPublicKey,
  rsaEncrypt,
} from '@/utils/encryption/asymmetric';
import { aesEncrypt, exportAesKey, generateAesKey } from '@/utils/encryption/symmetric';
import { stringToBytes } from '@/utils/encryption/utils';
import { storeToRefs } from 'pinia';
import aesEncryptText from '@/composable/secret/aes-encrypt-text';
import { generateSaltedHash, decryptUrlSalt, parseUrlForHash } from '@/composable/url-hash';

export default async function createSecret(payload: AddSecretForm): Promise<CreateSecretPayload> {
  const authUserStore = useAuthUserStore();
  const { authUser } = storeToRefs(authUserStore);

  if (authUser.value === null) {
    throw new Error('You must be logged in to create a secret.');
  }

  // This should never happen, just here to make the type check happy
  if (authUser === null) {
    throw new Error('An error occurred, please try again later.');
  }

  const asymmetricKeyPair = await generateRsaKey();

  if (asymmetricKeyPair.publicKey === undefined || asymmetricKeyPair.privateKey === undefined) {
    throw new Error('Could not generate RSA key pair.');
  }

  const symmetricKey: CryptoKey = await generateAesKey();

  const usernameCiphertext = await aesEncryptText(symmetricKey, payload.username);
  const passwordCiphertext = await aesEncryptText(symmetricKey, payload.secret);
  const otpSecretCiphertext = await aesEncryptText(symmetricKey, payload.otp_secret);
  const urlCiphertext = await aesEncryptText(symmetricKey, payload.url);
  const noteCiphertext = await aesEncryptText(symmetricKey, payload.note);
  const scoreCiphertext = await aesEncryptText(symmetricKey, payload.score?.toString());

  let urlHash = null;
  const urlSalt = await decryptUrlSalt();
  if (payload.url && urlSalt) {
    urlHash = await generateSaltedHash(parseUrlForHash(payload.url), urlSalt);
  }

  const privateKeyString = await exportPrivateKey(asymmetricKeyPair.privateKey);
  const privateKeyBytes = stringToBytes(privateKeyString);
  const encryptedPrivateKey: string = await aesEncrypt(symmetricKey, privateKeyBytes);

  const symmetricKeyExported = await exportAesKey(symmetricKey);

  const publicKey = await importPublicKey(authUser.value.asymmetric_key_public);

  const encryptedAesKey = await rsaEncrypt(publicKey, symmetricKeyExported);

  const publicKeyString = await exportPublicKey(asymmetricKeyPair.publicKey);

  const encryptedSecret: CreateSecretPayload = {
    name: payload.name as string,
    username: usernameCiphertext,
    secret: passwordCiphertext as string,
    otp_secret: otpSecretCiphertext,
    url: urlCiphertext,
    url_hash: urlHash,
    note: noteCiphertext,
    hidden: payload.hidden as boolean,
    score: scoreCiphertext,
    score_current: payload.score as number,
    user_id: authUser.value.id,
    tenant_id: authUser.value.tenant_id,
    user_secret_symmetric_key: encryptedAesKey,
    user_secret_asymmetric_key_public: publicKeyString,
    user_secret_asymmetric_key_private: encryptedPrivateKey,
  };

  return encryptedSecret;
}
