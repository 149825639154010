import { useSecretStore } from '@/stores/secret';

export default async function getSecretSymmetricKey(secretID: string): Promise<CryptoKey> {
  const secretStore = useSecretStore();

  const secretKey = await secretStore.getSecretKey(secretID);

  if (secretKey === null || secretKey.asymmetric_key_private === null) {
    throw new Error('Cannot get secret key');
  }

  return secretKey.symmetric_key;
}
