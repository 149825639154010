<template>
  <div class="payment-method-card q-mt-md">
    <h4 class="q-my-sm">Payment Method</h4>

    <div v-if="hasPaymentMethod" class="row q-mt-md q-mb-md">
      <div class="col">
        <img
          v-if="cardBrandImg"
          :src="cardBrandImg"
          class="card-brand q-mr-md payment-method-details"
        />

        <span class="payment-method-details">
          {{ paymentMethod }}
        </span>
      </div>

      <div class="col-auto">
        <q-btn color="primary" label="Edit" @click="isDialogOpen = true" />
      </div>
    </div>

    <div v-else class="row q-my-md">
      <div class="col">
        <span class="payment-method-details"> No Payment Method </span>
      </div>

      <div class="col-auto">
        <q-btn color="primary" label="Add" @click="isDialogOpen = true" />
      </div>
    </div>
  </div>

  <TenantSubscriptionChangePaymentMethodDialog v-model:isOpen="isDialogOpen" />
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useTenantStore } from '@/stores/tenant';
import { storeToRefs } from 'pinia';
import visaImage from '@/assets/images/visa.png';
import mastercardImage from '@/assets/images/mastercard.png';
import TenantSubscriptionChangePaymentMethodDialog from './TenantSubscriptionChangePaymentMethodDialog.vue';

const isDialogOpen = ref(false);

const tenantStore = useTenantStore();
const { tenant } = storeToRefs(tenantStore);

const hasPaymentMethod = computed(() => tenant.value?.stripe_payment_method !== null);

const cardBrandImg = computed(() => {
  if (tenant.value?.stripe_payment_method?.card_brand === 'visa') {
    return visaImage;
  }

  if (tenant.value?.stripe_payment_method?.card_brand === 'mastercard') {
    return mastercardImage;
  }

  return null;
});

const paymentMethod = computed(() => {
  return `•••• •••• •••• ${tenant.value?.stripe_payment_method?.card_last_four}, Exp: ${tenant.value?.stripe_payment_method?.card_exp_month}/${tenant.value?.stripe_payment_method?.card_exp_year}`;
});
</script>
