<template>
  <q-header reveal>
    <RecoveryGroupBanner />
    <PaymentPastDueBanner />

    <q-toolbar>
      <q-btn flat @click="() => emit('open-drawer')" round dense icon="menu" />
      <q-toolbar-title>
        {{ title }}
      </q-toolbar-title>

      <div class="q-mr-md q-gutter-md">
        <slot name="buttons" />
      </div>
    </q-toolbar>

    <slot name="tabs" />

    <q-tabs v-if="!hasTabsSlot && route.name !== 'dashboard'" align="left">
      <q-route-tab :to="{ name: route.name }" label="List" />
    </q-tabs>
  </q-header>

  <slot name="drawerRight" />

  <slot />
</template>

<script lang="ts" setup>
import { computed, useSlots } from 'vue';
import RecoveryGroupBanner from '@/components/recoveryGroups/RecoveryGroupBanner.vue';
import { useRoute } from 'vue-router';
import PaymentPastDueBanner from './subscriptions/PaymentPastDueBanner.vue';

const slots = useSlots();
const route = useRoute();

defineProps<{
  title: string;
}>();

const emit = defineEmits<{
  (e: 'open-drawer'): void;
}>();

const hasTabsSlot = computed(() => {
  return !!slots['tabs'];
});
</script>
