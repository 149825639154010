import { defineStore } from 'pinia';
import http from '@/utils/http';

export interface SecretLogState {
  secretLogs: SecretLog[];
}

export interface SecretLog {
  id: string;
  secret_id: string;
  user_id: string;
  type: string;
  metadata: string;
  created_at: string;

  user_first_name: string;
  user_last_name: string;
  user_email: string;
}

export interface SecretLogListFilters {
  search?: string;
}

export const useSecretLogStore = defineStore('secretLog', {
  state: (): SecretLogState => ({
    secretLogs: [],
  }),

  getters: {},

  actions: {
    async getSecretLogs({
      secretID,
      page,
      filters,
      reset = false,
    }: {
      secretID: string;
      page: number;
      filters: SecretLogListFilters;
      reset?: boolean;
    }) {
      const response = await http.get(`/secrets/${secretID}/logs`, {
        params: { page, ...filters },
      });

      if (reset) {
        this.secretLogs = [];
      }

      this.secretLogs.push(...(response.data ?? []));

      return this.secretLogs;
    },

    resetSecretLogs() {
      this.secretLogs = [];
    },
  },
});
