import { defineStore } from 'pinia';
import http from '@/utils/http';

export interface GroupSecretState {
  groupSecrets: GroupSecret[];
  groupSecretOptions: GroupSecretOption[];
}

export interface GroupSecret {
  id: string;
  group_id: string;
  name: string;
  created_at: string;
  updated_at: string;
}

export interface GroupSecretOption {
  id: string;
  name: string;
}

export interface GroupSecretListFilters {
  search?: string;
  date?: { from: string; to: string };
}

export const useGroupSecretStore = defineStore('groupSecrets', {
  state: (): GroupSecretState => ({
    groupSecrets: [],
    groupSecretOptions: [],
  }),

  actions: {
    async getGroupSecrets({
      groupID,
      page,
      filters,
      reset = false,
    }: {
      groupID: string;
      page: number;
      filters: GroupSecretListFilters;
      reset?: boolean;
    }) {
      const response = await http.get(`/groups/${groupID}/secrets`, {
        params: { page, ...filters },
      });

      if (reset) {
        this.groupSecrets = [];
      }

      this.groupSecrets.push(...(response.data ?? []));

      return this.groupSecrets;
    },

    resetGroupSecrets() {
      this.groupSecrets = [];
    },

    async getGroupSecretOptions(groupID: string) {
      const response = await http.get(`/groups/${groupID}/secret-options`);

      this.groupSecretOptions = response.data ?? [];

      return this.groupSecretOptions;
    },

    resetGroupSecretOptions() {
      this.groupSecretOptions = [];
    },
  },
});
