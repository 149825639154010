export function csvArraysToObject(arrays: string[][]) {
  // Extract the keys from the first array
  const keys = arrays[0];
  // Initialize an empty array to hold the resulting objects
  const result = [];

  // Iterate over the array starting from the second element
  for (let i = 1; i < arrays.length; i++) {
    const array = arrays[i];

    if (array.length === 0 || array.every((value) => value === '')) {
      continue; // Skip to the next iteration if the current array is empty or all values are empty strings
    }

    const obj: Record<string, string | null> = {};

    keys.forEach((key, index) => {
      obj[key] = array.length > index ? array[index] : null;
    });
    result.push(obj);
  }

  return result;
}
