import LandingView from '@/views/landing-page/LandingView.vue';
import TermsAndConditionsView from '@/views/landing-page/TermsAndConditionsView.vue';
import PrivacyPolicyView from '@/views/landing-page/PrivacyPolicyView.vue';

export default [
  {
    path: '/',
    name: 'landing',
    component: LandingView,
  },
  {
    path: '/terms-and-conditions',
    name: 'terms-and-conditions',
    component: TermsAndConditionsView,
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicyView,
  },
];
