export async function generateSaltedHash(input: string, salt: string): Promise<string> {
  // Combine input and salt
  const inputWithSalt = new TextEncoder().encode(input + salt);

  // Hash the combined value
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', inputWithSalt);

  // Convert the hash to a hex string
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');

  return hashHex;
}
