import { aesEncrypt } from '@/utils/encryption/symmetric';
import { stringToBytes } from '@/utils/encryption/utils';

export default async function aesEncryptText(
  key: CryptoKey,
  field: string | undefined
): Promise<string | undefined> {
  if (!field) {
    return undefined;
  }

  const bytes = stringToBytes(field as string);

  return await aesEncrypt(key, bytes);
}
