import { useGroupStore } from '@/stores/group';
import useGroupEncryption from '@/composable/group';
import useSecretEncryption from '../secret';
import { useSecretStore } from '@/stores/secret';
import zxcvbn from 'zxcvbn';
import useAddSecretAccess from '../secret/add-access';
import { isAxiosError } from 'axios';
import { ref } from 'vue';
import Papa from 'papaparse';
import { csvArraysToObject } from './csv-arrays-to-objects';
import { parseBitwardenExport, type BitwardenExport } from './parse-bitwarden-export';
import { parseLastpassExport, type LastPassExport } from './parse-lastpass-export';

export type ImportStructureGroup = {
  name: string;
  external_id: string;
  internal_id: string | null;
};

export type ImportStructureSecret = {
  name: string;
  username?: string;
  secret?: string;
  notes?: string;
  url?: string;
  otp_secret?: string;

  external_id: string;
  internal_id: string | null;

  group_external_id: string | null;
  group_internal_id: string | null;
};

export type ImportStructureError = {
  type: 'Group' | 'Secret';
  name: string;
  message: string;

  external_id: string;
};

export type ImportStructure = {
  groups: ImportStructureGroup[];
  secrets: ImportStructureSecret[];
  errors: ImportStructureError[];
};

async function readUploadedFileAsText(inputFile: File): Promise<string> {
  const reader = new FileReader();

  // Wrap the FileReader in a Promise
  return new Promise((resolve, reject) => {
    // Define the onload handler
    reader.onload = (event) => {
      if (!event.target || !event.target.result || typeof event.target.result !== 'string') {
        reject(new Error('Problem parsing the input file.'));
        return;
      }

      resolve(event.target.result);
    };

    // Define the onerror handler
    reader.onerror = (event) => {
      reader.abort(); // Optional: explicitly abort the read operation
      reject(new Error('Problem parsing input file.'));
    };

    // Start reading the file as Text
    reader.readAsText(inputFile, 'UTF-8');
  });
}

function textToJson(text: string, fileType: string): Object {
  if (fileType === 'text/csv') {
    const csvObj = Papa.parse(text);
    if (!csvObj || !csvObj.data || csvObj.errors.length > 0) {
      throw new Error('Error parsing csv.');
    }

    return csvArraysToObject(csvObj.data as string[][]);
  }

  return JSON.parse(text);
}

export function useImportPasswords() {
  const importData = ref<ImportStructure>({ groups: [], secrets: [], errors: [] });

  const parseImportFile = async (file: File, importFrom: 'bitwarden' | 'lastpass') => {
    const fileContents = await readUploadedFileAsText(file);
    const jsonData = textToJson(fileContents, file.type);

    importData.value = { groups: [], secrets: [], errors: [] };

    switch (importFrom) {
      case 'bitwarden':
        importData.value = await parseBitwardenExport(jsonData as BitwardenExport);
        break;
      case 'lastpass':
        importData.value = await parseLastpassExport(jsonData as LastPassExport[]);
        break;
      default:
        throw new Error('Invalid source to import from.');
    }
  };

  const asyncPool = async <T>(
    limit: number,
    array: Array<T>,
    iteratorFn: (item: T) => Promise<void>
  ) => {
    const ret = [];
    const executing: Promise<void>[] = [];

    for (const item of array) {
      const p = Promise.resolve().then(() => iteratorFn(item));
      ret.push(p); // Store promise for final result

      if (limit <= array.length) {
        const e: Promise<void> = p.then(() => {
          executing.splice(executing.indexOf(e), 1);
          return; // Explicitly return void here
        });

        executing.push(e);
        if (executing.length >= limit) {
          await Promise.race(executing); // Wait for one to finish
        }
      }
    }
    return Promise.all(ret); // Return final result when all promises are resolved
  };

  const importPasswords = async () => {
    const groupStore = useGroupStore();
    const { createGroup } = useGroupEncryption();

    const secretStore = useSecretStore();
    const { createSecret } = useSecretEncryption();

    const importGroup = async (item: ImportStructureGroup): Promise<void> => {
      try {
        const createGroupPayload = await createGroup({
          name: item.name,
          hidden: false,
        });

        const group = await groupStore.addGroup({ payload: createGroupPayload });

        item.internal_id = group.id;
      } catch (err) {
        let message = err?.toString() ?? 'An error occurred while importing group';

        if (isAxiosError(err) && err.response && err.response.status === 422) {
          message = JSON.stringify(err.response.data);
        }

        importData.value.errors.push({
          type: 'Group',
          name: item.name,
          external_id: item.external_id,
          message: message,
        });
      }
    };

    await asyncPool(5, importData.value.groups, importGroup);

    const importSecret = async (item: ImportStructureSecret): Promise<void> => {
      try {
        const score = zxcvbn(item.secret ?? '');

        const encryptedSecret = await createSecret({
          name: item.name,
          username: item.username,
          secret: item.secret ?? 'N/A',
          url: item.url,
          note: item.notes,
          otp_secret: item.otp_secret,
          hidden: false,
          score: score?.score?.valueOf() ?? 0,
        });

        const secret = await secretStore.addSecret({ payload: encryptedSecret });

        item.internal_id = secret.id;

        if (!item.group_external_id) {
          return;
        }

        const groupData = importData.value.groups.find(
          ({ external_id }) => external_id === item.group_external_id
        );

        if (!groupData) {
          return;
        }

        const { addAccess } = useAddSecretAccess();

        await addAccess({
          secretID: secret.id,
          accessOpt: {
            id: groupData.internal_id,
            name: groupData.name,
            type: 'Groups',
          },
          sharingPerms: false,
        });
      } catch (err) {
        let message = err?.toString() ?? 'An error occurred while importing secret';

        if (isAxiosError(err) && err.response && err.response.status === 422) {
          message = JSON.stringify(err.response.data);
        }

        importData.value.errors.push({
          type: 'Secret',
          name: item.name,
          external_id: item.external_id,
          message: message,
        });
      }
    };

    await asyncPool(5, importData.value.secrets, importSecret);

    return importData;
  };

  const resetImportData = () => {
    importData.value = { groups: [], secrets: [], errors: [] };
  };

  return {
    importData,
    parseImportFile,
    importPasswords,
    resetImportData,
  };
}
