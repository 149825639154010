<template>
  <AppContainer :title="title">
    <template #tabs>
      <slot name="tabs" />
    </template>

    <q-page-container>
      <q-page class="q-pa-md">
        <div class="row justify-center">
          <q-form class="q-layout col-12 col-lg-6 card rounded q-pa-md" @submit="save">
            <div class="row">
              <div class="col-12">
                <h6 class="q-ma-sm">Permissions</h6>
              </div>
            </div>

            <div class="row" v-for="permission in permissions" :key="permission.id">
              <div class="col-12">
                <q-checkbox
                  v-model="permission.has_permission"
                  :label="permission.title"
                  :disable="cannotWriteUsers"
                />
              </div>
            </div>

            <div class="row q-pt-md q-px-sm">
              <div class="col"></div>

              <div class="col-auto">
                <q-btn
                  class="text-right"
                  color="primary"
                  label="Save"
                  type="submit"
                  :disable="cannotWriteUsers"
                />
              </div>
            </div>
          </q-form>
        </div>
      </q-page>
    </q-page-container>
  </AppContainer>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import { useToast } from 'vue-toast-notification';
import { useAuthUserStore } from '@/stores/auth-user';

const toast = useToast();

const route = useRoute();

const authUserStore = useAuthUserStore();

const userStore = useUserStore();
const { permissions } = storeToRefs(userStore);

await userStore.getPermissions({ id: route.params.id as string });

const cannotWriteUsers = computed(() => !authUserStore.can({ action: 'write', subject: 'users' }));

const save = async () => {
  await userStore.updatePermissions({
    id: route.params.id as string,
    payload: permissions.value,
  });

  toast.success('Permissions saved successfully.');
};

const title = computed(() => `${userStore.fullName} - Permissions`);
</script>
