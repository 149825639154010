<template>
  <q-layout view="hHh lpR fFf">
    <q-page-container class="auth">
      <q-page class="q-layout-padding flex justify-center items-center">
        <q-form class="card card-auth q-pa-lg rounded" @submit="submit">
          <div class="q-gutter-md">
            <div class="text-center q-mx-none heading q-mt-md q-mb-lg">
              <img src="@/assets/icons/icon.svg" class="logo-image" />
              <h2>strng.io</h2>
            </div>

            <Field name="password" v-slot="{ errorMessage, value, field }">
              <q-input
                label="Password"
                type="password"
                :model-value="value"
                v-bind="field"
                :error-message="errorMessage"
                :error="!!errorMessage"
              />
            </Field>

            <Field name="confirm_password" v-slot="{ errorMessage, value, field }">
              <q-input
                label="Confirm Password"
                type="password"
                :model-value="value"
                v-bind="field"
                :error-message="errorMessage"
                :error="!!errorMessage"
              />
            </Field>

            <div class="row">
              <div class="col">
                <PasswordStrength :password="values.password" />
              </div>
            </div>

            <div class="row">
              <div class="col"></div>

              <div class="col-auto">
                <q-btn
                  class="text-right"
                  color="primary"
                  label="Unlock"
                  type="submit"
                  :loading="isLoading"
                />
              </div>
            </div>
          </div>
        </q-form>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { Field, useForm } from 'vee-validate';
import * as yup from 'yup';
import http from '@/utils/http';
import { useAuthUserStore } from '@/stores/auth-user';
import { useRouter } from 'vue-router';
import { unwrapPrivateKey, wrapCryptoKey } from '@/utils/encryption/asymmetric/index';
import { AxiosError } from 'axios';
import { storeToRefs } from 'pinia';
import { useToast } from 'vue-toast-notification';
import PasswordStrength from '@/components/form/PasswordStrength.vue';

const props = defineProps({
  code: String,
  user_id: String,
  invite_key: String,
});

const isLoading = ref(false);

const router = useRouter();
const toast = useToast();

const authUserStore = useAuthUserStore();
const { authUser } = storeToRefs(authUserStore);

type EnterPasswordForm = {
  password: string;
  confirm_password: string;
};

const schema = yup.object({
  password: yup.string().required().min(6).label('Password'),
  confirm_password: yup.string().required().min(6).label('Password'),
});

const { handleSubmit, setErrors, values } = useForm<EnterPasswordForm>({
  validationSchema: schema,
  initialValues: {},
});

const submit = handleSubmit(async (values, actions) => {
  if (!authUser.value || !props.invite_key) {
    toast.error('Something went wrong. Please try again later.');
    return;
  }

  try {
    isLoading.value = true;

    const unwrappedPrivateKey = await unwrapPrivateKey(
      authUser.value.asymmetric_key_private,
      props.invite_key
    );
    const wrappedPrivateKey = await wrapCryptoKey(unwrappedPrivateKey, values.password);

    await http.post('/setup-account', {
      ...values,
      asymmetric_key_private: wrappedPrivateKey,
    });

    authUserStore.setPrivateKey(unwrappedPrivateKey);

    isLoading.value = false;

    router.push({ name: 'secrets' });
  } catch (err) {
    isLoading.value = false;

    actions.resetForm();

    if (err instanceof AxiosError) {
      setErrors(err.response!.data);
      return;
    }

    throw err;
  }
});

onMounted(async () => {
  if (!props.code || !props.user_id) {
    toast.error('An error occured verifying your email. Please try again later.');
    return;
  }

  try {
    await http.post('/verify/email', {
      code: props.code,
      user_id: props.user_id,
    });
    await authUserStore.authorize();
  } catch (err) {
    toast.error('An error occured verifying your email. Please try again later.');
  }
});
</script>
