import type { RouteLocationNormalized } from 'vue-router';
import RecoverAccount from '@/views/account-recovery/RecoverAccount.vue';

export default [
  {
    path: '/recover-account',
    name: 'recover-account',
    component: RecoverAccount,
    props: (route: RouteLocationNormalized) => ({ ...route.query }),
  },
];
