import { defineStore } from 'pinia';
import http from '@/utils/http';

export type StripeInvoiceState = {
  stripeInvoices: StripeInvoice[];
};

export type StripeInvoice = {
  id: string;
  tenant_id: string;
  stripe_invoice_id: string;
  number: string;
  hosted_invoice_url: string;
  pdf_url: string;
  amount_due: number;
  amount_paid: number;
  amount_remaining: number;
  paid: boolean;
  status: string;
  created_at: string;
  updated_at: string;
};

export const useStripeInvoiceStore = defineStore('stripeInvoices', {
  state: (): StripeInvoiceState => ({
    stripeInvoices: [],
  }),

  getters: {},

  actions: {
    async getStripeInvoices({ page, reset = false }: { page: number; reset?: boolean }) {
      const { data } = await http.get('/tenant/invoices', { params: { page } });

      if (reset) {
        this.stripeInvoices = [];
      }

      this.stripeInvoices.push(...(data ?? []));

      return data ?? [];
    },

    resetStripeInvoices() {
      this.stripeInvoices = [];
    },
  },
});
