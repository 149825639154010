<template>
  <q-dialog v-model="isOpen" position="top">
    <q-card class="add-access-dialog">
      <q-card-section class="row items-center q-pb-none">
        <div class="text-h6">Add Access</div>
        <q-space />
        <q-btn icon="close" flat round dense v-close-popup />
      </q-card-section>

      <q-form @submit="save">
        <q-card-section>
          <div class="row">
            <Field name="selected_access" v-slot="{ errorMessage }">
              <q-select
                :options="options"
                label="Access"
                clearable
                v-model="selectedAccess"
                :error-message="errorMessage"
                :error="!!errorMessage"
                class="col"
                use-input
                @filter="filterFn"
                :display-value="`${values.selected_access ? values.selected_access.name : ''}`"
              >
                <template v-slot:option="scope">
                  <q-item v-bind="scope.itemProps">
                    <q-item-section>
                      <q-item-label>{{ scope.opt.name }}</q-item-label>
                    </q-item-section>
                  </q-item>
                </template>

                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      <q-item-label>No options available</q-item-label>
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
            </Field>
          </div>

          <div class="row">
            <Field name="sharing_permissions" v-slot="{ errorMessage, value, field }">
              <q-checkbox
                class="col"
                label="Grant access sharing permissions"
                :model-value="value"
                v-bind="field"
                :error-message="errorMessage"
                :error="!!errorMessage"
              />
            </Field>
          </div>

          <div class="row q-pl-md">
            <div class="text-sm text-grey col">
              This will allow the selected user to grant access to other users.
            </div>
          </div>

          <div class="row q-mt-md">
            <div class="col"></div>

            <div class="col-auto">
              <q-btn class="text-right" color="primary" label="Add Access" type="submit" />
            </div>
          </div>
        </q-card-section>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script lang="ts" setup>
import { Field, useForm } from 'vee-validate';
import { computed, onBeforeUnmount, ref, watch } from 'vue';
import * as yup from 'yup';
import { useGroupStore } from '@/stores/group';
import { useGroupAccessStore, type GroupAccessOption } from '@/stores/group/access';
import { storeToRefs } from 'pinia';
import { AxiosError } from 'axios';
import { useToast } from 'vue-toast-notification';
import useAddGroupAccess from '@/composable/group/add-access';

const props = defineProps<{
  isOpen: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:isOpen', value: boolean): void;
}>();

const isOpen = computed({
  get() {
    return props.isOpen;
  },

  set(value: boolean) {
    emit('update:isOpen', value);
  },
});

const toast = useToast();

const { addAccess } = useAddGroupAccess();

const options = ref<GroupAccessOption[]>([]);

const groupStore = useGroupStore();
const { group } = storeToRefs(groupStore);

const groupAccessStore = useGroupAccessStore();
const { groupAccessOptions } = storeToRefs(groupAccessStore);

await groupAccessStore.getGroupAccessOptions(group.value!.id, group.value!.recovery);
options.value = groupAccessOptions.value;

interface AddAccessForm {
  selected_access: GroupAccessOption | null;
  sharing_permissions: boolean;
}

const { handleSubmit, setErrors, setValues, values, resetForm } = useForm<AddAccessForm>({
  validationSchema: yup.object({
    selected_access: yup.object().required().label('Access'),
    sharing_permissions: yup.boolean().nullable().label('Sharing permission'),
  }),
  initialValues: {
    selected_access: null,
    sharing_permissions: false,
  },
});

const save = handleSubmit(async (payload) => {
  try {
    await addAccess({
      groupID: group.value!.id,
      accessOpt: payload.selected_access!,
      sharingPerms: payload.sharing_permissions,
    });

    isOpen.value = false;
    toast.success('Successfully added access.');
  } catch (err) {
    if (err instanceof AxiosError) {
      setErrors(err.response?.data);
      return;
    }

    throw err;
  }
});

const selectedAccess = computed({
  get() {
    return values.selected_access;
  },

  set(value: GroupAccessOption | null) {
    setValues({
      selected_access: value,
    });
  },
});

const filterFn = (val: string, update: (fn: () => void) => void) => {
  if (val === '') {
    update(() => {
      options.value = groupAccessOptions.value;
    });
    return;
  }

  update(() => {
    const needle = val.toLowerCase();

    options.value = groupAccessOptions.value.filter((t: GroupAccessOption) => {
      if (!t.name) {
        return true;
      }

      return t.name.toLowerCase().indexOf(needle) > -1;
    });
  });
};

onBeforeUnmount(() => {
  groupAccessStore.resetGroupAccessOptions();
});

watch(isOpen, () => {
  resetForm();
});
</script>
