<template>
  <AppContainer title="Add Group">
    <template #buttons>
      <HelpPopup page="groups.add" />
    </template>

    <q-page-container>
      <q-page class="q-pa-md">
        <div class="row justify-center">
          <q-form class="q-layout col-12 col-lg-6 q-pa-md" @submit="save">
            <div class="row q-mb-sm">
              <Field name="name" v-slot="{ errorMessage, value, field }">
                <q-input
                  class="q-px-sm col"
                  label="Name"
                  :model-value="value"
                  v-bind="field"
                  :error-message="errorMessage"
                  :error="!!errorMessage"
                />
              </Field>
            </div>

            <div class="row q-mb-sm">
              <Field name="note" v-slot="{ errorMessage, value, field }">
                <q-input
                  class="q-px-sm col"
                  label="Note"
                  type="textarea"
                  :model-value="value"
                  v-bind="field"
                  :error-message="errorMessage"
                  :error="!!errorMessage"
                />
              </Field>
            </div>

            <div class="row q-mb-sm">
              <Field name="hidden" v-slot="{ errorMessage, value, field }">
                <q-checkbox
                  class="q-px-sm col"
                  label="Hide group from users who do not have access."
                  :model-value="value"
                  v-bind="field"
                  :error-message="errorMessage"
                  :error="!!errorMessage"
                />
              </Field>
            </div>

            <div class="row">
              <div class="col"></div>

              <div class="col-auto">
                <q-btn
                  class="text-right"
                  color="primary"
                  label="Save"
                  type="submit"
                  :loading="isLoading"
                />
              </div>
            </div>
          </q-form>
        </div>
      </q-page>
    </q-page-container>
  </AppContainer>
</template>

<script lang="ts" setup>
import { Field, useForm } from 'vee-validate';
import * as yup from 'yup';
import { useGroupStore, type AddGroupForm } from '@/stores/group';
import { AxiosError } from 'axios';
import { useRouter } from 'vue-router';
import useGroupEncryption from '@/composable/group';
import { useToast } from 'vue-toast-notification';
import { ref } from 'vue';

const isLoading = ref(false);

const router = useRouter();
const toast = useToast();

const groupStore = useGroupStore();

const { createGroup } = useGroupEncryption();

const schema = yup.object({
  name: yup.string().required().label('Name'),
  note: yup.string().label('Note'),
  hidden: yup.boolean().label('Hidden'),
});

const { handleSubmit, setErrors } = useForm<AddGroupForm>({
  validationSchema: schema,
  initialValues: {
    hidden: false,
  },
});

const save = handleSubmit(async (payload) => {
  try {
    isLoading.value = true;

    const createGroupPayload = await createGroup(payload);
    const group = await groupStore.addGroup({ payload: createGroupPayload });

    router.push({ name: 'group.details', params: { id: group.id } });
    toast.success('Group updated successfully.');

    isLoading.value = false;
  } catch (err) {
    isLoading.value = false;
    if (err instanceof AxiosError) {
      setErrors(err.response?.data);
      return;
    }

    throw err;
  }
});
</script>
