<template>
  <div class="password-strength-meter" :class="props.class">
    <div class="password-strength-bar" :class="scoreClass"></div>
  </div>
</template>

<script lang="ts" setup>
import { ref, toRefs, watch, onMounted } from 'vue';
import zxcvbn from 'zxcvbn';

const props = defineProps<{
  password: string | undefined | null;
  class?: string | null;
}>();

const emit = defineEmits<{
  (e: 'update', value: zxcvbn.ZXCVBNResult): void;
}>();

const { password } = toRefs(props);

const scoreClass = ref<string | null>(null);

const calculateScore = () => {
  if (!password?.value) {
    scoreClass.value = null;
    return;
  }

  const score = zxcvbn(password.value);
  if (!score) {
    return;
  }

  emit('update', score);

  scoreClass.value = `password-strength-bar-${score.score}`;
};

watch(password, calculateScore);

onMounted(calculateScore);
</script>
