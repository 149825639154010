<template>
  <q-layout view="hHh lpR fFf">
    <q-page-container class="auth">
      <q-page class="q-layout-padding flex justify-center items-center">
        <component :is="verify" />
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useAuthUserStore } from '@/stores/auth-user';
import { storeToRefs } from 'pinia';
import VerifyTwoFactorEmail from '@/components/verification/VerifyTwoFactorEmail.vue';
import VerifyTwoFactorTotp from '@/components/verification/VerifyTwoFactorTotp.vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const authUserStore = useAuthUserStore();
const { authUser } = storeToRefs(authUserStore);

if (!authUser || !authUser.value) {
  router.replace({ name: 'login' });
}

const verify = computed(() => {
  if (!authUser || !authUser.value) {
    return null;
  }

  if (authUser.value.two_factor_method === 'email') {
    return VerifyTwoFactorEmail;
  }

  return VerifyTwoFactorTotp;
});
</script>
