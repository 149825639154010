import UserList from '@/views/users/UserList.vue';
import UserAdd from '@/views/users/UserAdd.vue';
import UserShow from '@/views/users/UserShow.vue';
import UserDetails from '@/views/users/UserDetails.vue';
import UserSecurity from '@/views/users/UserSecurity.vue';
import UserPermissions from '@/views/users/UserPermissions.vue';

export default [
  {
    path: '/users',
    name: 'users',
    redirect: { name: 'users.list' },
    meta: {
      isAdmin: true,
      template: 'app',
      ability: {
        action: 'read',
        subject: 'users',
      },
    },
    children: [
      {
        path: '',
        name: 'users.list',
        component: UserList,
      },
      {
        path: 'add',
        name: 'users.add',
        component: UserAdd,
        meta: {
          ability: {
            action: 'write',
            subject: 'users',
          },
        },
      },
      {
        path: ':id',
        name: 'user',
        component: UserShow,
        children: [
          {
            path: 'details',
            name: 'user.details',
            component: UserDetails,
          },
          {
            path: 'security',
            name: 'user.security',
            component: UserSecurity,
          },
          {
            path: 'permissions',
            name: 'user.permissions',
            component: UserPermissions,
          },
        ],
      },
    ],
  },
];
