import { defineStore, storeToRefs } from 'pinia';
import http from '@/utils/http';
import { useAuthUserStore } from './auth-user';
import { importPublicKey, rsaDecrypt } from '@/utils/encryption/asymmetric';
import { importAesKey } from '@/utils/encryption/symmetric';

export interface GroupState {
  groups: Group[];
  group: Group | null;
}

export interface Group {
  id: string;
  name: string;
  note?: string;
  hidden: boolean;
  created_at: string;
  updated_at: string;
  has_access: boolean;
  can_grant_access: boolean;
  has_requested_access: boolean;
  asymmetric_key_public: string;
  recovery: boolean;
}

export interface GroupListFilters {
  search?: string;
  created_at?: { from: string; to: string };
}

export const useGroupStore = defineStore('groups', {
  state: (): GroupState => ({
    groups: [],
    group: null,
  }),

  getters: {},

  actions: {
    async getGroups({
      page,
      filters,
      reset = false,
    }: {
      page: number;
      filters?: GroupListFilters;
      reset?: boolean;
    }): Promise<Group[]> {
      const response = await http.get('/groups', { params: { page, ...filters } });

      if (reset) {
        this.groups = [];
      }

      this.groups.push(...(response.data ?? []));

      return this.groups;
    },

    resetGroups(): void {
      this.groups = [];
    },

    async addGroup({ payload }: { payload: CreateGroupPayload }): Promise<Group> {
      const response = await http.post('/groups', payload);

      this.group = response.data;

      return response.data as Group;
    },

    async getGroup({ id }: { id: string }): Promise<Group> {
      const response = await http.get(`/groups/${id}`);

      this.group = response.data;

      return response.data as Group;
    },

    resetGroup() {
      this.group = null;
    },

    async updateGroup({ id, payload }: { id: string; payload: UpdateGroupForm }): Promise<Group> {
      const response = await http.put(`/groups/${id}`, payload);

      this.group = response.data;

      return response.data as Group;
    },

    async getGroupKey(id: string): Promise<GroupKey> {
      const authUserStore = useAuthUserStore();
      const { authUser } = storeToRefs(authUserStore);

      const response = await http.get(`/groups/${id}/keys`);

      const symKeyBytes = await rsaDecrypt(
        authUser.value!.asymmetric_key_private_decrypted as CryptoKey,
        response.data.symmetric_key as string
      );

      const symKey = await importAesKey(symKeyBytes);

      return {
        symmetric_key: symKey,
        asymmetric_key_private: response.data.asymmetric_key_private,
        asymmetric_key_public: response.data.asymmetric_key_public,
      };
    },

    async requestAccess(id: string) {
      await http.post(`/groups/${id}/request-access`);

      if (this.group === null) {
        return;
      }

      this.group.has_requested_access = true;
    },

    async getRecoveryGroupPublicKey(): Promise<CryptoKey> {
      const response = await http.get('/recovery-group/key');
      return await importPublicKey(response.data.asymmetric_key_public);
    },

    async addUserRecoveryKey(payload: AddUserRecoveryKeyPayload): Promise<void> {
      await http.post('/recovery-group/add-user', payload);
    },

    async deleteGroup(id: string): Promise<void> {
      await http.delete(`/groups/${id}`);
      this.group = null;
    },
  },
});

export type AddGroupForm = {
  name: string;
  note?: string;
  hidden: boolean;
};

export type UpdateGroupForm = {
  name?: string;
  note?: string;
  hidden: boolean;
};

export type CreateGroupPayload = {
  name: string;
  note: string | null;
  hidden: boolean;
  recovery?: boolean;
  symmetric_key: string;
  asymmetric_key_public: string;
  asymmetric_key_private: string;
  group_user_symmetric_key: string;
  group_user_asymmetric_key_public: string;
  group_user_asymmetric_key_private: string;
};

export type GroupKey = {
  symmetric_key: CryptoKey;
  asymmetric_key_public: string;
  asymmetric_key_private: string;
};

export type AddUserRecoveryKeyPayload = {
  user_id: string;
  symmetric_key: string;
  asymmetric_key_private: string;
};
