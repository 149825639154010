<template>
  <q-dialog v-model="isOpen" position="top">
    <q-card class="add-access-dialog">
      <q-card-section class="row items-center q-pb-none">
        <div class="text-h6">Grant Access</div>
        <q-space />
        <q-btn icon="close" flat round dense v-close-popup />
      </q-card-section>

      <q-form @submit="save">
        <q-card-section>
          <div class="row">
            <p>
              <b>{{ userName }}</b> has requested access to the {{ accessRequest?.type }}
              <b>{{ accessRequest?.target_name }}</b
              >.
            </p>
          </div>

          <div class="row" v-if="accessRequest?.type === 'secret'">
            <Field name="selected_access" v-slot="{ errorMessage }">
              <q-select
                :options="options"
                label="Access Method"
                clearable
                v-model="selectedAccess"
                :error-message="errorMessage"
                :error="!!errorMessage"
                class="col"
                use-input
                @filter="filterFn"
                @new-value="newValue"
                :display-value="`${values.selected_access ? values.selected_access.name : ''}`"
              >
                <template
                  v-slot:before-options
                  v-if="hasSearchText && (selectedAccess === null || hasNewGroup)"
                >
                  <q-item class="text-md text-muted" style="min-height: auto !important">
                    <q-item-label>Press Enter to create new group.</q-item-label>
                  </q-item>
                </template>

                <template v-slot:option="scope">
                  <q-item v-if="!scope.opt.group" v-bind="scope.itemProps" :key="scope.opt.name">
                    <q-item-section>
                      <q-item-label>{{ scope.opt.name }}</q-item-label>
                    </q-item-section>
                  </q-item>

                  <q-item
                    v-if="scope.opt.group && checkGroupNotFilteredOut(scope.opt.group)"
                    v-bind="scope.itemProps"
                    :key="scope.opt.group"
                  >
                    <q-item-label class="q-mt-md">{{ scope.opt.group }}</q-item-label>
                  </q-item>
                </template>

                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      <q-item-label>No options available</q-item-label>
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
            </Field>
          </div>

          <div
            v-if="accessRequest?.type === 'secret' && values.selected_access === null"
            class="row"
          >
            <div class="text-md text-muted col">
              Type a non existent group name and press enter to create a new group.
            </div>
          </div>

          <div class="row" v-if="hasNewGroup">
            <div class="text-md text-muted col">
              Create a new group and automatically add this secret.
            </div>
          </div>

          <template v-if="values.selected_access?.type !== 'Tenants'">
            <div class="row q-mt-md">
              <Field name="sharing_permissions" v-slot="{ errorMessage, value, field }">
                <q-checkbox
                  class="col"
                  label="Grant access sharing permissions"
                  :model-value="value"
                  v-bind="field"
                  :error-message="errorMessage"
                  :error="!!errorMessage"
                />
              </Field>
            </div>

            <div class="row">
              <div class="text-sm text-muted col">
                This will allow the selected user to grant access to other users.
              </div>
            </div>
          </template>

          <div class="row q-mt-md">
            <div class="col">
              <div class="col-auto">
                <q-btn
                  class="text-right"
                  color="negative"
                  label="Decline Access"
                  type="button"
                  @click="declineAccess"
                  :loading="isDeclineButtonLoading"
                />
              </div>
            </div>

            <div class="col-auto">
              <q-btn
                class="text-right"
                color="positive"
                label="Grant Access"
                type="submit"
                :loading="isGrantButtonLoading"
              />
            </div>
          </div>
        </q-card-section>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script lang="ts" setup>
import { Field, useForm } from 'vee-validate';
import { computed, onBeforeUnmount, ref, watch } from 'vue';
import * as yup from 'yup';
import {
  useAccessRequestStore,
  type AccessRequestOption,
  type AccessRequestOptionGroup,
} from '@/stores/access-request';
import { storeToRefs } from 'pinia';
import { AxiosError } from 'axios';
import { useToast } from 'vue-toast-notification';
import useAddGroupAccess from '@/composable/group/add-access';
import useAddSecretAccess from '@/composable/secret/add-access';

const props = defineProps<{
  isOpen: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:isOpen', value: boolean): void;
  (e: 'refresh-list'): void;
}>();

const isOpen = computed({
  get() {
    return props.isOpen;
  },

  set(value: boolean) {
    emit('update:isOpen', value);
  },
});

const toast = useToast();
const { addAccess: addGroupAccess } = useAddGroupAccess();
const { addAccess: addSecretAccess } = useAddSecretAccess();

const options = ref<(AccessRequestOption | AccessRequestOptionGroup)[]>([]);
const isGrantButtonLoading = ref<boolean>(false);
const isDeclineButtonLoading = ref<boolean>(false);
const hasSearchText = ref<boolean>(false);

const accessRequestStore = useAccessRequestStore();
const { accessRequest, accessRequestOptions } = storeToRefs(accessRequestStore);

watch(isOpen, async (value) => {
  if (value === false) {
    setErrors({});
    accessRequestStore.resetAccessRequest();
    accessRequestStore.resetAccessRequest();
    options.value = [];
    return;
  }

  await accessRequestStore.getAccessRequestOptions(accessRequest.value!.id);
  options.value = accessRequestOptions.value;
});

const userName = computed(() => {
  return `${accessRequest.value?.user_first_name} ${accessRequest.value?.user_last_name}`;
});

interface GrantAccessRequestForm {
  selected_access: AccessRequestOption | null;
  sharing_permissions: boolean;
}

const { handleSubmit, setValues, setErrors, values } = useForm<GrantAccessRequestForm>({
  validationSchema: yup.object({
    selected_access: yup.object().required().nullable().label('Access'),
    sharing_permissions: yup.boolean().nullable().label('Sharing permission'),
  }),
  initialValues: {
    selected_access: null,
    sharing_permissions: false,
  },
});

const save = handleSubmit(async (payload) => {
  if (accessRequest.value === null) {
    return;
  }

  try {
    isGrantButtonLoading.value = true;

    if (accessRequest.value?.type === 'group') {
      await addGroupAccess({
        groupID: accessRequest.value!.target_id as string,
        accessOpt: {
          id: accessRequest.value?.user_id,
          name: '',
        },
        sharingPerms: payload.sharing_permissions,
      });

      isOpen.value = false;
      accessRequestStore.resetAccessRequest();
      return;
    }

    if (selectedAccess.value?.type === 'Groups' && selectedAccess.value.target_has_secret_access) {
      return;
    }

    const newGroupID = await addSecretAccess({
      secretID: accessRequest.value!.target_id as string,
      accessOpt: payload.selected_access!,
      sharingPerms: payload.sharing_permissions,
      accessRequestUserID: accessRequest.value?.user_id,
    });

    if (
      (selectedAccess.value?.type === 'Groups' &&
        selectedAccess.value.has_target_access === false) ||
      selectedAccess.value?.type === 'New Group'
    ) {
      await addGroupAccess({
        groupID: newGroupID ?? (selectedAccess.value.id as string),
        accessOpt: {
          id: accessRequest.value?.user_id,
          name: '',
        },
        sharingPerms: payload.sharing_permissions,
      });
    }

    isOpen.value = false;
    accessRequestStore.resetAccessRequest();
  } catch (err) {
    if (err instanceof AxiosError) {
      setErrors(err.response?.data);
      return;
    }

    toast.error('Something went wrong, please try again later.');

    throw err;
  } finally {
    isGrantButtonLoading.value = false;
    emit('refresh-list');
  }
});

const declineAccess = async () => {
  isDeclineButtonLoading.value = true;
  try {
    await accessRequestStore.declineAccessRequest(accessRequest.value!.id);
    isOpen.value = false;
    accessRequestStore.resetAccessRequest();
  } catch (err) {
    toast.error('Something went wrong, please try again later.');
  } finally {
    isDeclineButtonLoading.value = false;
    emit('refresh-list');
  }
};

const selectedAccess = computed({
  get() {
    return values.selected_access;
  },

  set(value: AccessRequestOption | null) {
    setValues({
      selected_access: value,
    });
  },
});

const hasNewGroup = computed(() => {
  return values.selected_access?.id === null;
});

const newValue = (val: string, done: (val: any, mode: 'add' | 'add-unique') => void) => {
  done({ id: null, name: val, type: 'New Group' }, 'add');
};

function filterFn(val: string, update: (fn: () => void) => void) {
  hasSearchText.value = val !== '';

  if (val === '') {
    update(() => {
      options.value = accessRequestOptions.value;
    });
    return;
  }

  update(() => {
    const needle = val.toLowerCase();

    options.value = accessRequestOptions.value.filter(
      (t: AccessRequestOption | AccessRequestOptionGroup) => {
        if (!t.name) {
          return true;
        }

        return t.name.toLowerCase().indexOf(needle) > -1;
      }
    );
  });
}

function checkGroupNotFilteredOut(groupName: string) {
  return options.value.filter((o) => (o as AccessRequestOption).type === groupName).length > 0;
}

onBeforeUnmount(() => {
  setErrors({});
  accessRequestStore.resetAccessRequest();
});
</script>
