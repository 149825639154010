import { ref } from 'vue';
import { Dialog } from 'quasar';

type DialogParams = {
  title: string;
  message: string;
  options: DialogOptions;
};

type DialogOptions = {
  okLabel?: string;
  cancelLabel?: string;
};

export function useConfirmationDialog() {
  const isDialogOpen = ref(false);

  const confirm = async (params: DialogParams) => {
    return new Promise((resolve) => {
      Dialog.create({
        title: params.title,
        message: params.message,
        persistent: true,
        ...params.options,
        ok: {
          label: params.options.okLabel || 'Confirm',
          flat: true,
          color: 'negative',
        },
        cancel: {
          label: params.options.cancelLabel || 'Cancel',
          flat: true,
          color: 'black',
        },
      })
        .onOk(() => {
          isDialogOpen.value = false;
          resolve(true);
        })
        .onCancel(() => {
          isDialogOpen.value = false;
          resolve(false);
        })
        .onDismiss(() => {
          isDialogOpen.value = false;
          resolve(false);
        });
    });
  };

  return { isDialogOpen, confirm };
}
