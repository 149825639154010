<template>
  <RouterView v-if="authUser?.id" v-slot="{ Component }: any">
    <component :is="Component">
      <template #tabs>
        <q-tabs align="left">
          <q-route-tab :to="{ name: 'account.details' }" label="Details" />
          <q-route-tab :to="{ name: 'account.security' }" label="Security" />
        </q-tabs>
      </template>
    </component>
  </RouterView>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useAuthUserStore } from '@/stores/auth-user';

const authUserStore = useAuthUserStore();
const { authUser } = storeToRefs(authUserStore);

if (!authUser?.value) {
  throw new Error('User is undefined.');
}
</script>
