<template>
  <div class="q-breadcrumbs__separator">/</div>
  <q-breadcrumbs-el
    :label="name"
    @click="() => emit('change-parent', file)"
    class="secret-file-path-item"
  />
</template>

<script lang="ts" setup>
import rsaDecryptField from '@/composable/secret/rsa-decrypt-text';
import { useSecretStore } from '@/stores/secret';
import type { SecretFile } from '@/stores/secret/files';
import { storeToRefs } from 'pinia';
import { onMounted, toRefs, ref } from 'vue';

const props = defineProps<{
  file: SecretFile;
}>();

const emit = defineEmits<{
  (e: 'change-parent', value: SecretFile): void;
}>();

const { file } = toRefs(props);

const name = ref<string>('');

const secretStore = useSecretStore();
const { secret, secretPrivateKey } = storeToRefs(secretStore);

onMounted(async () => {
  if (!secretPrivateKey.value) {
    await secretStore.getSecretKey(secret.value!.id);
  }

  if (!secretPrivateKey.value) {
    throw new Error('No secret private key');
  }

  const nameDecrypted = await rsaDecryptField(secretPrivateKey.value, file.value.name);
  if (nameDecrypted) {
    name.value = nameDecrypted;
  }
});
</script>
