<template>
  <AppContainer :title="title">
    <template #buttons>
      <HelpPopup page="secrets.access" />

      <q-btn
        v-if="secret?.can_grant_access"
        type="button"
        color="primary"
        icon="add"
        @click="() => (isAddAccessDialogOpen = !isAddAccessDialogOpen)"
        round
        dense
      />

      <q-btn
        type="button"
        color="primary"
        icon="search"
        @click="() => (isFiltersOpen = !isFiltersOpen)"
        round
        dense
      />
    </template>

    <template #tabs>
      <slot name="tabs" />
    </template>

    <template #drawerRight>
      <FilterDrawer v-model:is-open="isFiltersOpen" v-model:filters="filters" />
    </template>

    <q-page-container>
      <q-page class="q-pa-md">
        <div class="row justify-center">
          <q-list class="q-layout q-pa-md">
            <q-infinite-scroll @load="onLoad" :offset="250" ref="infiniteScroll">
              <template v-slot:loading>
                <div class="row justify-center q-my-md">
                  <q-spinner-dots color="primary" size="40px" />
                </div>
              </template>

              <div v-for="access in secretAccess" :key="access.id">
                <ListItem :secret-access="access" @refresh="refreshList" />
              </div>

              <q-item v-if="secretAccess.length < 1" class="text-center">
                <q-item-section>
                  <q-item-label>No results available.</q-item-label>
                </q-item-section>
              </q-item>
            </q-infinite-scroll>
          </q-list>
        </div>
      </q-page>
    </q-page-container>

    <AddSecretAccessDialog v-model:is-open="isAddAccessDialogOpen" />
  </AppContainer>
</template>

<script lang="ts" setup>
import { ref, watch, onMounted, onBeforeUnmount, computed } from 'vue';
import { useSecretStore } from '@/stores/secret';
import { useSecretAccessStore, type SecretAccessListFilters } from '@/stores/secret/access';
import type { QInfiniteScroll } from 'quasar';
import { storeToRefs } from 'pinia';
import ListItem from '@/components/secrets/access/ListItem.vue';
import FilterDrawer from '@/components/secrets/access/FilterDrawer.vue';
import AddSecretAccessDialog from '@/components/secrets/access/AddSecretAccessDialog.vue';

const infiniteScroll = ref<QInfiniteScroll | null>(null);

const isFiltersOpen = ref(false);
const isAddAccessDialogOpen = ref(false);

const filters = ref<SecretAccessListFilters>({});

const secretStore = useSecretStore();
const { secret } = storeToRefs(secretStore);

const secretAccessStore = useSecretAccessStore();
const { secretAccess } = storeToRefs(secretAccessStore);

watch(
  filters,
  async () => {
    if (!infiniteScroll.value) {
      return;
    }

    const response = await secretAccessStore.getSecretAccess({
      secretID: secret.value!.id,
      page: 1,
      filters: filters.value,
      reset: true,
    });
    infiniteScroll.value.setIndex(1);
    if (response.length < 50) {
      infiniteScroll.value.stop();
    }
  },
  { deep: true }
);

const onLoad = async (index: number, done: (stop: boolean) => void) => {
  const response = await secretAccessStore.getSecretAccess({
    secretID: secret.value!.id,
    page: index,
    filters: filters.value,
    reset: false,
  });
  done(response.length < 50);
};

async function refreshList() {
  if (!infiniteScroll.value) {
    return;
  }

  const response = await secretAccessStore.getSecretAccess({
    secretID: secret.value!.id,
    page: 1,
    filters: filters.value,
    reset: true,
  });
  infiniteScroll.value.setIndex(1);
  if (response.length < 50) {
    infiniteScroll.value.stop();
  }
}

onMounted(() => {
  if (!infiniteScroll.value) {
    return;
  }

  infiniteScroll.value.trigger();
});

onBeforeUnmount(() => {
  if (infiniteScroll.value) {
    infiniteScroll.value.reset();
  }

  secretAccessStore.resetSecretAccess();
});

const title = computed(() => `${secret.value?.name} - Access`);
</script>
