<template>
  <div class="subscription-card">
    <div class="row">
      <div class="col">
        <h4 class="q-my-sm">Subscription</h4>
      </div>
      <div class="col-auto" v-if="isTrialing">
        <q-badge color="positive">Trialing</q-badge>
      </div>
    </div>

    <q-banner v-if="isCanceled" inline-actions class="text-white bg-red q-my-md">
      Your subscription has been cancelled, please resubscribe before {{ cancelAt }} to prevent
      loosing access.
      <template v-slot:action>
        <q-btn
          flat
          color="white"
          label="Resubscribe"
          @click="resubscribeTenantSubscription"
          :loading="isResubscribeLoading"
        />
      </template>
    </q-banner>

    <div class="row">
      <div class="col">
        <b>Current Subscription:</b>
      </div>

      <div class="col-auto">
        <p>{{ tenant?.stripe_package_name }} Subscription</p>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <b>Price:</b>
      </div>

      <div class="col-auto">
        <p>{{ tenant?.stripe_package_price }} / month</p>
      </div>
    </div>

    <div class="row">
      <div class="col" v-if="isTrialing">
        <b>Trial Ends At: </b>
      </div>

      <div class="col" v-else>
        <b>Next Payment: </b>
      </div>

      <div class="col-auto">
        <p>{{ nextPaymentAt }}</p>
      </div>
    </div>

    <div class="row" v-if="isCanceled">
      <div class="col">
        <b>Ending At: </b>
      </div>

      <div class="col-auto">
        <p>{{ cancelAt }}</p>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <b>Users: </b>
      </div>

      <div class="col-auto">
        <p>{{ usersLimit }}</p>
      </div>
    </div>

    <div class="row q-mt-sm q-mb-md">
      <div class="col" />
      <div class="col-auto q-gutter-sm" v-if="!isCanceled">
        <q-btn
          color="negative"
          label="Cancel"
          @click="cancelTenantSubscription"
          :loading="isCancelLoading"
        />

        <q-btn
          color="primary"
          label="Change Subscription Tier"
          :to="{ name: 'tenant.subscription.change' }"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useTenantStore } from '@/stores/tenant';
import { storeToRefs } from 'pinia';
import { useConfirmationDialog } from '@/composable/form/use-confirmation-dialog';
import dayjs from 'dayjs';
import formatDatetime from '@/composable/format-datetime';

const isCancelLoading = ref(false);
const isResubscribeLoading = ref(false);

const tenantStore = useTenantStore();
const { tenant } = storeToRefs(tenantStore);

const confirmationDialog = useConfirmationDialog();

const isCanceled = computed(() => {
  return tenant.value?.stripe_subscription_cancel_at !== null;
});

const isTrialing = computed(() => {
  return tenant.value?.stripe_subscription_status === 'trialing';
});

const usersLimit = computed(() => {
  if (!tenant?.value) {
    return null;
  }

  if (tenant.value.stripe_package_max_users === 0) {
    return `${tenant.value.user_count}`;
  }

  return `${tenant.value.user_count} / ${tenant.value.stripe_package_max_users}`;
});

const nextPaymentAt = formatDatetime(
  tenant.value?.stripe_subscription_current_period_end,
  'DD/MM/YYYY'
);

const cancelAt = formatDatetime(tenant.value?.stripe_subscription_cancel_at, 'DD/MM/YYYY');

async function cancelTenantSubscription() {
  if (
    !(await confirmationDialog.confirm({
      title: 'Cancel Subscription',
      message: 'Are you sure you want to cancel your subscription?',
      options: {
        okLabel: 'Cancel Subscription',
        cancelLabel: 'Abort',
      },
    }))
  ) {
    return;
  }

  try {
    isCancelLoading.value = true;
    await tenantStore.cancelSubscription();
    if (tenant.value) {
      tenant.value.stripe_subscription_cancel_at = dayjs()
        .add(1, 'month')
        .format('YYYY-MM-DD HH:mm:ss');
    }
  } finally {
    isCancelLoading.value = false;
  }
}

async function resubscribeTenantSubscription() {
  if (
    !(await confirmationDialog.confirm({
      title: 'Resubscribe',
      message: 'Are you sure you want to resubscribe to your subscription?',
      options: {
        okLabel: 'Resubscribe',
        cancelLabel: 'Abort',
      },
    }))
  ) {
    return;
  }

  try {
    isResubscribeLoading.value = true;
    await tenantStore.resubscribeSubscription();
    if (tenant.value) {
      tenant.value.stripe_subscription_cancel_at = null;
    }
  } finally {
    isResubscribeLoading.value = false;
  }
}
</script>

<style scoped>
.text-h4,
.q-badge {
  vertical-align: middle;
}

.q-icon {
  font-size: 1rem;
}
</style>
