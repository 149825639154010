<template>
  <AppContainer :title="title">
    <template #buttons>
      <HelpPopup page="groups.access" />

      <q-btn
        v-if="group?.can_grant_access"
        type="button"
        color="primary"
        icon="add"
        @click="() => (isAddAccessDialogOpen = !isAddAccessDialogOpen)"
        round
        dense
      />

      <q-btn
        type="button"
        color="primary"
        icon="search"
        @click="() => (isFiltersOpen = !isFiltersOpen)"
        round
        dense
      />
    </template>

    <template #tabs>
      <slot name="tabs" />
    </template>

    <template #drawerRight>
      <FilterDrawer v-model:is-open="isFiltersOpen" v-model:filters="filters" />
    </template>

    <q-page-container>
      <q-page class="q-pa-md">
        <div class="row justify-center">
          <q-list class="q-layout q-pa-md">
            <q-infinite-scroll @load="onLoad" :offset="250" ref="infiniteScroll">
              <template v-slot:loading>
                <div class="row justify-center q-my-md">
                  <q-spinner-dots color="primary" size="40px" />
                </div>
              </template>

              <div v-for="access in groupAccess" :key="access.id">
                <ListItem :group-access="access" @refresh="refreshList" />
              </div>

              <q-item v-if="groupAccess.length < 1" class="text-center">
                <q-item-section>
                  <q-item-label>No results available.</q-item-label>
                </q-item-section>
              </q-item>
            </q-infinite-scroll>
          </q-list>
        </div>
      </q-page>
    </q-page-container>

    <AddGroupAccessDialog v-model:is-open="isAddAccessDialogOpen" />
  </AppContainer>
</template>

<script lang="ts" setup>
import { ref, watch, onMounted, onBeforeUnmount, computed } from 'vue';
import { useGroupStore } from '@/stores/group';
import { useGroupAccessStore, type GroupAccessListFilters } from '@/stores/group/access';
import type { QInfiniteScroll } from 'quasar';
import { storeToRefs } from 'pinia';
import ListItem from '@/components/groups/access/ListItem.vue';
import FilterDrawer from '@/components/groups/access/FilterDrawer.vue';
import AddGroupAccessDialog from '@/components/groups/access/AddGroupAccessDialog.vue';

const infiniteScroll = ref<QInfiniteScroll | null>(null);

const isFiltersOpen = ref(false);
const isAddAccessDialogOpen = ref(false);

const filters = ref<GroupAccessListFilters>({});

const groupStore = useGroupStore();
const { group } = storeToRefs(groupStore);

const groupAccessStore = useGroupAccessStore();
const { groupAccess } = storeToRefs(groupAccessStore);

watch(
  filters,
  async () => {
    if (!infiniteScroll.value) {
      return;
    }

    const response = await groupAccessStore.getGroupAccess({
      groupID: group.value!.id,
      page: 1,
      filters: filters.value,
      reset: true,
    });
    infiniteScroll.value.setIndex(1);
    if (response.length < 50) {
      infiniteScroll.value.stop();
    }
  },
  { deep: true }
);

const onLoad = async (index: number, done: (stop: boolean) => void) => {
  const response = await groupAccessStore.getGroupAccess({
    groupID: group.value!.id,
    page: index,
    filters: filters.value,
    reset: false,
  });
  done(response.length < 50);
};

async function refreshList() {
  if (!infiniteScroll.value) {
    return;
  }

  const response = await groupAccessStore.getGroupAccess({
    groupID: group.value!.id,
    page: 1,
    filters: filters.value,
    reset: true,
  });
  infiniteScroll.value.setIndex(1);
  if (response.length < 50) {
    infiniteScroll.value.stop();
  }
}

onMounted(() => {
  if (!infiniteScroll.value) {
    return;
  }

  infiniteScroll.value.trigger();
});

onBeforeUnmount(() => {
  if (infiniteScroll.value) {
    infiniteScroll.value.reset();
  }

  groupAccessStore.resetGroupAccess();
});

const title = computed(() => `${group.value?.name} - Access`);
</script>
