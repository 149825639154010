<template>
  <q-dialog v-model="isOpen" position="top">
    <q-card class="change-subscription">
      <q-card-section class="row items-center q-pb-none">
        <div class="text-h6">Add Payment Method</div>
        <q-space />
        <q-btn icon="close" flat round dense @click="isOpen = false" />
      </q-card-section>

      <q-form @submit="submit">
        <q-card-section>
          <AddCard @tokenize="(t: () => Promise<PaymentMethod>) => (tokenize = t)" />

          <div class="row q-mt-md">
            <div class="col"></div>

            <div class="col-auto">
              <q-btn
                class="text-right"
                color="primary"
                label="Change Subscription"
                type="submit"
                :loading="isLoading"
              />
            </div>
          </div>
        </q-card-section>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useTenantStore } from '@/stores/tenant';
import { useSubscriptionStore } from '@/stores/subscription';
import { storeToRefs } from 'pinia';
import { useToast } from 'vue-toast-notification';
import type { PaymentMethod } from '@stripe/stripe-js';
import AddCard from '@/components/subscriptions/AddCard.vue';
import { useAuthUserStore } from '@/stores/auth-user';

const isLoading = ref(false);
const tokenize = ref<() => Promise<PaymentMethod> | null>();

const props = defineProps<{
  isOpen: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:isOpen', value: boolean): void;
}>();

const isOpen = computed({
  get() {
    return props.isOpen;
  },

  set(value: boolean) {
    emit('update:isOpen', value);
  },
});

const authUserStore = useAuthUserStore();
const tenantStore = useTenantStore();
const subscriptionStore = useSubscriptionStore();

const { tenant } = storeToRefs(tenantStore);

const toast = useToast();

async function submit() {
  if (!tokenize.value) {
    toast.error('An error occurred while processing your payment. Please try again.');
    return;
  }

  try {
    isLoading.value = true;

    const paymentMethod = await tokenize.value();

    if (!paymentMethod) {
      toast.error('An error occurred while processing your payment. Please try again.');
      return;
    }

    await subscriptionStore.subscribe({
      stripe_package_id: tenant.value!.stripe_package_id,
      use_existing_package: true,
      payment_method: paymentMethod.id,
    });

    await Promise.all([authUserStore.authorize(), tenantStore.getTenant()]);

    toast.success('Payment method updated successfully.');

    isLoading.value = false;
    isOpen.value = false;
  } catch (error) {
    toast.error('An error occurred while processing your payment. Please try again.');
    isLoading.value = false;
  }
}
</script>
