import { defineStore } from 'pinia';
import http from '@/utils/http';

export interface RoleState {
  roles: Role[];
}

export interface Role {
  id: string;
  name: string;
}

export const useRolesStore = defineStore('roles', {
  state: (): RoleState => ({
    roles: [],
  }),

  getters: {},

  actions: {
    async getRoles({ reset = false }: { reset?: boolean }): Promise<Role[]> {
      if (reset) {
        this.roles = [];
      }

      if (this.roles.length > 0) {
        return this.roles;
      }

      const response = await http.get('/roles');

      this.roles = response.data;

      return this.roles;
    },
  },
});
