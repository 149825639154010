import type { UpdateSecretForm, UpdateSecretPayload } from '@/stores/secret';
import aesEncryptText from './aes-encrypt-text';
import { generateSaltedHash, decryptUrlSalt, parseUrlForHash } from '@/composable/url-hash';
import getSecretSymmetricKey from './get-secret-symmetric-key';

export default async function encryptSecret(
  id: string,
  secretForm: UpdateSecretForm
): Promise<UpdateSecretPayload> {
  const publicKey = await getSecretSymmetricKey(id);

  const updateSecretPayload: UpdateSecretPayload = {
    name: secretForm.name,
    username: await aesEncryptText(publicKey, secretForm.username),
    secret: (await aesEncryptText(publicKey, secretForm.secret)) as string,
    otp_secret: await aesEncryptText(publicKey, secretForm.otp_secret),
    url: await aesEncryptText(publicKey, secretForm.url),
    note: await aesEncryptText(publicKey, secretForm.note),
    hidden: secretForm.hidden,
    score: await aesEncryptText(publicKey, secretForm.score?.toString()),
    score_current: secretForm.score,
    score_previous: secretForm.score_previous,
  };

  const urlSalt = await decryptUrlSalt();
  if (secretForm.url && urlSalt) {
    updateSecretPayload.url_hash = await generateSaltedHash(
      parseUrlForHash(secretForm.url),
      urlSalt
    );
  }

  return updateSecretPayload;
}
