import { defineStore, storeToRefs } from 'pinia';
import http from '@/utils/http';
import { v4 as uuidv4 } from 'uuid';
import { useSecretStore } from '../secret';
import rsaDecryptField from '@/composable/secret/rsa-decrypt-text';

export type SecretFileState = {
  secretFiles: SecretFile[];
  secretFilePath: SecretFile[];
  currentParentID: string | null;
};

export type SecretFile = {
  id: string;
  type: string;
  secret_id: string;
  name: string;
  nameDecrypted?: string;
  extension?: string;
  size: number;
  metadata?: string;
  signed_url?: string;
  left: number;
  right: number;
  parent_id: string;
  created_at: string;
  updated_at: string;
};

export type SecretFileListFilters = {};

export function mapFileToUploadingFile(file: File): UploadingFile {
  return {
    id: uuidv4(),
    file,
    encrypting: false,
    finished: false,
    uploading: false,
    progress: 0,
    failed: false,
  };
}

export const useSecretFileStore = defineStore('secretFile', {
  state: (): SecretFileState => ({
    secretFiles: [],
    secretFilePath: [],
    currentParentID: null,
  }),

  getters: {},

  actions: {
    async getSecretFiles({
      secretID,
      page,
      filters,
      reset = false,
    }: {
      secretID: string;
      page: number;
      filters: SecretFileListFilters;
      reset: boolean;
    }) {
      const secretStore = useSecretStore();
      const { secret, secretPrivateKey } = storeToRefs(secretStore);

      if (!secretPrivateKey.value) {
        await secretStore.getSecretKey(secret.value!.id);
      }

      const response = await http.get(`/secrets/${secretID}/files`, {
        params: {
          page,
          parent_id: this.currentParentID,
          ...filters,
        },
      });

      this.secretFilePath = [];

      if (reset) {
        this.secretFiles = [];
      }

      this.secretFilePath.push(...(response.data?.path ?? []));

      // Decrypt the names of the files and folders as we get them.
      for (let i = 0; i < response.data.contents.length; i++) {
        const item = response.data.contents[i];

        this.secretFiles.push({
          ...item,
          nameDecrypted: await rsaDecryptField(secretPrivateKey.value!, item.name),
        });
      }

      return this.secretFiles;
    },

    resetSecretFiles() {
      this.secretFiles = [];
    },

    async createSecretFileDirectory(secretID: string, payload: SecretFileDirectoryCreateForm) {
      await http.post(`/secrets/${secretID}/files/directory`, payload);
    },

    async deleteSecretFileDirectory(secretID: string, fileID: string) {
      await http.delete(`/secrets/${secretID}/files/${fileID}`);

      this.secretFiles = this.secretFiles.filter((file) => file.id !== fileID);
    },
  },
});

export type SecretFileDirectoryCreateForm = {
  name: string;
  parent_id: string | null;
};

export type UploadingFile = {
  id: string;
  file: File;
  nameCiphertext?: string;
  fileContentsCiphertext?: ArrayBuffer;
  encrypting: boolean;
  finished: boolean;
  uploading: boolean;
  progress: number;
  failed: boolean;
  error?: string;
  abortController?: AbortController;
};
