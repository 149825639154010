import { useGroupStore } from '@/stores/group';
import { useSecretStore } from '@/stores/secret';
import { useSecretAccessStore, type SecretAccessOption } from '@/stores/secret/access';
import {
  exportPrivateKey,
  exportPublicKey,
  importPublicKey,
  rsaEncrypt,
} from '@/utils/encryption/asymmetric';
import { aesEncrypt, exportAesKey } from '@/utils/encryption/symmetric';
import { stringToBytes } from '@/utils/encryption/utils';
import http from '@/utils/http';
import { storeToRefs } from 'pinia';
import useGroupEncryption from '@/composable/group';
import { useAuthUserStore } from '@/stores/auth-user';

export default function useAddSecretAccess() {
  const secretAccessStore = useSecretAccessStore();
  let secretID: string;
  let accessOpt: SecretAccessOption;
  let sharingPerms: boolean | undefined;
  let accessRequestUserID: string | undefined;

  const addAccess = async (params: {
    secretID: string;
    accessOpt: SecretAccessOption;
    sharingPerms?: boolean;
    accessRequestUserID?: string;
  }): Promise<string | null> => {
    secretID = params.secretID;
    accessOpt = params.accessOpt;
    sharingPerms = params.sharingPerms;
    accessRequestUserID = params.accessRequestUserID;

    if (accessOpt.type === 'New Group') {
      return await addNewGroup();
    }

    if (accessOpt.id === null) {
      throw new Error('Cannot add access with null id');
    }

    if (accessOpt.type === 'Users' || accessOpt.type === 'User') {
      return await addUser();
    }

    if (accessOpt.type === 'Groups') {
      return await addGroup();
    }

    if (accessOpt.type === 'Tenants') {
      return await addTenant();
    }

    throw new Error('Unknown access method provided');
  };

  const getUserPublicKey = async (): Promise<CryptoKey> => {
    const response = await http.get(`/users/${accessOpt.id}/public-key`);

    const userPublicKey = await importPublicKey(response.data);

    return userPublicKey;
  };

  const getSecretKey = async (): Promise<Uint8Array> => {
    const secretStore = useSecretStore();
    const secretKey = await secretStore.getSecretKey(secretID);

    if (secretKey === null) {
      throw new Error('Cannot get secret key');
    }

    const symKeyExported = await exportAesKey(secretKey.symmetric_key);

    return symKeyExported;
  };

  const getGroupPublicKey = async (): Promise<CryptoKey> => {
    if (accessOpt.id === null) {
      throw new Error('Cannot add group with null id');
    }

    const groupStore = useGroupStore();
    const { group } = storeToRefs(groupStore);

    await groupStore.getGroup({ id: accessOpt.id });

    if (group.value === null) {
      throw new Error('Cannot get group public key key');
    }

    return await importPublicKey(group.value?.asymmetric_key_public);
  };

  const addUser = async (): Promise<null> => {
    const publicKey = await getUserPublicKey();
    const aesKeyBytes = await getSecretKey();

    const secretStore = useSecretStore();
    const secretKey = await secretStore.getSecretKey(secretID);

    if (
      secretKey === null ||
      secretKey.asymmetric_key_public === null ||
      secretKey.asymmetric_key_private === null
    ) {
      throw new Error('Secret Key not set');
    }

    const privateKeyExported = await exportPrivateKey(secretKey.asymmetric_key_private);

    const privateKeyEncrypted = await aesEncrypt(
      secretKey.symmetric_key,
      stringToBytes(privateKeyExported)
    );

    const publicKeyExported = await exportPublicKey(secretKey.asymmetric_key_public);

    const aesKeyEncrypted = await rsaEncrypt(publicKey, aesKeyBytes);

    await secretAccessStore.addUserAccess(secretID, {
      user_id: accessOpt.id!,
      symmetric_key: aesKeyEncrypted,
      asymmetric_key_public: publicKeyExported,
      asymmetric_key_private: privateKeyEncrypted,
      can_grant_access: sharingPerms ?? false,
    });

    return null;
  };

  const addGroup = async (): Promise<null> => {
    const publicKey = await getGroupPublicKey();
    const aesKeyBytes = await getSecretKey();

    const secretStore = useSecretStore();
    const secretKey = await secretStore.getSecretKey(secretID);

    if (
      secretKey === null ||
      secretKey.asymmetric_key_public === null ||
      secretKey.asymmetric_key_private === null
    ) {
      throw new Error('Secret Key not set');
    }

    const privateKeyExported = await exportPrivateKey(secretKey.asymmetric_key_private);

    const privateKeyEncrypted = await aesEncrypt(
      secretKey.symmetric_key,
      stringToBytes(privateKeyExported)
    );

    const publicKeyExported = await exportPublicKey(secretKey.asymmetric_key_public);

    const aesKeyEncrypted = await rsaEncrypt(publicKey, aesKeyBytes);

    await secretAccessStore.addGroupAccess(secretID, {
      group_id: accessOpt.id!,
      symmetric_key: aesKeyEncrypted,
      asymmetric_key_public: publicKeyExported,
      asymmetric_key_private: privateKeyEncrypted,
      can_grant_access: sharingPerms ?? false,
      access_request_user_id: accessRequestUserID,
    });

    return null;
  };

  const addNewGroup = async (): Promise<string> => {
    const { createGroup } = useGroupEncryption();
    const groupPayload = await createGroup({
      name: accessOpt.name!,
      hidden: false,
    });

    const secretStore = useSecretStore();
    const secretKey = await secretStore.getSecretKey(secretID);

    if (
      secretKey === null ||
      secretKey.asymmetric_key_public === null ||
      secretKey.asymmetric_key_private === null
    ) {
      throw new Error('Secret Key not set');
    }

    const privateKeyExported = await exportPrivateKey(secretKey.asymmetric_key_private);

    const privateKeyEncrypted = await aesEncrypt(
      secretKey.symmetric_key,
      stringToBytes(privateKeyExported)
    );

    const publicKeyExported = await exportPublicKey(secretKey.asymmetric_key_public);

    const groupAsymmetricPublicKey = await importPublicKey(groupPayload.asymmetric_key_public);

    const aesKeyEncrypted = await rsaEncrypt(
      groupAsymmetricPublicKey,
      await exportAesKey(secretKey.symmetric_key)
    );

    const newGroupID = await secretAccessStore.addNewGroupAccess(secretID, {
      group: groupPayload,
      group_secret: {
        symmetric_key: aesKeyEncrypted,
        asymmetric_key_public: publicKeyExported,
        asymmetric_key_private: privateKeyEncrypted,
        can_grant_access: sharingPerms ?? false,
        access_request_user_id: accessRequestUserID,
      },
    });

    return newGroupID;
  };

  const addTenant = async (): Promise<null> => {
    const authUserStore = useAuthUserStore();
    const aesKeyBytes = await getSecretKey();

    const secretStore = useSecretStore();
    const secretKey = await secretStore.getSecretKey(secretID);

    if (
      secretKey === null ||
      secretKey.asymmetric_key_public === null ||
      secretKey.asymmetric_key_private === null
    ) {
      throw new Error('Secret Key not set');
    }

    const privateKeyExported = await exportPrivateKey(secretKey.asymmetric_key_private);

    const privateKeyEncrypted = await aesEncrypt(
      secretKey.symmetric_key,
      stringToBytes(privateKeyExported)
    );

    const publicKeyExported = await exportPublicKey(secretKey.asymmetric_key_public);

    const aesKeyEncrypted = await rsaEncrypt(await authUserStore.tenantPublicKey, aesKeyBytes);

    await secretAccessStore.addTenantAccess(secretID, {
      tenant_id: accessOpt.id!,
      symmetric_key: aesKeyEncrypted,
      asymmetric_key_public: publicKeyExported,
      asymmetric_key_private: privateKeyEncrypted,
      can_grant_access: sharingPerms ?? false,
      access_request_user_id: accessRequestUserID,
    });

    return null;
  };

  return {
    addAccess,
  };
}
