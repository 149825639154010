<template>
  <AppContainer :title="title">
    <template #buttons>
      <HelpPopup page="users.details" />
    </template>

    <template #tabs>
      <slot name="tabs" />
    </template>

    <q-page-container>
      <q-page class="q-pa-md">
        <div class="row justify-center">
          <q-form class="q-layout col-12 col-lg-6 q-pa-md" @submit="save">
            <div class="row q-mb-sm">
              <Field name="first_name" v-slot="{ errorMessage, value, field }">
                <q-input
                  class="q-px-sm col"
                  label="First Name"
                  :model-value="value"
                  v-bind="field"
                  :error-message="errorMessage"
                  :error="!!errorMessage"
                  :disable="cannotWriteUsers"
                />
              </Field>
            </div>

            <div class="row q-mb-sm">
              <Field name="last_name" v-slot="{ errorMessage, value, field }">
                <q-input
                  class="q-px-sm col"
                  label="Last Name"
                  :model-value="value"
                  v-bind="field"
                  :error-message="errorMessage"
                  :error="!!errorMessage"
                  :disable="cannotWriteUsers"
                />
              </Field>
            </div>

            <div class="row q-mb-sm">
              <Field name="email" v-slot="{ errorMessage, value, field }">
                <q-input
                  class="q-px-sm col-12"
                  label="Email"
                  :model-value="value"
                  v-bind="field"
                  :error-message="errorMessage"
                  :error="!!errorMessage"
                  :disable="cannotWriteUsers"
                />
              </Field>

              <p v-if="user?.new_email" class="col-12 q-pl-sm q-mb-md text-grey-7 text-sm">
                {{ user?.new_email }} will be set on email verification.
              </p>
            </div>

            <div class="row q-mb-sm">
              <Field name="timezone" v-slot="{ errorMessage, value, field }">
                <TimezoneSelect
                  class="q-px-sm col"
                  label="Timezone"
                  :model-value="value"
                  v-bind="field"
                  :error-message="errorMessage"
                  :error="!!errorMessage"
                  :disable="cannotWriteUsers"
                />
              </Field>
            </div>

            <div class="row q-mb-sm">
              <Field name="date_of_birth" v-slot="{ errorMessage, value, field }">
                <DateSelect
                  class="q-px-sm col"
                  label="Date Of Birth"
                  :model-value="value"
                  v-bind="field"
                  :error-message="errorMessage"
                  :error="!!errorMessage"
                  :disable="cannotWriteUsers"
                  max="today"
                />
              </Field>
            </div>

            <div class="row q-mb-sm">
              <Field name="role_id" v-slot="{ errorMessage, value, field }">
                <div class="q-px-sm col">
                  <RoleSelect
                    label="Role"
                    :model-value="value"
                    v-bind="field"
                    :error-message="errorMessage"
                    :error="!!errorMessage"
                    :disable="cannotWriteUsers"
                  />
                  <span v-if="!errorMessage" class="text-sm text-grey">
                    Changing role will reset custom permissions.
                  </span>
                </div>
              </Field>
            </div>

            <div class="row q-mb-sm">
              <Field name="profile_pic" v-slot="{ errorMessage, value, field }">
                <q-file
                  class="q-px-sm col-10"
                  :model-value="value"
                  label="Profile Picture"
                  :error-message="errorMessage"
                  :error="!!errorMessage"
                  @blur="field.onBlur"
                  @input="field.onChange"
                  :disable="cannotWriteUsers"
                >
                  <template v-slot:prepend>
                    <q-icon name="attach_file" />
                  </template>
                </q-file>
              </Field>

              <div class="col flex justify-center">
                <q-avatar color="primary" text-color="white">
                  <img v-if="fileUrl" :src="fileUrl" />
                  <template v-else>
                    {{ initials }}
                  </template>
                </q-avatar>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <q-btn
                  v-if="user?.status === 'Active' && user?.id !== authUser?.id"
                  class="text-right q-mr-sm"
                  color="negative"
                  label="Recover Account"
                  @click="recoverAccount"
                />

                <q-btn
                  v-if="user?.status === 'Pending' || user?.new_email"
                  class="text-right"
                  color="secondary"
                  label="Resend Verification Email"
                  type="button"
                  @click="resendVerification"
                  :disable="cannotWriteUsers"
                />
              </div>

              <div class="col-auto">
                <q-btn
                  class="text-right"
                  color="primary"
                  label="Save"
                  type="submit"
                  :disable="cannotWriteUsers"
                />
              </div>
            </div>
          </q-form>
        </div>
      </q-page>
    </q-page-container>
  </AppContainer>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { Field, useForm } from 'vee-validate';
import * as yup from 'yup';
import { useUserStore, type User, type EditUserForm } from '@/stores/user';
import { storeToRefs } from 'pinia';
import TimezoneSelect from '@/components/form/TimezoneSelect.vue';
import DateSelect from '@/components/form/DateSelect.vue';
import RoleSelect from '@/components/form/RoleSelect.vue';
import { AxiosError } from 'axios';
import { useToast } from 'vue-toast-notification';
import http from '@/utils/http';
import { useAuthUserStore } from '@/stores/auth-user';
import { useConfirmationDialog } from '@/composable/form/use-confirmation-dialog';
import useTenantUserEncryption from '@/composable/tenant_user';
import createTenantUserKeys from '@/composable/tenant_user/create-user-keys';

const toast = useToast();
const confirmationDialog = useConfirmationDialog();
const { recoverUserAccount } = useTenantUserEncryption();

const authUserStore = useAuthUserStore();
const { authUser } = storeToRefs(authUserStore);

const userStore = useUserStore();
const { user } = storeToRefs(userStore);

if (user?.value === undefined) {
  throw new Error('User is undefined.');
}

const schema = yup.object({
  first_name: yup.string().required().label('First name'),
  last_name: yup.string().required().label('Last name'),
  email: yup.string().required().email().label('Email'),
  timezone: yup.string().required().label('Timezone'),
  date_of_birth: yup.string().required().label('Date of Birth'),
  role_id: yup.string().required().label('Role'),
  profile_pic: yup.mixed().label('Profile Picture'),
});

const initialValues = {
  first_name: user.value.first_name,
  last_name: user.value.last_name,
  email: user.value.email,
  timezone: user.value.timezone,
  date_of_birth: user.value.date_of_birth,
  role_id: user.value.role.id,
  profile_pic: undefined,
};

const { handleSubmit, setErrors, setValues, setFieldValue, values } = useForm<EditUserForm>({
  validationSchema: schema,
  initialValues: initialValues,
});

const initials = computed(() => {
  return `${user.value?.first_name.charAt(0)}${user.value?.last_name.charAt(0)}`;
});

const fileUrl = computed(() => {
  if (values.profile_pic) {
    return URL.createObjectURL(values.profile_pic);
  }

  if (user.value?.profile_pic_url) {
    return user.value?.profile_pic_url;
  }

  return null;
});

const cannotWriteUsers = computed(() => !authUserStore.can({ action: 'write', subject: 'users' }));

const save = handleSubmit(async (payload) => {
  try {
    const u: User = await userStore.updateUser({ id: user.value?.id as string, payload });

    setValues(u);
    setFieldValue('role_id', u.role.id);

    toast.success('User saved successfully.');
  } catch (err) {
    if (err instanceof AxiosError) {
      setErrors(err.response?.data);
      return;
    }

    throw err;
  }
});

const resendVerification = async () => {
  if (!authUser.value || !authUser.value.asymmetric_key_private_decrypted) {
    throw new Error('Cannot create user keys without a decrypted private key.');
  }

  const tenantUserKeys = await createTenantUserKeys();

  await http.post(`/users/${user.value?.id}/resend-verification`, {
    asymmetric_key_public: tenantUserKeys.asymmetric_key_public,
    asymmetric_key_private: tenantUserKeys.asymmetric_key_private,

    tenant_user_symmetric_key: tenantUserKeys.tenant_user_symmetric_key,
    tenant_user_symmetric_key_password: tenantUserKeys.tenant_user_symmetric_key_password,
  });

  toast.success('Sent verification email to user.');
};

const recoverAccount = async () => {
  if (
    !(await confirmationDialog.confirm({
      title: 'Recover Account',
      message: 'Are you sure you want to recover this account?',
      options: {
        okLabel: 'Recover',
        cancelLabel: 'Cancel',
      },
    }))
  ) {
    return;
  }

  if (!user.value) {
    toast.error('An error occurred. Please try again later.');
    return;
  }

  try {
    await recoverUserAccount(user.value);

    toast.success('Account recovery email sent.');
  } catch (err) {
    toast.error('An error occurred. Please try again later.');
    throw err;
  }
};

const title = computed(() => `${userStore.fullName} - Details`);
</script>
