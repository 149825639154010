<template>
  <q-item class="q-my-sm" @click="openDialog" clickable v-ripple>
    <q-item-section>
      <q-item-label>
        <b>{{ userName }}</b> requested access to <b>{{ accessRequest.target_name }}</b>

        <q-badge
          v-if="accessRequest.type === 'secret'"
          color="positive"
          style="margin-left: 0.5rem"
        >
          Secret
        </q-badge>

        <q-badge v-if="accessRequest.type === 'group'" color="warning" style="margin-left: 0.5rem">
          Group
        </q-badge>
      </q-item-label>

      <q-item-label v-if="accessRequest.granted_access_at !== null" caption lines="1">
        <b>Accepted</b> on {{ grantedAccessAt }}
      </q-item-label>

      <q-item-label v-if="accessRequest.declined_access_at !== null" caption lines="1">
        <b>Declined</b> on {{ declinedAccessAt }}
      </q-item-label>
    </q-item-section>

    <q-item-section side>
      {{ createdAt }}
    </q-item-section>
  </q-item>
</template>

<script lang="ts" setup>
import { computed, toRefs } from 'vue';
import { useAccessRequestStore, type AccessRequest } from '@/stores/access-request';
import formatDatetime from '@/composable/format-datetime';

const props = defineProps<{
  accessRequest: AccessRequest;
}>();

const emit = defineEmits<{
  (e: 'open-dialog'): void;
}>();

const accessRequestStore = useAccessRequestStore();

const { accessRequest } = toRefs(props);

const userName = computed(() => {
  return `${accessRequest.value.user_first_name} ${accessRequest.value.user_last_name}`;
});

const createdAt = formatDatetime(accessRequest.value.created_at);

const grantedAccessAt = formatDatetime(accessRequest.value.granted_access_at);

const declinedAccessAt = formatDatetime(accessRequest.value.declined_access_at);

const openDialog = () => {
  if (
    accessRequest.value.granted_access_at !== null ||
    accessRequest.value.declined_access_at !== null
  ) {
    return;
  }

  accessRequestStore.setAccessRequest(accessRequest.value);
  emit('open-dialog');
};
</script>
