<template>
  <q-form class="card card-auth q-pa-lg rounded" @submit="verify">
    <div class="q-gutter-md">
      <div class="text-center q-mx-none heading q-mt-md q-mb-lg">
        <img src="@/assets/icons/icon.svg" class="logo-image" />
        <h2>strng.io</h2>
      </div>

      <p class="text-center">
        Please verify your two factor login by entering the code sent to {{ authUser?.email }}.
      </p>

      <Field name="code" v-slot="{ errorMessage, value, field }">
        <q-input
          label="Code"
          :model-value="value"
          v-bind="field"
          :error-message="errorMessage"
          :error="!!errorMessage"
          mask="### ###"
        />
      </Field>

      <Field name="remember_device" v-slot="{ errorMessage, value, field }">
        <q-checkbox
          :label="rememberDeviceLabel"
          :model-value="value"
          v-bind="field"
          :error-message="errorMessage"
          :error="!!errorMessage"
          style="margin-top: 0"
        />
      </Field>

      <div class="row">
        <div class="col-auto">
          <q-btn class="text-left" color="negative" label="Logout" type="button" @click="logout" />
        </div>

        <div class="col"></div>

        <div class="col-auto">
          <q-btn class="text-right" color="primary" label="Submit" type="submit" />
        </div>
      </div>
    </div>
  </q-form>
</template>

<script lang="ts" setup>
import { Field, useForm } from 'vee-validate';
import * as yup from 'yup';
import http from '@/utils/http';
import { useAuthUserStore } from '@/stores/auth-user';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import useAddRecoveryKey from '@/composable/group/add-recovery-key';

const router = useRouter();
const route = useRoute();

const authUserStore = useAuthUserStore();
const { authUser } = storeToRefs(authUserStore);

const { addRecoveryKey } = useAddRecoveryKey();

type TwoFactorEmailForm = {
  code?: string;
  remember_device?: boolean;
};

const schema = yup.object({
  code: yup.string().required().label('Code'),
  remember_device: yup.boolean().label('Remember Device'),
});

const { handleSubmit, setErrors } = useForm<TwoFactorEmailForm>({
  validationSchema: schema,
  initialValues: {
    remember_device: false,
  },
});

const verify = handleSubmit(async (payload) => {
  try {
    await http.post('/verify/two-factor/email', payload);

    if (authUser.value?.has_recovery_group && authUser.value?.asymmetric_key_private_decrypted) {
      await addRecoveryKey(authUser.value!.id, authUser.value!.asymmetric_key_private_decrypted);
    }

    router.replace(redirectTo.value);
  } catch (err) {
    setErrors({ code: 'Invalid code.' });
  }
});

const logout = () => {
  authUserStore.logout();
  router.replace({ name: 'login' });
};

const redirectTo = computed(() => {
  if (route.query.redirect) {
    return route.query.redirect as string;
  }

  return {
    name: authUserStore.can({ action: 'read', subject: 'dashboard' }) ? 'dashboard' : 'secrets',
  };
});

const rememberDeviceLabel = computed(() => {
  return `Remember this device for ${authUser.value?.tenant_two_factor_remember_expiry_day} days`;
});
</script>
