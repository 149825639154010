<template>
  <AppContainer title="Dashboard">
    <template #buttons>
      <HelpPopup page="dashboard" />
    </template>

    <q-page-container>
      <q-page class="q-pa-md">
        <div class="row justify-center">
          <div class="col-lg-8" v-if="authUserStore.can({ action: 'read', subject: 'dashboard' })">
            <div class="row q-gutter-md">
              <div class="col">
                <ActiveSessionCountCard />
              </div>

              <div class="col">
                <UserCountCard />
              </div>

              <div class="col">
                <SecretCountCard />
              </div>
            </div>

            <div class="row q-gutter-md">
              <div class="col">
                <AnalyticsChart />
              </div>
            </div>
          </div>
        </div>
      </q-page>
    </q-page-container>
  </AppContainer>
</template>

<script lang="ts" setup>
import { onMounted } from 'vue';
import { useAuthUserStore } from '@/stores/auth-user';
import { useRouter } from 'vue-router';
import ActiveSessionCountCard from '@/components/dashboard/ActiveSessionCountCard.vue';
import SecretCountCard from '@/components/dashboard/SecretCountCard.vue';
import UserCountCard from '@/components/dashboard/UserCountCard.vue';
import AnalyticsChart from '@/components/dashboard/AnalyticsChart.vue';

const authUserStore = useAuthUserStore();
const router = useRouter();

onMounted(() => {
  if (!authUserStore.can({ action: 'read', subject: 'dashboard' })) {
    router.replace({ name: 'secrets.list' });
  }
});
</script>
