<template>
  <div class="row justify-center q-mt-lg">
    <q-form class="q-layout col-12 col-lg-6 card rounded q-pa-md" @submit="save">
      <div class="row">
        <div class="col-12">
          <h6 class="q-ma-sm">Change Password</h6>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <Field name="current_password" v-slot="{ errorMessage, value, field }">
            <q-input
              class="q-px-sm"
              type="password"
              :model-value="value"
              v-bind="field"
              :val="true"
              label="Current Password"
              :error-message="errorMessage"
              :error="!!errorMessage"
            />
          </Field>
        </div>
      </div>

      <div class="row q-mt-md">
        <div class="col-6">
          <Field name="new_password" v-slot="{ errorMessage, value, field }">
            <q-input
              class="q-px-sm"
              type="password"
              :model-value="value"
              v-bind="field"
              :val="true"
              label="New Password"
              :error-message="errorMessage"
              :error="!!errorMessage"
            />
          </Field>

          <PasswordStrength :password="values.new_password" class="q-mx-sm q-mb-md" />
        </div>

        <div class="col-6">
          <Field name="confirm_new_password" v-slot="{ errorMessage, value, field }">
            <q-input
              class="q-px-sm"
              type="password"
              :model-value="value"
              v-bind="field"
              :val="true"
              label="Confirm New Password"
              :error-message="errorMessage"
              :error="!!errorMessage"
            />
          </Field>
        </div>
      </div>

      <div class="row q-pt-md q-px-sm">
        <div class="col"></div>

        <div class="col-auto">
          <q-btn
            class="text-right"
            color="primary"
            label="Change Password"
            type="submit"
            :loading="isLoading"
          />
        </div>
      </div>
    </q-form>
  </div>
</template>

<script lang="ts" setup>
import { Field, useForm } from 'vee-validate';
import * as yup from 'yup';
import { storeToRefs } from 'pinia';
import { useToast } from 'vue-toast-notification';
import { useAuthUserStore, type ChangePassworForm } from '@/stores/auth-user';
import { AxiosError } from 'axios';
import { wrapCryptoKey } from '@/utils/encryption/asymmetric';
import PasswordStrength from '@/components/form/PasswordStrength.vue';
import { ref } from 'vue';

const isLoading = ref(false);

const toast = useToast();

const authUserStore = useAuthUserStore();
const { authUser } = storeToRefs(authUserStore);

if (!authUser?.value) {
  throw new Error('User is undefined.');
}

const schema = yup.object({
  current_password: yup.string().required().label('Current Password'),
  new_password: yup.string().required().label('New Password'),
  confirm_new_password: yup.string().required().label('Confirm New Password'),
  asymmetric_key_private: yup.string(),
});

const { handleSubmit, setErrors, values } = useForm<ChangePassworForm>({
  validationSchema: schema,
  initialValues: {},
});

const save = handleSubmit(async (payload, actions) => {
  if (!authUser.value?.asymmetric_key_private_decrypted) {
    toast.error('An error occurred. Please try again later.');
    return;
  }

  try {
    isLoading.value = true;

    await authUserStore.changePassword({
      payload: {
        ...payload,
        asymmetric_key_private: await wrapCryptoKey(
          authUser.value?.asymmetric_key_private_decrypted,
          payload.new_password
        ),
      },
    });

    isLoading.value = false;

    toast.success('Password updated successfully.');
    actions.resetForm();
  } catch (err) {
    isLoading.value = false;

    actions.resetForm();
    if (err instanceof AxiosError) {
      setErrors(err.response!.data);
    }
  }
});
</script>
