<template>
  <q-banner
    v-if="showPaymentPastDueBanner"
    :inline-actions="props.inlineActions"
    class="text-white bg-red"
  >
    Your strng.io subscription payment is overdue. Please update your payment details.
    <template v-slot:action>
      <q-btn
        flat
        color="white"
        label="Update Payment Details"
        :to="{ name: 'tenant.subscription' }"
      />
    </template>
  </q-banner>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useAuthUserStore } from '@/stores/auth-user';
import { storeToRefs } from 'pinia';

const props = withDefaults(
  defineProps<{
    overrideShow?: boolean;
    inlineActions?: boolean;
  }>(),
  {
    overrideShow: false,
    inlineActions: true,
  }
);

const authUserStore = useAuthUserStore();
const { authUser } = storeToRefs(authUserStore);

const showPaymentPastDueBanner = computed(() => {
  if (props.overrideShow) {
    return true;
  }

  return authUser?.value?.current_subscription.subscription_status === 'past_due';
});
</script>
