import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { Quasar, QInput, QSelect, QFile, QRouteTab, Dialog } from 'quasar';
import 'vee-validate';
import * as Sentry from '@sentry/vue';

// Import icon libraries
import '@quasar/extras/roboto-font/roboto-font.css';
import '@quasar/extras/material-icons/material-icons.css';
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css';
import '@quasar/extras/material-icons-round/material-icons-round.css';
import '@quasar/extras/material-icons-sharp/material-icons-sharp.css';
import '@quasar/extras/material-symbols-outlined/material-symbols-outlined.css';
import '@quasar/extras/material-symbols-rounded/material-symbols-rounded.css';
import '@quasar/extras/material-symbols-sharp/material-symbols-sharp.css';
import '@quasar/extras/mdi-v7/mdi-v7.css';
import '@quasar/extras/fontawesome-v6/fontawesome-v6.css';

import 'vue-toast-notification/dist/theme-sugar.css';

import AppContainer from '@/components/AppContainer.vue';
import HelpPopup from '@/components/HelpPopup.vue';
import DateSelect from '@/components/form/DateSelect.vue';

import 'quasar/src/css/index.sass';
import './assets/scss/main.scss';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

import App from './App.vue';
import router from './router';

const app = createApp(App);

Sentry.init({
  app,
  dsn: import.meta.env.VITE_APP_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/staging.api.strng.io\.io/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

app.use(Quasar, {
  plugins: {
    Dialog,
  },
});

QInput.props.outlined = { type: Boolean, default: true };
// defineComponent('q-input', QInput);
QSelect.props.outlined = { type: Boolean, default: true };
// defineComponent('q-select', QSelect);
QFile.props.outlined = { type: Boolean, default: true };
// defineComponent('q-file', QFile);
QRouteTab.props.ripple = { type: Boolean, default: false };
// defineComponent('q-route-tab', QRouteTab);

// Register a global custom directive called `v-focus`
app.directive('focus', {
  // When the bound element is mounted into the DOM...
  mounted(el: HTMLElement) {
    // Focus the element
    el.focus();
  },
});

app.use(createPinia());
app.use(router);

app.component('AppContainer', AppContainer);
app.component('HelpPopup', HelpPopup);
app.component('DateSelect', DateSelect);

app.mount('#app');
