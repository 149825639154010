import type { ImportStructure } from './import-passwords';

export type LastPassExport = {
  extra: string;
  fav: string;
  grouping: string;
  name: string;
  password: string;
  totp: string;
  url: string;
  username: string;
};

export const parseLastpassExport = async (data: LastPassExport[]): Promise<ImportStructure> => {
  const importData: ImportStructure = {
    groups: [],
    secrets: [],
    errors: [],
  };

  for (let i = 0; i < data.length; i++) {
    const item = data[i];

    const hasGroup = importData.groups.find((group) => group.name === item.grouping);
    if (!hasGroup) {
      importData.groups.push({
        name: item.grouping,
        external_id: item.grouping,
        internal_id: null,
      });
    }

    importData.secrets.push({
      name: item.name,
      username: item.username ?? undefined,
      secret: item.password ?? undefined,
      notes: item.extra,
      url: item.url !== 'http://sn' ? item.url : undefined,
      otp_secret: item.totp ?? undefined,

      external_id: item.name,
      internal_id: null,

      group_external_id: item.grouping,
      group_internal_id: null,
    });
  }

  return importData;
};
