<template>
  <q-dialog v-model="isOpen" position="top">
    <q-card class="add-access-dialog">
      <q-card-section class="row items-center q-pb-none">
        <div class="text-h6">Add Secrets</div>
        <q-space />
        <q-btn icon="close" flat round dense v-close-popup />
      </q-card-section>

      <q-form @submit="save">
        <q-card-section>
          <div class="row">
            <Field name="secrets" v-slot="{ errorMessage }">
              <q-select
                class="q-px-sm col"
                label="Secrets"
                v-model="selectedSecrets"
                :error-message="errorMessage"
                :error="!!errorMessage"
                :options="options"
                :loading="optionsLoading"
                option-label="name"
                @filter="filterFn"
                use-input
                emit-value
                map-options
                multiple
                use-chips
              />
            </Field>
          </div>

          <div v-if="errors.length > 0" class="row q-mt-md">
            <p class="my-sm text-negative" v-for="(error, index) in errors" :key="index">
              {{ error }}
            </p>
          </div>

          <div class="row q-mt-md">
            <div class="col"></div>

            <div class="col-auto">
              <q-btn class="text-right" color="primary" label="Add Access" type="submit" />
            </div>
          </div>
        </q-card-section>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script lang="ts" setup>
import { Field, useForm } from 'vee-validate';
import { computed, onBeforeUnmount, ref, watch } from 'vue';
import * as yup from 'yup';
import { useGroupStore } from '@/stores/group';
import { useGroupSecretStore, type GroupSecretOption } from '@/stores/group/secrets';
import { useToast } from 'vue-toast-notification';
import { storeToRefs } from 'pinia';
import useAddSecretAccess from '@/composable/secret/add-access';

const props = defineProps<{
  isOpen: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:isOpen', value: boolean): void;
  (e: 'refresh'): void;
}>();

const isOpen = computed({
  get() {
    return props.isOpen;
  },

  set(value: boolean) {
    emit('update:isOpen', value);
  },
});

const optionsLoading = ref(false);
const buttonLoading = ref(false);

const toast = useToast();

const options = ref<GroupSecretOption[]>([]);
const errors = ref<string[]>([]);

const { addAccess } = useAddSecretAccess();

const groupStore = useGroupStore();
const { group } = storeToRefs(groupStore);

const groupSecretStore = useGroupSecretStore();
const { groupSecretOptions } = storeToRefs(groupSecretStore);

interface AddSecretsForm {
  secrets: GroupSecretOption[];
}

const { handleSubmit, resetForm, setFieldValue, values } = useForm<AddSecretsForm>({
  validationSchema: yup.object({
    secrets: yup.array().ensure().required().label('Secrets'),
  }),
  initialValues: {},
});

const save = handleSubmit(async (payload) => {
  if (!group.value) {
    throw new Error('Group is undefined.');
  }

  buttonLoading.value = true;

  for (let i = 0; i < payload.secrets.length; i++) {
    const secret = payload.secrets[i];

    try {
      await addAccess({
        secretID: secret.id,
        accessOpt: {
          id: group.value.id,
          name: group.value.name,
          type: 'Groups',
        },
        sharingPerms: false,
      });
    } catch (err) {
      errors.value.push(`Failed to add secret "${secret.name}" to group.`);
    }

    buttonLoading.value = false;
  }

  if (errors.value.length > 0) {
    return;
  }

  if (errors.value.length === 0) {
    toast.success('Successfully added secrets to group.');
    isOpen.value = false;
    emit('refresh');
  }
});

const selectedSecrets = computed({
  get() {
    return values.secrets;
  },

  set(value: GroupSecretOption[]) {
    setFieldValue('secrets', value);
  },
});

const filterFn = (val: string, update: (fn: () => void) => void) => {
  if (val === '') {
    update(() => {
      options.value = groupSecretOptions.value;
    });
    return;
  }

  update(() => {
    const needle = val.toLowerCase();

    options.value = groupSecretOptions.value.filter((t: GroupSecretOption) => {
      if (!t.name) {
        return true;
      }

      return t.name.toLowerCase().indexOf(needle) > -1;
    });
  });
};

onBeforeUnmount(() => {
  groupSecretStore.resetGroupSecretOptions();
});

watch(isOpen, () => {
  resetForm();

  if (isOpen.value === true) {
    optionsLoading.value = true;
    groupSecretStore.getGroupSecretOptions(group.value!.id).then(() => {
      options.value = groupSecretOptions.value;
      optionsLoading.value = false;
    });
  } else {
    options.value = [];
  }
});
</script>
