import { useGroupStore } from '@/stores/group';
import { exportPrivateKey, rsaEncrypt } from '@/utils/encryption/asymmetric';
import { aesEncrypt, exportAesKey, generateAesKey } from '@/utils/encryption/symmetric';
import { stringToBytes } from '@/utils/encryption/utils';

export default function useAddRecoveryKey() {
  const groupStore = useGroupStore();

  const addRecoveryKey = async (userID: string, asymmetricKeyPrivate: CryptoKey): Promise<void> => {
    const asymmetricKeyPrivateExported = await exportPrivateKey(asymmetricKeyPrivate);

    const recoveryGroupAsymmetricKeyPublic = await groupStore.getRecoveryGroupPublicKey();
    const symmetricKey = await generateAesKey();

    const encryptedUserPrivateKey = await aesEncrypt(
      symmetricKey,
      stringToBytes(asymmetricKeyPrivateExported)
    );

    const encryptedSymmetricKey = await rsaEncrypt(
      recoveryGroupAsymmetricKeyPublic,
      await exportAesKey(symmetricKey)
    );

    await groupStore.addUserRecoveryKey({
      user_id: userID,
      symmetric_key: encryptedSymmetricKey,
      asymmetric_key_private: encryptedUserPrivateKey,
    });
  };

  return {
    addRecoveryKey,
  };
}
