<template>
  <RouterView v-if="group?.id" v-slot="{ Component }: any">
    <component :is="Component">
      <template #tabs>
        <q-tabs align="left">
          <q-route-tab :to="{ name: 'group.details' }" label="Details" />

          <q-route-tab v-if="group.has_access" :to="{ name: 'group.access' }" label="Access" />

          <q-route-tab v-if="group.has_access" :to="{ name: 'group.secrets' }" label="Secrets" />
        </q-tabs>
      </template>
    </component>
  </RouterView>
</template>

<script lang="ts" setup>
import { useGroupStore } from '@/stores/group';
import { storeToRefs } from 'pinia';
import { onBeforeUnmount } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const groupStore = useGroupStore();
const { group } = storeToRefs(groupStore);

await groupStore.getGroup({
  id: route.params.id as string,
});

if (group?.value === undefined) {
  throw new Error('Secret is undefined.');
}

onBeforeUnmount(() => {
  groupStore.resetGroup();
});
</script>
