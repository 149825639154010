import SecretList from '@/views/secrets/SecretList.vue';
import SecretAdd from '@/views/secrets/SecretAdd.vue';
import SecretShow from '@/views/secrets/SecretShow.vue';
import SecretDetails from '@/views/secrets/SecretDetails.vue';
import SecretFileList from '@/views/secrets/SecretFileList.vue';
import SecretAccessList from '@/views/secrets/SecretAccessList.vue';
import SecretLogList from '@/views/secrets/SecretLogList.vue';

export default [
  {
    path: '/secrets',
    name: 'secrets',
    redirect: { name: 'secrets.list' },
    meta: {
      isAdmin: true,
    },
    children: [
      {
        path: '',
        name: 'secrets.list',
        component: SecretList,
      },
      {
        path: 'add',
        name: 'secrets.add',
        component: SecretAdd,
        meta: {
          ability: {
            action: 'write',
            subject: 'secrets',
          },
        },
      },
      {
        path: ':id',
        name: 'secret',
        component: SecretShow,
        children: [
          {
            path: 'details',
            name: 'secret.details',
            component: SecretDetails,
          },
          {
            path: 'access',
            name: 'secret.access',
            component: SecretAccessList,
          },
          {
            path: 'files',
            name: 'secret.files',
            component: SecretFileList,
          },
          {
            path: 'logs',
            name: 'secret.logs',
            component: SecretLogList,
          },
        ],
      },
    ],
  },
];
