import { defineStore } from 'pinia';
import http from '@/utils/http';
import useParseKeys from '@/composable/key/parse-keys';

export interface SecretState {
  secrets: Secret[];
  secret: Secret | null;
  secretPublicKey: CryptoKey | null;
  secretPrivateKey: CryptoKey | null;
  secretSymmetricKey: CryptoKey | null;
}

export interface Secret {
  id: string;
  name: string;
  username?: string;
  secret?: string;
  url?: string;
  url_hash?: string;
  note?: string;
  otp_secret?: string;
  hidden: boolean;
  created_at: string;
  updated_at: string;
  has_access: boolean;
  can_grant_access: boolean;
  has_requested_access: boolean;
  score?: number | string;
}

export interface SecretListFilters {
  search?: string;
  date?: { from: string; to: string };
}

export const useSecretStore = defineStore('secrets', {
  state: (): SecretState => ({
    secrets: [],
    secret: null,
    secretPrivateKey: null,
    secretPublicKey: null,
    secretSymmetricKey: null,
  }),

  getters: {},

  actions: {
    async getSecrets({
      page,
      filters,
      reset = false,
    }: {
      page: number;
      filters?: SecretListFilters;
      reset?: boolean;
    }) {
      const response = await http.get('/secrets', { params: { page, ...filters } });

      if (reset) {
        this.secrets = [];
      }

      this.secrets.push(...(response.data ?? []));

      return response.data ?? [];
    },

    resetSecrets() {
      this.secrets = [];
    },

    setSecret(secret: Secret) {
      this.secret = secret;
    },

    resetSecret() {
      this.secret = null;
      this.secretPrivateKey = null;
    },

    setSecretPrivateKey(key: CryptoKey) {
      this.secretPrivateKey = key;
    },

    resetSecretPrivateKey() {
      this.secretPrivateKey = null;
    },

    async addSecret({ payload }: { payload: CreateSecretPayload }): Promise<Secret> {
      const response = await http.post('/secrets', payload);

      this.secret = response.data;

      return response.data as Secret;
    },

    async updateSecret({ id, payload }: { id: string; payload: UpdateSecretPayload }) {
      const response = await http.put(`/secrets/${id}`, payload);
      return response.data as Secret;
    },

    async getSecret({ id }: { id: string }) {
      const response = await http.get(`/secrets/${id}`);

      this.secret = response.data;

      return response.data as Secret;
    },

    async getSecretKey(id: string) {
      const response = await http.get(`/secrets/${id}/keys`);

      const { parseKeys } = useParseKeys(response.data);
      const keys = await parseKeys();

      if (keys?.asymmetric_key_private !== null) {
        this.secretPrivateKey = keys.asymmetric_key_private;
      }

      if (keys?.asymmetric_key_public !== null) {
        this.secretPublicKey = keys.asymmetric_key_public;
      }

      if (keys?.symmetric_key !== null) {
        this.secretSymmetricKey = keys.symmetric_key;
      }

      return keys;
    },

    async requestAccess(id: string) {
      await http.post(`/secrets/${id}/request-access`);

      if (this.secret === null) {
        return;
      }

      this.secret.has_requested_access = true;
    },

    async deleteSecret(id: string) {
      await http.delete(`/secrets/${id}`);
      this.secret = null;
    },
  },
});

export type AddSecretForm = {
  tenant_id?: string;
  name?: string;
  username?: string;
  secret?: string;
  url?: string;
  note?: string;
  otp_secret?: string;
  hidden?: boolean;
  score?: string | number;
  user_id?: string;
  user_secret_symmetric_key?: string;
  user_secret_asymmetric_key_private?: string;
  user_secret_asymmetric_key_pubic?: string;
};

export type UpdateSecretForm = {
  name: string;
  username?: string;
  secret?: string;
  url?: string;
  note?: string;
  otp_secret?: string;
  hidden: boolean;
  score?: number;
  score_previous?: number;
};

export interface CreateSecretPayload {
  name: string;
  username?: string | null;
  secret: string;
  otp_secret?: string | null;
  url?: string | null;
  url_hash?: string | null;
  note?: string | null;
  hidden: boolean;
  score?: string;
  score_current?: number;
  user_id: string;
  tenant_id: string;
  user_secret_symmetric_key: string;
  user_secret_asymmetric_key_public: string;
  user_secret_asymmetric_key_private: string;
}

export interface UpdateSecretPayload {
  name: string;
  username?: string;
  secret: string;
  otp_secret?: string;
  url?: string;
  url_hash?: string;
  note?: string;
  hidden: boolean;
  score?: string;
  score_current?: number;
  score_previous?: number;
}
