<template>
  <div class="row items-center q-mt-sm">
    <div class="col">
      <slot name="title" />
    </div>

    <div class="col-auto">
      <img v-if="cardBrandImg" :src="cardBrandImg" class="card-brand" />
    </div>
  </div>

  <div class="text-center" v-show="isLoading">
    <span>Loading...</span>
  </div>

  <form v-on:submit.prevent v-show="!isLoading">
    <div class="row">
      <div class="col-12">
        <q-field
          outlined
          label="Card Number"
          stack-label
          class="q-mb-md"
          :error-message="errors['cardNumber']"
          :error="!isCardNumberValid"
        >
          <template v-slot:control>
            <div class="self-center full-width no-outline">
              <div id="cardNumber" ref="cardNumber"></div>
            </div>
          </template>
        </q-field>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-6 q-pr-sm">
        <q-field
          outlined
          label="Expiry"
          stack-label
          class="q-mb-md"
          :error-message="errors['cardExpiry']"
          :error="!isCardExpiryValid"
        >
          <template v-slot:control>
            <div class="self-center full-width no-outline">
              <div id="cardExpiry" ref="cardExpiry"></div>
            </div>
          </template>
        </q-field>
      </div>

      <div class="col-6 q-pl-sm">
        <q-field
          outlined
          label="CVC"
          stack-label
          class="q-mb-md"
          :error-message="errors['cardCvc']"
          :error="!isCardCvcValid"
        >
          <template v-slot:control>
            <div class="self-center full-width no-outline">
              <div id="cardCvc" ref="cardCvc"></div>
            </div>
          </template>
        </q-field>
      </div>
    </div>

    <div class="pt-4 px-1 row">
      <div class="col"></div>
      <slot name="buttons" />
    </div>
  </form>
</template>

<script lang="ts" setup>
import { onMounted, ref, computed } from 'vue';
import {
  loadStripe,
  type PaymentMethod,
  type Stripe,
  type StripeCardCvcElement,
  type StripeCardCvcElementChangeEvent,
  type StripeCardElement,
  type StripeCardExpiryElement,
  type StripeCardExpiryElementChangeEvent,
  type StripeCardNumberElement,
  type StripeCardNumberElementChangeEvent,
  type StripeElements,
} from '@stripe/stripe-js';
import visaImage from '@/assets/images/visa.png';
import mastercardImage from '@/assets/images/mastercard.png';

const emit = defineEmits<{
  (e: 'tokenize', t: () => Promise<PaymentMethod>): void;
}>();

const cardNumber = ref<HTMLElement | null>(null);
const cardExpiry = ref<HTMLElement | null>(null);
const cardCvc = ref<HTMLElement | null>(null);
const cardBrand = ref<string | null>(null);

const errors = ref<{
  cardNumber?: string;
  cardExpiry?: string;
  cardCvc?: string;
}>({
  cardNumber: '',
  cardExpiry: '',
  cardCvc: '',
});

const stripe = ref<Stripe | null>(null);
const elements = ref<StripeElements | null>(null);
const isLoading = ref(true);

const cNumber = ref<StripeCardElement | StripeCardNumberElement | { token: string } | null>(null);
const cExpiry = ref<StripeCardElement | StripeCardExpiryElement | { token: string } | null>(null);
const cCvc = ref<StripeCardElement | StripeCardCvcElement | { token: string } | null>(null);

const processCard = async (): Promise<PaymentMethod> => {
  if (stripe.value === null || elements.value === null) {
    throw new Error('Stripe is not initialised.');
  }

  // This should never happen, just here to make the type check happy
  if (cNumber.value === null) {
    throw new Error('Card number is null.');
  }

  const response = await stripe.value.createPaymentMethod({
    type: 'card',
    card: cNumber.value,
  });

  if (response.error) {
    throw response.error;
  }

  return response.paymentMethod;
};

const setupCardForm = async () => {
  if (stripe.value === null) {
    return;
  }

  elements.value = stripe.value.elements({});

  if (elements.value === null) {
    throw new Error('Could not initialise stripe.');
  }

  const elementCss: {
    [key: string]: string | any;
  } = {
    fontFamily: '"Roboto", "-apple-system", "Helvetica Neue", Helvetica, Arial, sans-serif',
    '::placeholder': {
      color: '#CFD7E0',
    },
  };

  cNumber.value = elements.value.create('cardNumber', {
    style: { base: elementCss },
    placeholder: '•••• •••• •••• ••••',
  });
  cNumber.value.mount(cardNumber.value as HTMLElement);
  cNumber.value.on('change', (e) => updated(e));

  cExpiry.value = elements.value.create('cardExpiry', {
    style: { base: elementCss },
    placeholder: 'MM / YY',
  });
  cExpiry.value.mount(cardExpiry.value as HTMLElement);
  cExpiry.value.on('change', (e) => updated(e));

  cCvc.value = elements.value.create('cardCvc', {
    style: { base: elementCss },
    placeholder: '•••',
  });
  cCvc.value.mount(cardCvc.value as HTMLElement);
  cCvc.value.on('change', (e) => updated(e));

  cNumber.value.on('change', ({ brand }) => {
    cardBrand.value = brand;
  });

  isLoading.value = false;

  emit('tokenize', processCard);
};

const cardBrandImg = computed(() => {
  if (cardBrand.value === 'visa') {
    return visaImage;
  }

  if (cardBrand.value === 'mastercard') {
    return mastercardImage;
  }

  return null;
});

onMounted(async () => {
  stripe.value = await loadStripe(import.meta.env.VITE_STRIPE_KEY);
  setupCardForm();
});

const isCardNumberValid = computed(() => {
  return isValid('cardNumber');
});

const isCardExpiryValid = computed(() => {
  return isValid('cardExpiry');
});

const isCardCvcValid = computed(() => {
  return isValid('cardCvc');
});

function updated(
  e:
    | StripeCardNumberElementChangeEvent
    | StripeCardExpiryElementChangeEvent
    | StripeCardCvcElementChangeEvent
) {
  const elementType = e['elementType'];
  const error = e['error'];
  if (error) {
    errors.value[elementType] = e['error']!['message'];
    return null;
  } else {
    if (errors.value[elementType]) {
      errors.value[elementType] = '';
    }
  }
}

function isValid(elementType: 'cardNumber' | 'cardExpiry' | 'cardCvc') {
  return errors.value[elementType] === '';
}
</script>

<style>
*,
::before,
::after {
  position: static;
}
</style>
