import { defineStore } from 'pinia';

export interface AppState {
  csrfToken?: string;
}

export const useAppStore = defineStore('app', {
  state: (): AppState => ({
    csrfToken: undefined,
  }),

  getters: {
    csrf(): string {
      return this.csrfToken || '';
    },

    hasCsrf(): boolean {
      return !!this.csrfToken;
    },
  },

  actions: {
    setCsrf(token: string) {
      this.csrfToken = token;
    },
  },
});
