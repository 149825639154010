import TenantShow from '@/views/tenant/TenantShow.vue';
import TenantDetails from '@/views/tenant/TenantDetails.vue';
import TenantImport from '@/views/tenant/TenantImport.vue';
import TenantSubscription from '@/views/tenant/TenantSubscription.vue';
import TenantSubscriptionChange from '@/views/tenant/TenantSubscriptionChange.vue';

export default [
  {
    path: '/tenant',
    name: 'tenant',
    component: TenantShow,
    redirect: { name: 'tenant.details' },
    meta: {
      isAdmin: true,
      template: 'app',
      ability: {
        action: 'read',
        subject: 'tenant',
      },
    },
    children: [
      {
        path: 'details',
        name: 'tenant.details',
        component: TenantDetails,
      },
      {
        path: 'import',
        name: 'tenant.import',
        component: TenantImport,
      },
      {
        path: 'subscription',
        children: [
          {
            path: '',
            name: 'tenant.subscription',
            component: TenantSubscription,
          },
          {
            path: 'change',
            name: 'tenant.subscription.change',
            component: TenantSubscriptionChange,
          },
        ],
      },
    ],
  },
];
