<template>
  <AppContainer :title="title">
    <template #buttons>
      <HelpPopup page="tenant.import" />
    </template>

    <template #tabs>
      <slot name="tabs" />
    </template>

    <q-page-container>
      <q-page class="q-pa-md">
        <div class="row justify-center">
          <q-form class="q-layout col-12 col-lg-6 q-pa-md" @submit="save">
            <h4 class="q-my-md">Import Passwords</h4>

            <div class="row q-mb-sm">
              <Field name="import_from" v-slot="{ errorMessage, value, field }">
                <q-select
                  class="q-px-sm col"
                  label="Import From"
                  :model-value="value"
                  v-bind="field"
                  :error-message="errorMessage"
                  :error="!!errorMessage"
                  :options="importFromOptions"
                  map-options
                  emit-value
                />
              </Field>
            </div>

            <div class="row q-mb-sm">
              <Field name="upload_file" v-slot="{ errorMessage, value, field }">
                <q-file
                  class="q-px-sm col"
                  :model-value="value"
                  label="Upload"
                  :error-message="errorMessage"
                  :error="!!errorMessage"
                  @blur="field.onBlur"
                  @input="field.onChange"
                  :accept="contentTypeAccept"
                  :disable="fileUploadDisabled"
                >
                  <template v-slot:append>
                    <q-icon name="attach_file" />
                  </template>
                </q-file>
              </Field>
            </div>

            <div v-if="!dryRun && !finished">
              <h5 class="q-my-sm">Summary</h5>

              <p>
                This will import <b>{{ importData.groups.length }} groups</b> and
                <b>{{ importData.secrets.length }} secrets</b>.
              </p>

              <p>Click Continue to proceed with the import.</p>

              <p><b>Groups to import: </b></p>

              <ul>
                <li v-for="group in importData.groups" :key="group.external_id">
                  {{ group.name }}
                </li>
              </ul>

              <p><b>Secrets to import: </b></p>

              <ul>
                <li v-for="secret in importData.secrets" :key="secret.external_id">
                  {{ secret.name }}
                </li>
              </ul>
            </div>

            <div v-if="finished">
              <h5 class="q-my-sm">Summary</h5>

              <p>
                Successfully imported <b>{{ successfulGroupCount }} groups</b>
              </p>

              <p>
                Successfully imported <b>{{ successfulSecretCount }} secrets</b>
              </p>

              <template v-if="importData.errors.length > 0">
                <p><b>Errors: </b></p>

                <ul>
                  <li v-for="error in importData.errors" :key="error.external_id">
                    <b>{{ error.type }}: </b>{{ error.name }} -
                    <span class="text-red">{{ error.message }}</span>
                  </li>
                </ul>
              </template>
            </div>

            <div class="row" v-if="!finished">
              <div class="col" />
              <div class="col-auto">
                <q-btn
                  class="text-right"
                  color="primary"
                  :label="dryRun ? 'Import' : 'Continue'"
                  type="submit"
                />
              </div>
            </div>
          </q-form>
        </div>
      </q-page>
    </q-page-container>
  </AppContainer>

  <div class="import-overlay" v-if="showLoadingOverlay">
    <q-spinner color="primary" size="5em" />

    <b class="q-mt-md">Please do not close this tab until the import has completed.</b>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { Field, useForm } from 'vee-validate';
import * as yup from 'yup';
import { useTenantStore } from '@/stores/tenant';
import { storeToRefs } from 'pinia';
import { useToast } from 'vue-toast-notification';
import { useImportPasswords } from '@/composable/import-passwords';

const showLoadingOverlay = ref(false);
const dryRun = ref(true);
const finished = ref(false);

const toast = useToast();

const { importData, parseImportFile, importPasswords, resetImportData } = useImportPasswords();

const tenantStore = useTenantStore();
const { tenant } = storeToRefs(tenantStore);

const importFromOptions = [
  { label: 'Bitwarden', value: 'bitwarden' },
  { label: 'Lastpass', value: 'lastpass' },
];

type ImportPasswordsForm = {
  import_from: 'bitwarden' | 'lastpass';
  upload_file: File;
};

const schema = yup.object({
  import_from: yup.string().required().label('Import From'),
  upload_file: yup.mixed().required().label('Upload'),
});

const { handleSubmit, values } = useForm<ImportPasswordsForm>({
  validationSchema: schema,
  initialValues: {},
});

const save = handleSubmit(async (payload) => {
  try {
    showLoadingOverlay.value = !dryRun.value;

    if (dryRun.value) {
      await parseImportFile(payload.upload_file, payload.import_from);

      dryRun.value = false;
      return;
    }

    await importPasswords();

    toast.success('Successfully imported passwords.');

    finished.value = true;
  } catch (err) {
    toast.error('An error occured while importing your passwords, please try again later.');
    throw err;
  } finally {
    showLoadingOverlay.value = false;
  }
});

watch(values, () => {
  resetImportData();
  dryRun.value = true;
  finished.value = false;
});

const title = computed(() => `${tenant.value?.name} - Import`);

const contentTypeAccept = computed(() => {
  if (values.import_from === 'bitwarden') {
    return 'application/json';
  }

  return 'text/csv';
});

const fileUploadDisabled = computed(() => {
  return !values.import_from;
});

const successfulGroupCount = computed(() => {
  return (
    importData.value.groups.length -
    importData.value.errors.filter(({ type }) => type === 'Group').length
  );
});

const successfulSecretCount = computed(() => {
  return (
    importData.value.secrets.length -
    importData.value.errors.filter(({ type }) => type === 'Secret').length
  );
});
</script>
