<template>
  <q-layout view="hHh lpR fFf">
    <q-page-container class="auth">
      <q-page class="q-layout-padding flex justify-center items-center">
        <q-form
          class="card card-auth q-pa-lg rounded"
          :class="{ 'card-auth-choose-subscription': !values.stripe_package_id }"
          @submit="submit"
        >
          <div class="q-gutter-md">
            <div class="q-mx-none heading q-mt-md q-mb-lg">
              <img src="@/assets/icons/icon.svg" class="logo-image" />
              <h2>strng.io</h2>
            </div>
          </div>

          <template v-if="authUserStore.isAdmin">
            <h4 class="q-my-md text-center">Subscribe</h4>

            <SubscriptionCanceledMessage v-if="subscriptionWasCanceled" />

            <template v-if="!values.stripe_package_id">
              <p class="text-center">Please select a subscription tier to subscribe to.</p>

              <ChooseSubscription @selected="(pkg) => setFieldValue('stripe_package_id', pkg.id)" />
            </template>

            <template v-else>
              <div class="row">
                <div class="col">
                  <b>Selected Subscription: </b>
                </div>

                <div class="col-auto">
                  {{ selectedPkgDescription }}
                </div>
              </div>

              <div class="row q-mt-sm">
                <div class="col" />
                <div class="col-auto">
                  <q-btn
                    color="primary"
                    label="Change"
                    @click="() => setFieldValue('stripe_package_id', null)"
                  />
                </div>
              </div>

              <AddCard @tokenize="(t: () => Promise<PaymentMethod>) => (tokenize = t)">
                <template #title>
                  <h5 class="q-my-md">Add Card</h5>
                </template>
              </AddCard>

              <div class="row q-mt-md">
                <div class="col">
                  <q-btn
                    v-if="!subscriptionWasCanceled"
                    text-color="black"
                    label="Skip For Now"
                    @click="skipPaymentMethod"
                    :loading="isSkipPaymentLoading"
                  />
                </div>

                <div class="col-auto">
                  <q-btn
                    class="text-right"
                    color="primary"
                    label="Subscribe"
                    type="submit"
                    :loading="isLoading"
                  />
                </div>
              </div>
            </template>
          </template>

          <NoSubscriptionMessage v-else />
        </q-form>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script lang="ts" setup>
import { useForm } from 'vee-validate';
import * as yup from 'yup';
import { useRouter } from 'vue-router';
import { AxiosError } from 'axios';
import NoSubscriptionMessage from '@/components/subscriptions/NoSubscriptionMessage.vue';
import AddCard from '@/components/subscriptions/AddCard.vue';
import type { PaymentMethod } from '@stripe/stripe-js';
import { computed, onMounted, ref } from 'vue';
import { useToast } from 'vue-toast-notification';
import { useAuthUserStore } from '@/stores/auth-user';
import { useSubscriptionStore } from '@/stores/subscription';
import ChooseSubscription from '@/components/subscriptions/ChooseSubscription.vue';
import { storeToRefs } from 'pinia';
import SubscriptionCanceledMessage from '@/components/subscriptions/SubscriptionCanceledMessage.vue';

const isLoading = ref(false);
const isSkipPaymentLoading = ref(false);

const router = useRouter();
const toast = useToast();

const authUserStore = useAuthUserStore();
const { authUser } = storeToRefs(authUserStore);

const subscriptionStore = useSubscriptionStore();
const { stripePackages } = storeToRefs(subscriptionStore);

await subscriptionStore.getStripePackages();

const tokenize = ref<() => Promise<PaymentMethod> | null>();

type SubscribeForm = {
  stripe_package_id: string | null;
};

const schema = yup.object({
  stripe_package_id: yup.string().required().label('Subscription'),
});

const { handleSubmit, values, setFieldValue, setErrors } = useForm<SubscribeForm>({
  validationSchema: schema,
  initialValues: {
    stripe_package_id: authUser.value?.current_subscription?.package_id ?? null,
  },
});

const submit = handleSubmit(async (values, actions) => {
  if (!tokenize.value) {
    toast.error('An error occurred while processing your payment. Please try again.');
    return;
  }

  try {
    isLoading.value = true;

    const paymentMethod = await tokenize.value();

    if (!paymentMethod) {
      toast.error('An error occurred while processing your payment. Please try again.');
      return;
    }

    await subscriptionStore.subscribe({
      stripe_package_id: values.stripe_package_id!,
      payment_method: paymentMethod.id,
    });

    localStorage.removeItem('landing-page-pkg-selected');

    isLoading.value = false;

    router.push({ name: 'dashboard' });
  } catch (err) {
    isLoading.value = false;
    actions.resetForm();

    if (err instanceof AxiosError) {
      setErrors(err.response!.data);
      return;
    }

    throw err;
  }
});

async function skipPaymentMethod() {
  try {
    isSkipPaymentLoading.value = true;

    await subscriptionStore.subscribe({
      stripe_package_id: values.stripe_package_id!,
      skip_payment_method: true,
    });

    localStorage.removeItem('landing-page-pkg-selected');

    isSkipPaymentLoading.value = false;

    router.push({ name: 'dashboard' });
  } finally {
    isSkipPaymentLoading.value = false;
  }
}

onMounted(() => {
  const selectedPkg = localStorage.getItem('landing-page-pkg-selected');

  if (selectedPkg && stripePackages.value.find(({ id }) => id === selectedPkg)) {
    setFieldValue('stripe_package_id', selectedPkg);
  }
});

const selectedPkgDescription = computed(() => {
  const pkg = stripePackages.value.find(({ id }) => id === values.stripe_package_id);

  if (!pkg) {
    return null;
  }

  return `${pkg.name}  -  ${pkg.price} / month`;
});

const subscriptionWasCanceled = computed(
  () =>
    ['canceled', 'past_due'].includes(
      authUser.value?.current_subscription?.subscription_status ?? ''
    )
);
</script>
