import { rsaDecrypt } from '@/utils/encryption/asymmetric';
import { bytesToString } from '@/utils/encryption/utils';

export default async function rsaDecryptField(
  privateKey: CryptoKey,
  ciphertext: string | null | undefined
): Promise<string | undefined> {
  if (!ciphertext) {
    return undefined;
  }

  try {
    const plaintextBytes = await rsaDecrypt(privateKey, ciphertext);

    return bytesToString(plaintextBytes);
  } catch (err) {
    return undefined;
  }
}
