import { bytesToString, getKeyMaterial } from '@/utils/encryption/utils';

/*
Given some key material and some random salt
derive an AES-CBC key using PBKDF2.
*/
export async function getKey(keyMaterial: CryptoKey, salt: ArrayBuffer): Promise<CryptoKey> {
  return await window.crypto.subtle.deriveKey(
    {
      name: 'PBKDF2',
      salt,
      iterations: 100000,
      hash: 'SHA-256',
    },
    keyMaterial,
    { name: 'AES-GCM', length: 256 },
    true,
    ['wrapKey', 'unwrapKey']
  );
}

/*
Wrap the given key.
*/
export async function wrapCryptoKey(keyToWrap: CryptoKey, password: string): Promise<string> {
  // get the key encryption key
  const keyMaterial: CryptoKey = await getKeyMaterial(password);
  const salt: ArrayBuffer = window.crypto.getRandomValues(new Uint8Array(16));
  const wrappingKey: CryptoKey = await getKey(keyMaterial, salt);
  const iv: ArrayBuffer = window.crypto.getRandomValues(new Uint8Array(12));

  const wrappedKey = await window.crypto.subtle.wrapKey('pkcs8', keyToWrap, wrappingKey, {
    name: 'AES-GCM',
    iv,
  });

  return window.btoa(bytesToString(iv) + bytesToString(salt) + bytesToString(wrappedKey));
}
