<template>
  <div class="pricing-table">
    <ChooseSubscriptionListItem
      v-for="stripePackage in stripePackages"
      :key="stripePackage.id"
      :stripePackage="stripePackage"
      :button-text="buttonText"
      :max-users="maxUsers"
      :current-subscription="currentSubscription"
      @selected="(stripePkg) => emit('selected', stripePkg)"
    />
  </div>
</template>

<script lang="ts" setup>
import ChooseSubscriptionListItem from '@/components/subscriptions/ChooseSubscriptionListItem.vue';
import { useSubscriptionStore, type StripePackage } from '@/stores/subscription';
import { storeToRefs } from 'pinia';
import { toRefs } from 'vue';

const props = withDefaults(
  defineProps<{
    buttonText?: string;
    maxUsers?: number | null;
    currentSubscription?: string | null;
  }>(),
  {
    buttonText: 'Select',
    maxUsers: null,
    currentSubscription: null,
  }
);

const emit = defineEmits<{
  (e: 'selected', value: StripePackage): void;
}>();

const { buttonText, maxUsers, currentSubscription } = toRefs(props);

const subscriptionStore = useSubscriptionStore();
const { stripePackages } = storeToRefs(subscriptionStore);

await subscriptionStore.getStripePackages();
</script>
