import { defineStore } from 'pinia';
import type { AxiosResponse } from 'axios';
import http from '@/utils/http';
import type { Role } from './role';
import { defineAbility } from '@casl/ability';
import { importPublicKey } from '@/utils/encryption/asymmetric';

export type Ability = {
  action: string;
  subject: string;
};

export type AuthUserState = {
  authUser: AuthUser | null;
  ability: any;
};

export type AuthUser = {
  id: string;
  tenant_id: string;
  first_name: string;
  last_name: string;
  email: string;
  new_email?: string;
  timezone: string;
  date_of_birth: string;
  role: Role;
  profile_pic_url?: string;
  two_factor_enabled: boolean;
  two_factor_method: string;
  email_verification_required?: boolean;
  two_factor_verification_required?: boolean;
  permissions: string[];

  tenant_url_salt: string;
  tenant_two_factor_remember_expiry_day: number;
  access_request_count: number;

  has_recovery_group: boolean;
  recovery_group_badge_dismissed: boolean;

  asymmetric_key_public: string;
  asymmetric_key_private: string;
  asymmetric_key_private_decrypted: CryptoKey | null;
  tenant_user_symmetric_key: string;
  tenant_user_asymmetric_key_public: string;
  tenant_user_asymmetric_key_private: string;
  tenant_symmetric_key: string;
  tenant_asymmetric_key_public: string;
  tenant_asymmetric_key_private: string;

  current_subscription: AuthUserCurrentSubscription;
};

export type AuthUserCurrentSubscription = {
  stripe_customer_id: string;
  package_id: string;
  package_name: string;
  package_min_users: string;
  package_max_users: string;
  package_price: string;
  subscription_status: string;
};

export const useAuthUserStore = defineStore('authUser', {
  state: (): AuthUserState => ({
    authUser: null,
    ability: null,
  }),

  getters: {
    currentUser: (state: AuthUserState): AuthUser => {
      if (state.authUser === null) {
        throw new Error('User not logged in');
      }

      return state.authUser;
    },

    fullName: (state: AuthUserState) =>
      `${state.authUser?.first_name} ${state.authUser?.last_name}`,

    initials: (state: AuthUserState) =>
      `${state.authUser?.first_name.charAt(0)}${state.authUser?.last_name.charAt(0)}`,

    loggedIn: (state: AuthUserState) => state.authUser && state.authUser.id !== null,

    isAdmin: (state: AuthUserState) => state.authUser && state.authUser.role.name === 'Admin',

    publicKey: async (state: AuthUserState) =>
      await importPublicKey(state.authUser!.asymmetric_key_public),

    tenantUserPublicKey: async (state: AuthUserState) =>
      await importPublicKey(state.authUser!.tenant_user_asymmetric_key_public),

    tenantPublicKey: async (state: AuthUserState) =>
      await importPublicKey(state.authUser!.tenant_asymmetric_key_public),
  },

  actions: {
    setUser(payload: AuthUser) {
      const user: AuthUser = payload;

      if (this.authUser?.asymmetric_key_private_decrypted) {
        user.asymmetric_key_private_decrypted = this.authUser?.asymmetric_key_private_decrypted;
      }

      this.authUser = user;
      this.authUser.permissions = payload.permissions ?? [];

      this.ability = defineAbility((can): void => {
        this.authUser!.permissions.forEach((permission: string) => {
          const s = permission.split(' ');
          return can(s[0], s.slice(1).join(' '));
        });
      });
    },

    setPrivateKey(privateKey: CryptoKey) {
      if (!this.authUser) {
        return;
      }

      this.authUser.asymmetric_key_private_decrypted = privateKey;
    },

    can(ability: Ability) {
      return this.ability && this.ability.can(ability.action, ability.subject);
    },

    async authorize(): Promise<void> {
      let response: AxiosResponse | null = null;
      try {
        response = await http.get('/user');
      } catch (e) {
        this.authUser = null;
        return;
      }

      if (response === null) {
        this.authUser = null;
        return;
      }

      this.setUser(response.data);
    },

    async logout(): Promise<void> {
      try {
        await http.get('/logout');
        // eslint-disable-next-line no-empty
      } catch (err) {}

      this.authUser = null;
    },

    async changePassword({ payload }: { payload: ChangePassworForm }): Promise<void> {
      await http.put('/user/change-password', payload);
      await this.authorize();
    },

    async recoverAccount({ payload }: { payload: RecoverAccountForm }): Promise<void> {
      await http.put('/user/recover-account', payload);
      await this.authorize();
    },
  },
});

export type ChangePassworForm = {
  current_password: string;
  new_password: string;
  confirm_new_password: string;
  asymmetric_key_private: string;
};

export type RecoverAccountForm = {
  password: string;
  confirm_password: string;
  asymmetric_key_private: string;
};
