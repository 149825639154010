import { bytesToArrayBuffer, bytesToString } from '@/utils/encryption/utils';

export async function aesEncrypt(aesKey: CryptoKey, plaintext: Uint8Array): Promise<string> {
  const data: ArrayBuffer = bytesToArrayBuffer(plaintext);

  const iv: ArrayBuffer = window.crypto.getRandomValues(new Uint8Array(12));

  const encData: ArrayBuffer = await window.crypto.subtle.encrypt(
    {
      name: 'AES-GCM',
      iv: iv,
    },
    aesKey,
    data
  );

  return window.btoa(bytesToString(iv) + bytesToString(encData));
}

export async function aesEncryptFile(aesKey: CryptoKey, plaintext: File): Promise<ArrayBuffer> {
  const data: ArrayBuffer = await plaintext.arrayBuffer();

  const iv: ArrayBuffer = window.crypto.getRandomValues(new Uint8Array(12));

  const encData: ArrayBuffer = await window.crypto.subtle.encrypt(
    {
      name: 'AES-GCM',
      iv: iv,
    },
    aesKey,
    data
  );

  const rawBuffer = new ArrayBuffer(iv.byteLength + encData.byteLength);
  const rawBytes = new Uint8Array(rawBuffer);
  rawBytes.set(new Uint8Array(iv), 0);
  rawBytes.set(new Uint8Array(encData), iv.byteLength);
  return rawBuffer;
}
