import type { RouteLocationNormalized } from 'vue-router';
import LoginView from '@/views/auth/LoginView.vue';
import SignupView from '@/views/auth/SignupView.vue';
import VerifyEmailView from '@/views/verification/VerifyEmail.vue';
import VerifyTwoFactorView from '@/views/verification/VerifyTwoFactor.vue';
import EnterPasswordView from '@/views/auth/EnterPasswordView.vue';
import SetupAccountView from '@/views/auth/SetupAccountView.vue';

export default [
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignupView,
  },
  {
    path: '/verify/email',
    name: 'verify.email',
    component: VerifyEmailView,
    props: (route: RouteLocationNormalized) => ({ ...route.query }),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/verify/two-factor',
    name: 'verify.two-factor',
    component: VerifyTwoFactorView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/enter-password',
    name: 'enter-password',
    component: EnterPasswordView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/setup-account',
    name: 'setup.account',
    component: SetupAccountView,
    props: (route: RouteLocationNormalized) => ({ ...route.query }),
  },
];
