<template>
  <q-item
    class="q-my-sm"
    clickable
    v-ripple
    :to="{ name: 'user.details', params: { id: groupAccess.id } }"
  >
    <q-item-section>
      <q-item-label>
        {{ groupAccess.name }}
      </q-item-label>
    </q-item-section>

    <q-item-section side>
      {{ createdAt }}
    </q-item-section>

    <q-item-section side>
      <q-btn-dropdown
        class="dropdown-ellipsis"
        dropdown-icon="fa-solid fa-ellipsis-vertical"
        :disable="!(groupAccessList.length > 1 && groupAccess.id !== authUser?.id)"
        @click.prevent
      >
        <q-list>
          <q-item clickable v-close-popup @click.prevent="revokeAccess">
            <q-item-section>
              <q-item-label>
                <q-icon name="fa-solid fa-trash" class="q-mr-sm" />
                Revoke Access
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-btn-dropdown>
    </q-item-section>
  </q-item>
</template>

<script lang="ts" setup>
import { toRefs } from 'vue';
import { useGroupAccessStore, type GroupAccess } from '@/stores/group/access';
import { storeToRefs } from 'pinia';
import { useAuthUserStore } from '@/stores/auth-user';
import { useGroupStore } from '@/stores/group';
import { AxiosError } from 'axios';
import { useToast } from 'vue-toast-notification';
import formatDatetime from '@/composable/format-datetime';

const props = defineProps<{
  groupAccess: GroupAccess;
}>();

const emit = defineEmits<{
  (e: 'refresh'): void;
}>();

const toast = useToast();

const { groupAccess } = toRefs(props);

const authUserStore = useAuthUserStore();
const { authUser } = storeToRefs(authUserStore);

const groupStore = useGroupStore();
const { group } = storeToRefs(groupStore);

const groupAccessStore = useGroupAccessStore();
const { groupAccess: groupAccessList } = storeToRefs(groupAccessStore);

const createdAt = formatDatetime(groupAccess.value.created_at);

const revokeAccess = async () => {
  try {
    await groupAccessStore.revokeAccess(group.value!.id, {
      id: groupAccess.value.id,
    });

    emit('refresh');

    toast.success('Access revoked successfully.');
  } catch (err) {
    if (err instanceof AxiosError) {
      if (err.response?.status === 400) {
        toast.error(err.response?.data.message);
      }
    }
  }
};
</script>
