<template>
  <div class="row justify-center">
    <q-form class="q-layout col-12 col-lg-6 card rounded q-pa-md" @submit="save">
      <div class="row">
        <div class="col-12">
          <h6 class="q-ma-sm">Two Factor Authentication</h6>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <Field name="two_factor_enabled" v-slot="{ errorMessage, value, field }">
            <q-checkbox
              :model-value="value"
              v-bind="field"
              :val="true"
              label="Enabled"
              :error-message="errorMessage"
              :error="!!errorMessage"
            />
          </Field>
        </div>

        <div v-if="values.two_factor_enabled" class="col-6">
          <Field name="two_factor_method" v-slot="{ value, field }">
            <q-radio
              class="q-mr-sm"
              :model-value="value"
              v-bind="field"
              val="email"
              label="Email"
            />

            <q-radio
              class="q-mr-sm"
              :model-value="value"
              v-bind="field"
              val="totp"
              label="Google Authenticator"
            />
          </Field>
        </div>
      </div>

      <div class="row q-pt-md q-px-sm">
        <div class="col"></div>

        <div class="col-auto">
          <q-btn class="text-right" color="primary" label="Save" type="submit" />
        </div>
      </div>
    </q-form>
  </div>
</template>

<script lang="ts" setup>
import { Field, useForm } from 'vee-validate';
import * as yup from 'yup';
import type { EditUserSecurityForm } from '@/stores/user';
import { storeToRefs } from 'pinia';
import { useToast } from 'vue-toast-notification';
import { useRouter } from 'vue-router';
import http from '@/utils/http';
import { useAuthUserStore } from '@/stores/auth-user';

const toast = useToast();
const router = useRouter();

const authUserStore = useAuthUserStore();
const { authUser } = storeToRefs(authUserStore);

if (!authUser?.value) {
  throw new Error('User is undefined.');
}

const schema = yup.object({
  two_factor_enabled: yup.boolean().label('Two factor enabled'),
  two_factor_method: yup.string().label('Two factor method'),
});

const initialValues = {
  two_factor_enabled: authUser?.value?.two_factor_enabled,
  two_factor_method: authUser?.value?.two_factor_method,
};

const { handleSubmit, values } = useForm<EditUserSecurityForm>({
  validationSchema: schema,
  initialValues: initialValues,
});

const save = handleSubmit(async (payload) => {
  await http.put(`/users/${authUser.value?.id}/security`, payload);

  toast.success('User saved successfully.');

  await authUserStore.authorize();

  if (authUser.value?.two_factor_enabled) {
    router.replace({ name: 'verify.two-factor' });
  }
});
</script>
