import { bytesToArrayBuffer, stringToBytes, getKeyMaterial } from '@/utils/encryption/utils';

/*
Derive an AES-GCM key using PBKDF2.
*/
async function getUnwrappingKey(password: string, saltBytes: Uint8Array) {
  // 1. get the key material (user-supplied password)
  const keyMaterial = await getKeyMaterial(password);
  // 2 initialize the salt parameter.
  // The salt must match the salt originally used to derive the key.
  // In this example it's supplied as a constant "saltBytes".
  const saltBuffer = bytesToArrayBuffer(saltBytes);
  // 3 derive the key from key material and salt
  return window.crypto.subtle.deriveKey(
    {
      name: 'PBKDF2',
      salt: saltBuffer,
      iterations: 100000,
      hash: 'SHA-256',
    },
    keyMaterial,
    { name: 'AES-GCM', length: 256 },
    true,
    ['wrapKey', 'unwrapKey']
  );
}

export async function unwrapPrivateKey(wrappedKey: string, password: string) {
  const ivStr = window.atob(wrappedKey).slice(0, 12);
  const ivBytes = stringToBytes(ivStr);

  const saltStr = window.atob(wrappedKey).slice(12, 28);
  const saltBytes = stringToBytes(saltStr);

  const keyStr = window.atob(wrappedKey).slice(28);
  const keyBytes = stringToBytes(keyStr);

  const unwrappingKey = await getUnwrappingKey(password, saltBytes);
  const wrappedKeyBuffer = bytesToArrayBuffer(keyBytes);
  const ivBuffer = bytesToArrayBuffer(ivBytes);

  return await window.crypto.subtle.unwrapKey(
    'pkcs8',
    wrappedKeyBuffer,
    unwrappingKey,
    {
      name: 'AES-GCM',
      iv: ivBuffer,
    },
    {
      name: 'RSA-OAEP',
      hash: 'SHA-256',
    },
    true,
    ['decrypt']
  );
}
