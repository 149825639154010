<template>
  <q-item class="q-my-sm" clickable v-ripple :to="link">
    <q-item-section>
      <q-item-label>
        {{ secretAccess.name }}

        <q-badge v-if="secretAccess.type === 'group'" color="primary" style="margin-left: 0.5rem">
          Group
        </q-badge>

        <q-badge v-if="secretAccess.type === 'tenant'" color="primary" style="margin-left: 0.5rem">
          Organisation
        </q-badge>

        <q-badge v-if="secretAccess.can_grant_access" color="positive" style="margin-left: 0.5rem">
          Grant access
        </q-badge>
      </q-item-label>
    </q-item-section>

    <q-item-section side>
      {{ createdAt }}
    </q-item-section>

    <q-item-section side>
      <q-btn-dropdown
        class="dropdown-ellipsis"
        dropdown-icon="fa-solid fa-ellipsis-vertical"
        :disable="disableDropdown"
        @click.prevent
      >
        <q-list>
          <q-item clickable v-close-popup @click.prevent="revokeAccess">
            <q-item-section>
              <q-item-label>
                <q-icon name="fa-solid fa-trash" class="q-mr-sm" />
                Revoke Access
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-btn-dropdown>
    </q-item-section>
  </q-item>
</template>

<script lang="ts" setup>
import { computed, toRefs } from 'vue';
import { useSecretAccessStore, type SecretAccess } from '@/stores/secret/access';
import { useSecretStore } from '@/stores/secret';
import { useAuthUserStore } from '@/stores/auth-user';
import { storeToRefs } from 'pinia';
import { AxiosError } from 'axios';
import { useToast } from 'vue-toast-notification';
import formatDatetime from '@/composable/format-datetime';

const props = defineProps<{
  secretAccess: SecretAccess;
}>();

const emit = defineEmits<{
  (e: 'refresh'): void;
}>();

const toast = useToast();

const authUserStore = useAuthUserStore();
const { authUser } = storeToRefs(authUserStore);

const secretStore = useSecretStore();
const { secret } = storeToRefs(secretStore);

const secretAccessStore = useSecretAccessStore();
const { secretAccess: secretAccessList } = storeToRefs(secretAccessStore);

const { secretAccess } = toRefs(props);

const createdAt = formatDatetime(secretAccess.value.created_at);

const revokeAccess = async () => {
  try {
    await secretAccessStore.revokeAccess(secret.value!.id, {
      type: secretAccess.value.type,
      id: secretAccess.value.id,
    });

    emit('refresh');

    toast.success('Access revoked successfully.');
  } catch (err) {
    if (err instanceof AxiosError) {
      if (err.response?.status === 400) {
        toast.error(err.response?.data.message);
      }
    }
  }
};

const link = computed(() => {
  if (
    secretAccess.value.type === 'user' &&
    authUserStore.can({ action: 'read', subject: 'users' })
  ) {
    return { name: 'user.details', params: { id: secretAccess.value.id } };
  }

  if (secretAccess.value.type === 'group') {
    return { name: 'group.details', params: { id: secretAccess.value.id } };
  }

  return {};
});

const disableDropdown = computed(() => {
  return !(
    secret.value?.can_grant_access &&
    secretAccessList.value.length > 1 &&
    secretAccess.value.id !== authUser.value?.id
  );
});
</script>
