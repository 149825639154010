export function formatJSONtoPlainText(json: Object, indent = 0): string {
  let plainText = '';
  const indentation = ' '.repeat(indent);

  for (const [key, value] of Object.entries(json)) {
    if (typeof value === 'object' && value !== null) {
      plainText += `${indentation}${key}:\n`;
      plainText += formatJSONtoPlainText(value, indent + 2);
    } else {
      plainText += `${indentation}${key}: ${value}\n`;
    }
  }

  return plainText;
}
