<template>
  <a :href="invoiceLink" target="_blank">
    <q-item clickable v-ripple>
      <q-item-section>
        <q-item-label>
          {{ stripeInvoice.number }}

          <q-badge v-if="stripeInvoice.status !== 'paid'" color="negative" class="q-ml-sm">
            Outstanding
          </q-badge>

          <q-badge v-if="stripeInvoice.status === 'paid'" color="positive" class="q-ml-sm">
            Paid
          </q-badge>
        </q-item-label>
      </q-item-section>

      <q-item-section side>
        {{ createdAt }}
      </q-item-section>
    </q-item>
  </a>
</template>

<script lang="ts" setup>
import { computed, toRefs } from 'vue';
import type { StripeInvoice } from '@/stores/tenant/stripe-invoices';
import formatDatetime from '@/composable/format-datetime';

const props = defineProps<{
  stripeInvoice: StripeInvoice;
}>();

const { stripeInvoice } = toRefs(props);

const createdAt = formatDatetime(stripeInvoice.value.created_at);

const invoiceLink = computed(() => {
  if (stripeInvoice.value.status === 'paid') {
    return stripeInvoice.value.pdf_url;
  }

  return stripeInvoice.value.hosted_invoice_url;
});
</script>
