<template>
  <footer reveal>
    <div class="row">
      <div class="col-md col-12">
        <div class="flex items-center">
          <img src="@/assets/icons/icon.svg" class="q-mr-md" />
          <span class="footer-logo-text">strng.io</span>
        </div>

        <p class="q-mt-md">
          Unlock potential, not just accounts: Evolving access for evolving teams.
        </p>
      </div>

      <div class="col-md-auto col-12 q-pt-xl">
        <RouterLink :to="{ name: 'terms-and-conditions' }" class="footer-link">
          Terms &amp; Conditions
        </RouterLink>
        <span> | </span>
        <RouterLink :to="{ name: 'privacy-policy' }" class="footer-link">
          Privacy Policy
        </RouterLink>
        <span> | &copy; 2023 strng.io</span>
      </div>
    </div>
  </footer>
</template>
