import { defineStore } from 'pinia';
import http from '@/utils/http';

export interface AccessRequestState {
  accessRequests: AccessRequest[];
  accessRequest: AccessRequest | null;
  accessRequestOptions: (AccessRequestOption | AccessRequestOptionGroup)[];
}

export interface AccessRequest {
  id: string;
  type: string;
  user_id: string;
  user_first_name: string;
  user_last_name: string;
  user_email: string;
  target_id: string;
  target_name: string;
  accepted: boolean;
  requested_access_at: string;
  granted_access_at?: string;
  declined_access_at?: string;
  created_at: string;
  updated_at: string;
}

export interface AccessRequestOption {
  id: string;
  name: string;
  type: string;
  has_target_access: boolean;
  target_has_secret_access: boolean;
}

export interface AccessRequestOptionGroup {
  group: string;
  name?: string;
  disable: boolean;
}

export interface AccessRequestListFilters {
  search?: string;
  status?: string;
  created_at?: { from: string; to: string };
  sort?: string;
}

export const useAccessRequestStore = defineStore('accessRequests', {
  state: (): AccessRequestState => ({
    accessRequests: [],
    accessRequest: null,
    accessRequestOptions: [],
  }),

  getters: {},

  actions: {
    async getAccessRequests({
      page,
      filters,
      reset = false,
    }: {
      page: number;
      filters?: AccessRequestListFilters;
      reset?: boolean;
    }) {
      const response = await http.get('/access-requests', { params: { page, ...filters } });

      if (reset) {
        this.accessRequests = [];
      }

      this.accessRequests.push(...(response.data ?? []));

      return response.data ?? [];
    },

    resetAccessRequests() {
      this.accessRequests = [];
    },

    setAccessRequest(accessRequest: AccessRequest) {
      this.accessRequest = accessRequest;
    },

    resetAccessRequest() {
      this.accessRequest = null;
    },

    async getAccessRequestOptions(id: string) {
      const response = await http.get(`/access-requests/${id}/access-options`);

      this.accessRequestOptions = [];

      const availableTypes = ['User', 'Groups', 'Tenants'];

      availableTypes.forEach((type: string) => {
        const typeValues = response.data.filter(
          (option: AccessRequestOption) => option.type === type
        );

        if (typeValues.length === 0) {
          return;
        }

        this.accessRequestOptions.push({
          group: type,
          disable: true,
        });

        this.accessRequestOptions.push(...typeValues);
      });

      return response.data ?? [];
    },

    async declineAccessRequest(id: string) {
      await http.post(`/access-requests/${id}/decline`);
    },
  },
});
