<template>
  <q-select v-bind="attrs" />
</template>

<script lang="ts" setup>
import { ref, computed, useAttrs } from 'vue';
import { useTimezonesStore } from '@/stores/timezone';
import { storeToRefs } from 'pinia';

const props = defineProps<{
  modelValue: any;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: any): void;
}>();

const isLoading = ref(true);
const options = ref<string[]>([]);

const timezonesStore = useTimezonesStore();
const { timezones } = storeToRefs(timezonesStore);

timezonesStore.getTimezones({}).then(() => {
  options.value = timezones.value.map((t) => t.name);
  isLoading.value = false;
});

const filterFn = (val: string, update: (fn: () => void) => void) => {
  if (val === '') {
    update(() => {
      options.value = timezones.value.map((t) => t.name);
    });
    return;
  }

  update(() => {
    const needle = val.toLowerCase();

    options.value = timezones.value
      .filter((t) => {
        return t.name.toLowerCase().indexOf(needle) > -1;
      })
      .map((t) => t.name);
  });
};

const attrs = computed(() => {
  const defaultAttrs = useAttrs();

  return {
    modelValue: props.modelValue,
    'onUpdate:modelValue': (modelValue: any) => {
      emit('update:modelValue', modelValue);
    },
    onFilter: filterFn,
    useInput: true,
    inputDebounce: 0,
    options: options.value,
    class: defaultAttrs.class,
    label: defaultAttrs.label as string,
    error: defaultAttrs.error as boolean,
    errorMessage: defaultAttrs['error-message'] as string,
  };
});
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>
