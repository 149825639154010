import AppTemplate from '@/templates/AppTemplate.vue';
import DashboardView from '@/views/dashboard/DashboardView.vue';
import accountRoutes from './account';
import userRoutes from './users';
import tenantRoutes from './tenant';
import secretRoutes from './secrets';
import groupRoutes from './groups';
import accessRequests from './access-requests';

export default [
  {
    path: '/',
    name: 'main',
    component: AppTemplate,
    redirect: { name: 'dashboard' },
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: DashboardView,
      },
      ...accountRoutes,
      ...userRoutes,
      ...tenantRoutes,
      ...secretRoutes,
      ...groupRoutes,
      ...accessRequests,
    ],
  },
];
